@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw15-set-new-psw {
    @include wizard(&);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__description {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        margin-top: rem(16px);
        margin-bottom: rem(24px);
    }

    &__password,
    &__passwordRepeat {
        margin-top: rem(16px);
        transition: margin-top 0.3s ease-in-out;

        &--valid {
            border-color: var(--color-secondary3);
        }
    }

    &__passwordRules {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        overflow: hidden;
        max-height: rem(50px);
        transition: max-height 0.2s ease-in-out;

        &--hidden {
            max-height: 0;
        }
    }
    &__passwordRules--hidden + &__password {
        margin-top: 0;
    }

    &__passwordMismatch {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        overflow: hidden;
        visibility: visible;
        pointer-events: all;
        transition: max-height 0.2s ease-in-out;

        &--hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__passwordState {
        flex: 0 0 auto;
        margin-right: rem(16px);
        & > .ok {
            font-size: rem(16px);
            background-color: var(--color-secondary3);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .error {
            font-size: rem(16px);
            background-color: var(--color-primary);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .active {
            display: block;
        }
    }

    &__passwordText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
    }

    &__passwordMismatchText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-primary);
    }

    &__form {
        display: flex;
        flex-flow: column;
        padding-bottom: rem(38px);
    }

    &__submit {
        width: 100%;
        text-align: center;
        display: inline-block;
        @extend %button-primary3;
        margin-top: rem(32px);
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);
        pointer-events: all !important;

        @include respond-above(m) {
            align-self: flex-end;
            width: fit-content;
        }

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }

    &__container {
        display: none;

        &--show {
            display: flex;
        }

        &--error {
            align-items: flex-start;
        }

        &--ok {
            flex-flow: column;
        }
    }

    &__image {
        width: rem(148px);
        height: auto;
        margin-right: rem(12px);
    }

    &__result {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        max-width: rem(464px);
    }

    &__txt {
        margin-top: rem(8px);
        @extend %font-heading5;
        @extend %txt;
    }
}
