@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf1-payments {
    margin-bottom: rem(32px);
    height: 100%;
    
    @include respond-above(l) {
        margin-bottom: rem(39px);
    }
    
    &__intro {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        margin-top: rem(23px);
        margin-bottom: rem(21px);

        @include respond-above(l) {
            margin-top: rem(-16px);
            margin-bottom: rem(19px);
        }
    }

    &__iframeContainer {
        margin-top: rem(26px);
        height: 100%;
        display: flex;
    }

    &__iframeContainer .mc5-iframe {
        flex-grow: 1;
    }
}
