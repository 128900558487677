@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp14-close-cc-request {
    @include actionPopup();

    &__content {
        @include respond-above(l) {
            flex-flow: row;
        }
    }
    &--loading &__content {
        padding: rem(32px) rem(19px) rem(45px);
    }
    &--success &__content {
        padding: rem(24px) rem(16px) rem(24px);

        @include respond-above(l) {
            padding: rem(24px) rem(24px) rem(22px);
        }
    }

    &--loading &__close {
        display: none;
    }

    &__text a {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        text-decoration: underline;
    }

    &__image {
        display: none;
        width: rem(160px);
        height: rem(125.4px);
        object-fit: contain;
        object-position: top center;
        margin-top: rem(24px);
        margin-left: rem(24px);

        @include respond-above(l) {
            display: block;
        }
    }
    &--loading &__image,
    &--success &__image {
        display: none;
    }

    &--loading &__wrapper,
    &--success &__wrapper {
        display: none;
    }

    &__ctas {
        @include respond-above(l) {
            margin-top: rem(28px);
        }
    }

    &__loading {
        display: none;
    }
    &--loading &__loading {
        display: block;
    }

    &__loadingImage {
        width: rem(208px);
        height: rem(115px);
        object-fit: contain;
        object-position: top center;
        margin: 0 auto;
    }

    &__loadingText {
        @extend %robotoFontBold;
        font-size: rem(21px);
        line-height: rem(27px);
        color: var(--color-txtAcc);
        max-width: rem(348px);
        text-align: center;
        margin-top: rem(24px);
        margin-left: auto;
        margin-right: auto;

        @include respond-above(l) {
            font-size: rem(22px);
        }
    }

    &__loader {
        display: block;
        position: relative;
        width: rem(80px);
        height: rem(80px);
        background-image: url('#{$resourcesFolder}/myconad/icons/loader.gif');
        background-repeat: no-repeat;
        background-size: rem(80px);
        margin: rem(32px) auto 0;

        @include respond-above(l) {
            margin-top: rem(24px);
        }
    }

    &__success {
        display: none;
    }
    &--success &__success {
        display: flex;
        gap: rem(16px);
    }

    &__successImage {
        display: none;
        width: rem(144px);
        height: rem(144px);
        object-fit: contain;
        object-position: top center;

        @include respond-above(l) {
            display: block;
        }
    }

    &__successTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);
        padding-right: rem(40px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__successText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        padding-right: rem(40px);

        & a {
            @extend %dmSansFontMedium;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-info);
            text-decoration: underline;
        }

        /* request id */
        & span {
            @extend %dmSansFontBold;
        }
    }

    &__successCta {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        color: var(--color-white);
        text-align: center;
        margin-top: rem(40px);
        display: block;
        width: 100%;

        @include respond-above(l) {
            width: unset;
            margin-left: auto;
        }
    }
}
