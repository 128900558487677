@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt33-timeslot-chip {
    margin-top: rem(20px);
    pointer-events: none;
    cursor: default;
    
    &:focus-visible {
        @extend %button-focused;
    }

    &--available {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
    }

    &--available &__chip {
        background: unset;
        color: var(--color-txtAcc);
    }

    &:not(:last-child) {
        margin-right: rem(16px);
    }
    
    &__chip {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(16px);
        border: solid 1px #efefef;
        border-radius: 29px;
        display: inline-block;
        user-select: none;
        padding: rem(9px) rem(24px);
        background: #e9e8e8;
        color: #999999;
        transition: all .3s ease-in-out;
    }

    &--selected &__chip {
        background-color: var(--color-bgYellowLight);
        border: solid 1px #fee04a;
        font-size: rem(20px);
        padding: rem(15px) rem(29px);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: rem(16px);
        letter-spacing: normal;
    }
}
