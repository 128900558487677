@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw22-confirmed-profile {
    @include wizard(&);
    display: flex;
    flex-direction: column;

    &__container {
        display: none;

        &--show {
            display: block;
        }
    }

    &__wrapper {
        display: none;
    }
    &__container--show &__wrapper {
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        @include respond-above(l) {
            flex-flow: row;
        }
    }

    &__image {
        width: rem(148px);
        height: auto;
        padding-right: rem(12px);
        padding-left: rem(12px);

        @include respond-above(l) {
            padding: 0;
            margin-right: rem(12px);
        }
    }

    &__result {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        max-width: rem(464px);
    }

    &__title {
        padding-left: rem(12px);
        padding-right: rem(12px);
    }

    &__txt, &__refreshTxt {
        margin-top: rem(8px);
        padding-left: rem(12px);
        padding-right: rem(12px);
        @extend %font-heading5;
        @extend %txt;
    }

    &__refreshTxt {
        border-radius: rem(12px);
        padding: rem(12px);
        margin-top: rem(12px);
        background: #FFF6CD;
    }

    &__link {
        @extend %buttonReset;
        @extend %button-primary3;
        display: block;
        text-align: center;
        align-self: flex-end;
        margin-top: rem(40px);
        width: 100%;

        @include respond-above(l) {
            margin-top: rem(72px);
            min-width: rem(248px);
            width: unset;
        }
    }
}
