@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mf6-order-detail {
    $root: &;

    /* heading */

    &__heading {
        /* follow breakpoint of ml2-navlayout dropdown menu &__selectedMob (l) */
        @include respond-below(l) {
            margin-top: rem(24px);
        }
    }

    &__orderAndState {
        display: flex;
        flex-flow: column-reverse;

        @include respond-above(l) {
            flex-flow: row;
            align-items: center;
        }
    }

    &__orderTitle {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__state {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        width: fit-content;
        border-radius: rem(12px);
        padding: rem(5px) rem(16px) rem(5px) rem(16px);
        user-select: none;
        margin-bottom: rem(14px);

        @include respond-above(l) {
            margin-left: rem(12.1px);
            margin-bottom: unset;
        }

        /* state colors */
        @include orderStateColors();
    }

    &__placedOn {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            margin-top: rem(11px);
            margin-bottom: rem(10.5px);
        }
    }

    &__modifiableInfo {
        display: flex;
        margin-bottom: rem(17.5px);

        @include respond-above(l) {
            margin-bottom: unset;
        }
    }
    &__infoIcon {
        flex: 0 0 auto;
        object-fit: contain;
        width: rem(16px);
        height: rem(17px);
        background: url('#{$resourcesFolder}/myconad/icons/info-gray.svg');
        margin-right: rem(10px);
    }
    &__infoText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
    }

    /* booking info */

    &__manageOrder {
        display: flex;
        flex-flow: row-reverse;
        position: relative;

        @include respond-above(l) {
            top: -88px;
        }
    }
    &__manageOrderLabel {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        cursor: pointer;

        /* icon */
        & > span {
            display: inline-block;
            transition: transform 0.3s ease-in-out;
        }
    }
    &__manageOrderLabel:focus-visible {
        @extend %button-focused;
    }
    &__manageOrderLabel--show > span {
        transform: rotateX(180deg);
    }
    &__manageOrderLabel--show + &__manageOrderDropdown {
        visibility: visible;
        pointer-events: all;
        max-height: 500px;
    }
    &__manageOrderDropdown {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
        max-height: 0;
        transition: all 0.6s ease-in;
        top: calc(100% + 16px);
        right: 0;
        max-width: rem(343px);
        width: calc(100% - 31.8px);
        background-color: var(--color-bgDropdown);
        border: 1px solid lightness(--color-borderTextfield, 80%);
        box-shadow: 0 8px 16px -4px alpha(--color-boxShadow, 0.15), 0 0 1px 0 alpha(--color-boxShadow, 0.2);
        border-radius: 16px;
        overflow: hidden;
        z-index: setZ(dropdown);

        @include respond-above(l) {
            top: calc(100% + 8px);
        }
    }
    &__manageOrderOption {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: var(--color-txtDark);
        padding: rem(19.5px) rem(16px);
        display: block;
        user-select: none;
        width: 100%;
    }
    &__manageOrderOption:focus-visible,
    &__manageOrderOption:hover {
        background-color: alpha(--color-bgDark, 0.6) !important;
    }
    &__manageOrderOption:focus-visible {
        @extend %button-focused;
    }

    &__bookingInfoContent {
        background-color: var(--color-secondary1);
        border-radius: 12px;
        margin-top: rem(16px);

        @include respond-below(l) {
            padding: rem(32px) rem(27px) rem(32px) rem(24px); /* padding mobile (< 1024px) */
        }
        @include respond-above(l) {
            display: flex;
            justify-content: space-between;
            padding: rem(32px) rem(32px) rem(18px); /* padding desktop (>= 1024px, < 1440px) */
        }
        @include respond-above(xl) {
            padding: rem(32px) rem(56px) rem(18px); /* padding desktop large (>= 1440px) */
        }
    }

    &__label {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }
    &__labelIcon {
        font-size: rem(24px);
        position: relative;
        bottom: -5px;
        margin-right: rem(8px);
    }
    &__divider {
        background-color: lightness(--color-txt, 90%);
        width: auto;
        height: 1px;
        margin-top: 15px;
        margin-bottom: 16px;
        margin-left: unset;
        margin-right: unset;

        @include respond-above(l) {
            width: 1px;
            height: auto;
            margin-top: unset;
            margin-bottom: unset;
            margin-left: 18.3px;
            margin-right: 17.3px;
        }
    }
    &__labelText {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);
    }

    &__modifyDate {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        margin-top: rem(14px);

        @include respond-above(l) {
            margin-top: rem(8px);
        }
    }
    &__modifyDate:focus-visible {
        @extend %button-focused;
    }

    /* preparingPdv */

    &__preparingPdv {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        border-bottom: 1px solid lightness(--color-txt, 90%);

        @include respond-above(l) {
            padding-top: rem(12.8px);
            padding-bottom: rem(20.3px);
        }
    }

    /* paymentDetails */

    &__paymentDetailsTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-txtAcc);
        margin-bottom: rem(16px);
    }

    &__paymentDetails {
        margin-top: rem(28px);
    }

    &__paymentCalc {
        margin-bottom: rem(16px);
    }

    &__paymentEntry,
    &__orderTotal {
        display: flex;
        justify-content: space-between;
    }
    &__paymentEntry {
        padding-top: rem(8px);
        padding-bottom: rem(8px);
    }

    &__paymentEntryLabel {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        display: flex;
    }
    &__paymentEntryLabelTooltip {
        align-self: center;
        margin-left: rem(5px);
    }

    &__paymentEntryValue,
    &__pointsEntryValue {
        @extend %robotoFontMedium;
        font-size: rem(16px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }

    /* payment entry modifiers */
    &__paymentEntry--borderBottom-solid {
        border-bottom: 1px solid lightness(--color-txt, 90%);
    }
    &__paymentEntry--borderBottom-dashed {
        border-bottom: 2px dashed lightness(--color-txt, 90%);
    }
    &__paymentEntry--padTop-none {
        padding-top: 0;
    }
    &__paymentEntry--padTop-2x {
        padding-top: rem(16px);
    }
    &__paymentEntry--padBottom-none {
        padding-bottom: 0;
    }
    &__paymentEntry--padBottom-2x {
        padding-bottom: rem(16px);
    }
    &__paymentEntry--fontUpBold > &__paymentEntryLabel {
        font-size: rem(18px);
        line-height: rem(24px);
    }
    &__paymentEntry#{$root}__preauth > &__paymentEntryLabel {
        font-size: rem(20px);
        line-height: rem(20px);
    }
    &__paymentEntry--fontUpBold > &__paymentEntryValue {
        @extend %robotoFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
    }
    &__paymentEntry--promoRed {
        margin-top: rem(4px) !important;
    }
    &__paymentEntry--promoRed > &__paymentEntryLabel {
        padding-left: rem(16px);
    }
    &__paymentEntry--promoRed > &__paymentEntryValue {
        color: var(--color-primary);
    }

    &__orderTotal > &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-txtAcc);
    }
    &__orderTotalValue {
        @extend %robotoFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }

    &__vatIncluded {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
        color: var(--color-gray);
        margin-top: rem(4px);
    }

    /* payment method */

    &__paymentMethod {
        margin-top: rem(11px);

        @include respond-above(l) {
            margin-top: rem(24px);
        }

        & > p:first-of-type {
            margin-bottom: rem(4px);
        }

        & > p:last-of-type {
            margin-top: rem(8px);
        }
    }

    &__receiptDownload {
        @extend %dmSansFontMedium;
        color: var(--color-info);
        text-decoration: underline;
    }

    &__paymentConfirmed {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('#{$resourcesFolder}/myconad/icons/checked-black.svg');
        position: relative;
        bottom: -2px;
        margin-left: 5px;
    }

    /* points info */

    &__pointsInfo {
        margin-top: rem(24px);
        padding-bottom: rem(24px);
        border-bottom: 1px solid lightness(--color-txt, 90%);
    }

    &__pointsInfoContent {
        background-color: var(--color-secondary1);
        border-radius: 12px;
        padding: rem(24px) rem(13.3px) rem(24px) rem(11.8px);

        @include respond-above(l) {
            padding: rem(26px) rem(24px) rem(24px) rem(24px);
        }
    }

    &__pointsEntry {
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
            margin-top: rem(28px);
        }
    }
    &__pointsEntry--indented:not(:first-child) {
        margin-top: rem(16px);
    }
    &__pointsEntry--indented {
        margin-left: rem(24px);
    }
    &__pointsEntry--insieme > &__pointsEntryLabel::before,
    &__pointsEntry--insiemePlus > &__pointsEntryLabel::before {
        content: ' ';
        display: inline-block;
        width: rem(24px);
        height: rem(21px);
        background-size: rem(24px);
        background-repeat: no-repeat;
        margin-right: rem(5px);
        position: relative;
        bottom: -4px;
    }
    &__pointsEntry--insieme > &__pointsEntryLabel::before {
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-orange.svg');
    }
    &__pointsEntry--insiemePlus > &__pointsEntryLabel::before {
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-gray.svg');
    }

    &__pointsEntryLabel {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
    }
    &__pointsEntry--indented > &__pointsEntryLabel {
        color: alpha(--color-darkCharcoal, 0.7);
    }

    &__pointsText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        margin-top: rem(28px);
    }

    /* products */

    &__products {
        margin-top: rem(32px);
        padding-bottom: rem(16px);
        border-bottom: 1px solid lightness(--color-txt, 90%);
        margin-bottom: rem(32px);
    }

    &__productsAccordionHeading {
        @extend %buttonReset;
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        /* title */
        & > h3 {
            flex: 1 1 auto;
        }

        /* icon */
        & > span:last-of-type {
            display: inline-block;
            font-size: rem(24px);
            transition: transform 0.3s ease-in-out;
        }
    }

    &__productsAccordionHeading:focus-visible {
        @extend %button-focused;
    }

    &__productsAccordionHeading--open > span:last-of-type {
        transform: rotateX(180deg);
    }

    &__countProducts {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-txtAcc);
        background-color: lightness(--color-txt, 95%);
        padding: rem(5px) rem(16px);
        border-radius: rem(12px);
        margin-right: rem(14px);
    }

    &__productsAccordionContent {
        display: block;
        visibility: hidden;
        pointer-events: none;
        max-height: 0;
        transition: all 0.4s ease-in-out;
        overflow: hidden;
    }

    &__productsAccordionHeading--open ~ &__productsAccordionContent {
        visibility: visible;
        pointer-events: all;
    }

    &__productsAccordionWrapper {
        display: flex;
        flex-flow: column;
    }

    &__productCard {
        transition: margin-top 0.5s ease-out;
    }

    &__addRemoveProductsCta {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
        align-self: flex-start;
        display: block;
        margin-top: rem(21px);

        @include respond-above(l) {
            margin-top: rem(25px);
        }
    }

    &__addRemoveProductsCta:focus-visible {
        @extend %button-focused;
    }

    &__addRemoveProductsCta ~ &__productCard:first-of-type {
        margin-top: rem(24px);

        @include respond-above(l) {
            margin-top: rem(25px);
        }
    }

    &__freeProductCard {
        margin-top: rem(15px);
    }

    &__productCard:not(:first-of-type) {
        margin-top: rem(15px);
    }

    /* cancel order */

    &__cancel {
        margin-top: rem(32px);
        margin-bottom: rem(61px);

        @include respond-above(l) {
            margin-top: rem(56px);
            margin-bottom: rem(50px);
        }
    }

    &__cancelOrderCta {
        @extend %buttonReset;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        cursor: pointer;
        padding: rem(20px) rem(32px);
        border: 2px solid var(--color-primary3);
        border-radius: 12px;

        @include respond-above(l) {
            width: fit-content;
        }

        &--hidden {
            display: none;
        }
    }
    &__cancelOrderCta:focus-visible {
        @extend %button-focused;
    }

    &__preauthCta {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(11px);
        line-height: rem(16px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
    }
}
