@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw5-register-bimodal {
    @include wizard(&);

    &__subtitle {
        color: var(--color-txtTab);
        font-size: rem(17px);
        line-height: rem(17px);
        margin-top: rem(28px);
    }

    &__error {
        margin-top: rem(24px);
        overflow: hidden;
        display: flex;

        span {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }

        a {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &__userDataWrapper {
        height: rem(638px);
        transition: height 2s;
        margin-bottom: rem(25px);

        @include respond-above(m) {
             height: rem(554px);
        }

        &--showRecap {
            height: (150px);

            @include respond-above(m) {
                 height: rem(130px);
            }
        }
    }

    &__form {
        opacity: 1;
        max-height: 100%;
        transition: all 2s;
        border-bottom: 0;

        &--hidden {
          position: relative;
          z-index: -1;
          visibility: hidden;
          opacity: 0;
          padding: 0;
          max-height: 0;
        }
    }

    &__email {
        align-self: stretch;
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-right: rem(8px);
        }

        &--locked {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__emailConfirm {
        margin-top: rem(24px);

        &--hidden {
            display: none !important;
        }
    }

    &__emailMismatch {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        padding-left: rem(16px);
        display: flex;
        align-items: center;
        overflow: hidden;
        visibility: visible;
        pointer-events: all;
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__password {
        align-self: stretch;
        margin-top: rem(24px);

        &--valid {
            border-color: var(--color-secondary3);
        }

        &--hidden {
            display: none !important;
        }
    }

    &__confirmPassword {
        margin-top: rem(24px);
    }

    &__passwordRules--hidden + &__confirmPassword {
        margin-top: 0;
    }

    &__passwordRules {
        @extend %font-body2;
        margin-top: rem(24px);
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            max-height: 0;
        }
    }

    &__passwordMismatch {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        padding-left: rem(16px);
        display: flex;
        align-items: center;
        overflow: hidden;
        visibility: visible;
        pointer-events: all;
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__passwordState {
        flex: 0 0 auto;
        margin-right: rem(16px);
        margin-top: rem(5px);
        & > .info {
            font-size: rem(16px);
            background-color: var(--color-info);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .ok {
            font-size: rem(16px);
            background-color: var(--color-secondary3);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .error {
            font-size: rem(16px);
            background-color: var(--color-primary);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .active {
            display: block;
        }
    }

    &__passwordText {
        color: var(--color-txtTab);
    }
    &__passwordMismatchText {
        color: var(--color-primary);
    }
    &__emailMismatchText {
        color: var(--color-primary);
    }

    &__editPersonalData {
        color: var(--color-info);
        cursor: pointer;
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
        text-decoration: underline;
        font-weight: 500;
    }

    &__bottom {
        display: block;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        @extend %buttonReset;
        @extend %button-primary3;
        display: inline-block;
        align-self: flex-end;
        margin-top: rem(40px);
        text-align: center;
        pointer-events: all !important;
        width: 100%;
        display: none;

        @include respond-above(m) {
            width: unset;
        }


        &--show {
            display: block;
        }
    }

    &__referencePdvTitle {
        @extend %font-heading5;
        @extend %dmSansFontBold;

        @include respond-above(m) {
            font-size: rem(20px);
            line-height: 1.4em;
        }

        &--disabled {
            color: var(--color-bgButton);

            & > span {
                color: var(--color-bgButton);
            }
        }

        & > span {
            font-size: rem(20px);
            line-height: 1.4em;
            margin-right: rem(11px);

            @include respond-above(m) {
                font-size: rem(24px);
                line-height: rem(24px);
            }
        }
    }

    &__referencePdvText {
        @extend %txt;
        margin-top: rem(8px);
        opacity: 1;
        transition: opacity 1s;

        &--hidden {
            opacity: 0;
            visibility: hidden;
            margin: 0;
            padding: 0;
        }
    }

    /* buttons */
    &__referencePdvButton {
        @extend %buttonReset;
        color: var(--color-primary3);
        width: 100%;
        height: rem(99px);
        border: 2px dotted alpha(--color-txt, 0.3);
        border-radius: rem(12px);
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(52.5px);
        transition: all 1s ease-in-out;
        opacity: 1;

        span {
            font-size: rem(16px);
            line-height: rem(16px);
            font-weight: 600;
            margin-left: rem(4px);
            color: var(--color-primary3);
        }

        &--hidden {
            opacity: 0;
            visibility: hidden;
            margin: 0;
            padding: 0;
            height: 0;
            border: 0;
        }
    }

    &__storeWrapper {
        opacity: 0;
        max-height: 0;
        margin: 0;
        padding: 0;
        transition: all 1s ease-in-out;

        &--show {
            border-top: 1px solid alpha(--color-borderTextfield, 0.1);
            padding-top: rem(25px);
            margin-top: rem(25px);
            max-height: rem(400px);
            opacity: 1;
        }

        &-data {
            justify-content: space-between;
            align-items: center;
            display: flex;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @include respond-above(m) {
                flex-direction: row;
            }
        }

        &-left {
            display: flex;
            flex-direction: column;
        }

        &-right {
            display: flex;
            align-items: center;
            flex: 1 0 auto;
            justify-content: flex-end;
        }

        &-title {
            @extend %font-heading5;
            @extend %dmSansFontBold;
            margin-bottom: rem(4px);
        }

        &-address {
            @extend %font-body2;
            color: alpha(--color-txt, 0.8);
            margin-bottom: rem(9px);
        }

        &-distance {
            @extend %badge;
            @extend %font-label2;
            @extend %dmSansFontBold;
        }
    }

    &__storeNotEnabledWrapper {
        display: flex;
        padding: rem(15px) 0 0;
        @extend %font-body3;
        color: var(--color-txtTab);

        span {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            height: rem(16px);
            margin-top: rem(5px);
            margin-right: rem(16px);
        }

        &--hidden {
            opacity: 0;
        }
    }

    &__changePdvButton {
        @extend %buttonReset;
        @extend %font-body3;
        display: none;
        margin: 0;
        color: var(--color-info);
        text-decoration: underline;
        font-weight: 500;
        transition: opacity 1s;
        opacity: 0;

        &--show {
            display: flex;
            flex: 1 0 auto;
            justify-content: flex-end;
            align-items: flex-end;
            opacity: 1;
        }

        &--hidden {
            opacity: 0;
            visibility: hidden;
            margin: 0;
            padding: 0;
        }
    }

    &__pdvSelect {
        border: 1px solid alpha(--color-txt, 0.3);
        border-radius: 0.75rem;
        padding: 25px;
        transition: max-height 1s;
        max-height: rem(86px);

        @include respond-above(m) {
            max-height: rem(80px);
        }

        &--disabled {
            border: 1px solid alpha(--color-borderTextfield, 0.1);
        }

        &--hidden {
            display: none;
        }

        &--withBackground {
            background: #FFFFFF;
            max-height: rem(550px);
        }
    }

    &__referencePdvButton:hover {
        background-color: alpha(--color-bgDark, 0.05);
    }

    &__nextButton {
        float: right;
        text-align: center;
        display: inline-block;
        @extend %button-primary3;

        &--disabled {
            pointer-events: none;
            color: var(--color-txtTab);
            background-color: var(--color-bgButton);
        }

        &--hidden {
            display: none;
        }
    }

    &__userDataRecapWrapper {
        display: flex;
        flex-direction: column;
        opacity: 1;
        transition: opacity 1s;
        padding-bottom: rem(25px);
        margin-bottom: rem(25px);
        border-bottom: 1px solid alpha(--color-borderTextfield, 0.1);


        &--hidden {
            display: none;
            opacity: 0;
        }

        span {
            font-weight: bold;
        }
    }
}
