@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf12-wishlist {
    display: flex;
    flex-flow: column;
    padding-bottom: rem(48px);

    @include respond-below(l) {
        margin-top: rem(24px);
    }

    &__alert {
        margin-top: rem(-5px);
        margin-bottom: rem(24px);
    }

    &__topBar {
        padding-bottom: rem(14px);
        border-bottom: 1px solid alpha(--color-black, 0.1);

        @include respond-above(l) {
            display: flex;
            justify-content: space-between;
            margin-bottom: rem(16px);
        }
    }

    &__numProductsAndAddAll {
        display: flex;
        flex-flow: row;
        justify-content: flex-end; /*space-between;*/
        align-items: center;
        margin-top: rem(22px);

        @include respond-above(l) {
            flex-flow: column;
            align-items: flex-end;
            margin-top: unset;
        }
    }

    &__numProducts {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-txtAcc);
    }

    &__addAll {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        cursor: pointer;

        @include respond-above(l) {
            margin-bottom: rem(8px);
        }

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }

        /* icon */
        & > span {
            position: relative;
            bottom: -2.5px;
            font-size: rem(16px);
            margin-right: rem(4px);
        }
    }

    &__content {
        padding-bottom: rem(41px);

        &--hidden {
            display: none;
        }
    }

    &__iframe {
        border: unset;
    }

    &__loadMore {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(124px);
        height: rem(40px);
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        cursor: pointer;
        text-decoration: underline;

        &--hidden {
            display: none;
        }
    }

    &__noProducts {
        padding-top: rem(32px);

        @include respond-above(l) {
            padding-top: rem(16px);
        }

        &--hidden {
            display: none;
        }
    }

    &__noProductsTitle {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        padding-bottom: rem(8px);
    }

    &__noProductsText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        margin-bottom: rem(32px);
    }

    &__noProductsLink {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtDark);
        display: inline-block;
        padding: rem(20px) rem(32px);
        background-color: var(--color-primary3);
        border-radius: 12px;
    }
}