@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw10-thankyou {
    @include wizard(&);
    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        align-items: flex-start;
        flex-flow: column;

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__image {
        width: rem(227px);
        height: rem(136px);
        object-fit: contain;
        margin-bottom: rem(12px);
        object-position: left;

        @include respond-above(m) {
            margin-bottom: unset;
            margin-right: rem(12px);
            object-position: right;
        }
    }

    &__result {
        flex: 1 1 auto;
        max-width: rem(464px);
    }

    &__thankTitle {
    }

    &__thankTxt {
        margin-top: rem(8px);
        @extend %font-heading5;
        @extend %txt;
    }

    &__thankResend {
        margin-top: rem(54px);
        @extend %font-heading5;
    }

    &__thankResendBtn {
        @extend %buttonReset;
        color: var(--color-info);
        text-decoration: underline;
        font-weight: 500;

        &--disabled {
            pointer-events: none;
            opacity: 40%;
        }
    }

    &__resendedMessage {
        margin-top: rem(20px);
        overflow: hidden;
        display: flex;
        opacity: 1;
        transition: opacity 1s;

        @include respond-above(m) {
            width: 105%;
        }

        span {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/info16.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-heading5;
        color: var(--color-info);
        padding: rem(17px) rem(16px);
        background: var(--color-secondary8);
        border-radius: rem(16px);

        &--hidden {
            opacity: 0;
        }

        a {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &__continueNavigation {
         @extend %buttonReset;
         @extend %button-primary3;
         display: block;
         text-align: center;
         margin-top: rem(25px);
         width: 100%;

         @include respond-above(m) {
             min-width: rem(248px);
             width: rem(248px);
         }
     }
}
