@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt31-textfield-locked {
    height: rem(56px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(8px) rem(16px);
    border-radius: 16px;
    border: 1px solid alpha(--color-border, 0.05);

    &__label {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(13px);
        color: var(--color-txtAcc);
    }
    &__value {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
    }
    &__icon {
        width: 16px;
        height: 16px;
        object-fit: cover;
        background-image: url('#{$resourcesFolder}/myconad/icons/lock.svg');
    }
}