@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt2-addressfield {
    position: relative;
    width: 100%;
    display: block;

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(24px);
        display: block;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        border-radius: rem(16px);
        border: 1px solid alpha(--color-borderTextfield, 0.3);
        
        /* search button always present */
        padding-left: rem(60px);
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
        line-height: rem(39px);
    }

    &__input:focus + &__label,
    &__input:not(:placeholder-shown) + &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: alpha(--color-txt, 0.8);
        background-color: transparent;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: alpha(--color-txt, 0.8);

        /* search button always present */
        margin-left: rem(45px);
    }

    &__cancel {
        @extend %buttonReset;
        display: block;
        width: rem(16px);
        height: rem(16px);
        background-image: url('#{$resourcesFolder}/myconad/icons/close-circle.svg');
        background-size: 16px;
        position: absolute;
        top: 35%;
        right: 16px;
        cursor: pointer;
        opacity: 1;
        pointer-events: all;
        transition: opacity .2s ease-in;

        &--hidden {
            opacity: 0;
            pointer-events: none;
            cursor: default;
        }
    }
    &__cancel:focus-visible {
        @extend %button-focused;
    }

    &__searchBtn {
        @extend %buttonReset;
        position: absolute;
        left: rem(3px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
        color: lightness(--color-txt, 30);
        border-radius: 14px;
        width: rem(50px);
        height: rem(50px);
        text-align: center;
        transition: background-color 0.2s;

        & > .on {
            display: block;
        }
        & > .off {
            display: none;
        }
    }
    &__searchBtn:focus-visible {
        @extend %button-focused;
    }

    &--errorState &__input {
        border: 1px solid var(--color-negativeInfo); //lightness(--color-borderError, 40%);
    }
    &--validState.mt2-addressfield--validEnabled &__input {
        border: 1px solid var(--color-positiveInfo); //lightness(--color-secondary2, 40%);
    }

    /* fix autocomplete hover on webkit browsers */
    input:-webkit-autofill:hover ~ &__label {
        color: alpha(--color-txt, 0.8);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }

    &__detectLocationContainer {
        position: absolute;
        top: 100%;
        width: 100%;
        overflow: hidden;
        z-index: setZ(dropdown);
        background-color: #333;
        border-radius: 16px;
        margin-top: 4px;
        box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
        display: none;

        &--active {
            display: block;
        }
    }

    &__detectLocationItem {
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        padding-left: rem(18px);
        padding-right: rem(16px);
        background-color: var(--color-darkCharcoal);
        cursor: pointer;
    }
    &__detectLocationItem:focus-visible,
    &__detectLocationItem:hover {
        background-color: #191919;
    }

    &__detectLocationLink {
        @extend %buttonReset;
        display: flex;
        align-items: center;
    }
    &__detectLocationLink:focus-visible {
        @extend %button-focused;
    }

    &__detectLocationIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('#{$resourcesFolder}/myconad/icons/target.svg');
        background-size: 20px;
        margin-right: rem(10px);
    }

    &__detectLocationText {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(24px);
        color: var(--color-txtDark);
    }
}
