@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm3-add-card {
    @include modal(&, true);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__formSection {
        margin-top: rem(24px);

        &:first-child {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            display: flex;
            justify-content: space-between;

            &--single {
                max-width: 50%;
            }
        }
    }

    &__cfCalc {
        position: absolute;
        @extend %button-primary3;
        text-align: center;
        min-width: rem(99px);
        top: rem(3px);
        right: rem(3px);
        max-height: rem(50px);
        padding-top: rem(15px);
        padding-bottom: rem(15px);
    }

    &__cf > input {
        padding-right: rem(120px);
    }

    &__formItem {
        flex: 1 0 0;
    }

    &__formItem {
        @include respond-above(m) {
            margin-right: rem(8px);
        }
    }

    &__formItem + &__formItem {
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-top: 0;
            margin-left: rem(8px);
        }
    }

    &__formItem:last-child {
        margin-right: 0;
    }

    &__formItem:first-child {
        @include respond-above(m) {
            margin-right: rem(8px);
        }
    }
    &__cardNumber {
        margin-right: unset !important;
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__addCard {
        @extend %button-primary3;
        text-align: center;
        min-width: rem(138px);
        pointer-events: all !important;
    }
    &__addCard:focus-visible {
        @extend %button-focused;
    }
}
