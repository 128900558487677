@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt20-tooltip {
    &__icon {
        @extend %buttonReset;
        background-repeat: no-repeat;

        &--normal {
            background-image: url('#{$resourcesFolder}/myconad/icons/info.svg');
            background-size: 24px;
            width: 24px;
            height: 24px;
        }
        &--small {
            background-image: url('#{$resourcesFolder}/myconad/icons/info16.svg');
            background-size: 16px;
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }
    }
    &__icon:focus-visible {
        @extend %button-focused;
    }
}

.mt20-tooltip-popup {
    @extend %dmSansFontRegular;
    font-size: rem(13px);
    line-height: rem(16px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: rem(292px);
    height: fit-content;
    background-color: var(--color-secondary8);
    color: var(--color-info);
    border-radius: rem(6px);
    padding: rem(6px) rem(12px);
    z-index: map-get($z-layers, popup);

    &--show {
        opacity: 1;
    }
}
