@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt29-toggle {

    &__label {
        display: block;
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 24px;
        border: 2px solid alpha(--color-border, 0.3);
        border-radius: 12px;
        transition: background-color .2s ease-in-out;

        &--disabled {
            opacity: 0.3;
            pointer-events: none;
        }
    }

    /* hover */
    &__label:hover {
        border: 2px solid var(--color-primary3);
    }
    &__label:hover &__checkbox::before {
        background-color: var(--color-primary3);
        background-image: none;
    }

    &__input {
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }

    &__checkbox {
        width: 40px;
        height: 24px;

        &::before,
        &::after {
            display: inline-block;
            position: relative;
            left: 2px;
            top: 2px;
            content: ' ';
            width: rem(16px);
            height: rem(16px);
            transition: opacity .2s ease-in-out, left .2s ease-in-out;
        }
        &::before {
            opacity: 1;
            border-radius: 50%;
            background-image: url('#{$resourcesFolder}/myconad/icons/untoggle.svg');
            background-size: contain;
        }
        &::after {
            opacity: 0;
            left: -14px;
            background-color: var(--color-bg);
            border-radius: 50%;
            background-image: url('#{$resourcesFolder}/myconad/icons/toggle.svg');
            background-size: contain;
        }
    }
    &__input:checked + &__checkbox::after {
        left: 2px;
        opacity: 1;
    }
    &__input:checked + &__checkbox::before {
        left: 18px;
        opacity: 0;
    }

    /* checked */
    &--checked &__label {
        background-color: var(--color-primary3);
        border: 2px solid var(--color-primary3);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
