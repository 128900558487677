@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt17-checkbox {
    position: relative;
    
    /* custom checkbox */
    &__label {
        position: relative;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }
    &__input {
        opacity: 0;
        position: absolute !important; /* to overwrite Accessiway positioning */
        cursor: pointer;
    }

    &__checkbox {
        &::before,
        &::after {
            content: '';
            position: absolute;
            vertical-align: middle;
            width: rem(21px);
            height: rem(21px);
            top: rem(1px);
            left: 0;
        }
        &::before {
            display: inline-block;
            border: 2px solid alpha(--color-border, 0.3);
            border-radius: rem(5px);
        }
        &::after {
            display: none;
            width: rem(24px);
            height: rem(24px);
            top: rem(-0.5px);
            left: rem(-1.6px);
            background-image: url('#{$resourcesFolder}/myconad/icons/checkbox.svg');
        }
        &:hover::before {
            border-color: var(--color-primary3);
        }
    }
    &__input:focus + &__checkbox::before {
        border-color: var(--color-primary3);
    }
    &__input:checked + &__checkbox::after {
        display: block;
    }
    &__input:checked + &__checkbox::before {
        border-color: var(--color-primary3);
    }
    &--errorState &__checkbox::before {
        border: 1px solid var(--color-negativeInfo);
    }

    &__labelText {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(21px);
        color: var(--color-txtAcc);
        padding-left: rem(32px);
        &--html {
            @extend %txt;
            font-weight: normal;
        }
    }

    /* tooltip global position (set by parent) */
    &__tooltip {
        display: inline-block;
        position: relative;
        bottom: -4px;
        margin-left: 4px;

        & > button {
            vertical-align: top;
        }
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(32px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
