@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt40-customer-care-request {
    $color-border-request: #ABABAB;
    $color-border-info: #959595;
    $color-border-btn-dsk: #959595;
    $color-bg-hover-btn-dsk: #D9D9D9;
    $color-content-heading-separator: #E0DED7;
    $color-border-ctas: #A8A8A8;

    background-color: var(--color-white);
    border: 0.5px solid $color-border-request;
    border-radius: rem(12px);
    box-shadow: 0 4px 8px -2px alpha(--color-borderSand, 0.15), 
                0 0 1px 0 alpha(--color-borderSand, 0.2);
    padding-top: rem(16px);
    padding-left: rem(42px);
    padding-right: rem(42px);
    padding-bottom: rem(24px);

    @include respond-above(l) {
        border-radius: rem(8px);
        box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        padding-top: rem(16px);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
    }

    &__heading {
        display: flex;
        flex-flow: column;
        align-items: center;

        @include respond-above(l) {
            flex-flow: row;
            align-items: stretch;
            padding-left: rem(24px);
            padding-right: rem(24px);
        }
    }

    &__info {
        text-align: center;
        padding-left: rem(19px);
        padding-right: rem(19px);
        border-bottom: 1px solid $color-border-info;

        @include respond-above(l) {
            text-align: left;
            padding-left: unset;
            padding-right: unset;
            border-bottom: unset;
            flex-grow: 1;
        }
    }
    
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            margin-bottom: rem(10px);
        }
    }

    &__site {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(9px);

        @include respond-above(l) {
            display: inline-block;
            margin-bottom: unset;
        }

        /* icon */
        & > span:first-child {
            position: relative;
            bottom: -2px;
            margin-right: rem(6px);
        }
    }

    &__created {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            display: inline-block;
            margin-bottom: unset;

            &::before {
                content: '-';
                font-size: rem(15.2px);
                font-weight: normal;
            }
        }

        /* date */
        & > span:last-child {
            @extend %dmSansFontBold;
        }
    }

    &__status {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(23.5px);

        @include respond-above(l) {
            display: inline-block;
            margin-bottom: unset;

            &::before {
                content: '-';
                font-size: rem(15.2px);
                font-weight: normal;
            }
        }

        /* badge */
        @include ccRequestBadges();
    }

    &__btnOpenMob {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        margin-top: rem(23.5px);

        /* hide on desktop */
        @include respond-above(l) {
            display: none;
        }
    }

    &__btnOpenDsk {
        @extend %buttonReset;
        position: relative;
        display: inline-block;
        height: calc(100% + 32px);
        margin-top: rem(-16px);
        padding-right: rem(24px);
        margin-right: rem(-24px);
        border-top-right-radius: rem(8px);
        border-bottom-right-radius: rem(8px);
        transition: border-bottom-right-radius .45s linear;

        &[aria-expanded="true"] {
            border-bottom-right-radius: 0;
            transition-duration: 10ms;
        }

        &[aria-label] {
            position: relative;
        }

        &[aria-label]:hover:after {
            content: attr(aria-label);
            display: none;
            width: rem(140px);
            height: rem(40px);
            font-size: rem(13px);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            display: flex;
            flex-direction: column;
            padding: rem(16px) rem(16px);
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: #333333;
            position: absolute;
            z-index: 1;
            bottom: rem(-8px);
            right: rem(30px);
            color: #ffffff;
        }

        /* border left */
        & > span:first-child {
            vertical-align: middle;
            display: inline-block;
            height: calc(100% - 40px);
            width: 1px;
            background-color: $color-border-btn-dsk;
        }

        /* icon */
        & > span:last-child {
            display: inline-block;
            vertical-align: middle;
            font-size: rem(24px);
            padding-left: rem(16px);
            transition: transform .2s ease-in-out;
        }
        &[aria-expanded="true"] > span:last-child {
            transform: rotateX(180deg);
        }

        /* hide on mobile */
        @include respond-below(l) {
            display: none;
        }
    }
    &__btnOpenDsk:focus-visible,
    &__btnOpenDsk:hover {
        background-color: $color-bg-hover-btn-dsk;

        /* border left */
        & > span:first-child {
            width: unset;
        }
    }

    &__content {
        overflow: hidden;
        visibility: hidden;
        max-height: 0;
        transition: visibility .3s linear, 
                    max-height .3s linear;
        
        @include respond-above(l) {
            margin-top: rem(16px);
        }
    }

    &__contentHeading {
        display: flex;
        justify-content: space-between;
        background-color: var(--color-secondary1);
        padding-top: rem(18px);
        padding-bottom: rem(18px);
        padding-left: rem(45px);
        padding-right: rem(45px);
    }

    &__contentHeadingSection {
        flex: 1 1 0;
        min-width: 172px;
        margin-top: rem(5px);

        /* titles */
        & > h4 {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-txtAcc);
            margin-bottom: rem(4px);
        }

        & > p {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-txtAcc);
        }

        & > p > a {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__contentHeadingSeparator {
        flex: 1 0 0;
        margin-left: rem(31px);
        margin-right: rem(31px);

        & > div {
            background-color: $color-content-heading-separator;
            width: 1px;
            height: rem(55px);
            margin: 0 auto;
        }
    }

    &__contentBody {
        padding-top: rem(32px);
        padding-left: rem(45px);
        padding-right: rem(45px);
        padding-bottom: rem(32px);
    }

    &__descriptionTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }

    &__descriptionText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
        padding: rem(16px);
        background-color: var(--color-secondary1);
    }

    /* documents and linkedOrder wrapper */
    &__contentBody > div:first-of-type {
        display: flex;
        justify-content: space-between;
        margin-top: rem(32px);
    }

    &__documents {

        & > ul > li {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(24px);
            color: var(--color-txtAcc);

            & > a {
                display: flex;
                align-items: center;
            }

            /* icons */
            & > a > span {
                font-size: rem(16px);
            }
            & > a > span:first-of-type {
                margin-right: rem(8px);
            }
            & > a > span:last-of-type {
                margin-left: rem(8px);
                transform: rotateZ(320deg);
            }
        }
        & > ul > li:not(:first-child) {
            margin-top: rem(12px);
        }
    }

    &__documentsTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);
    }

    &__documentName:hover {
        text-decoration: underline;
    }

    &__linkedOrder {
        margin-right: rem(64px);

        /* order link */
        & > a {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-txtAcc);
            white-space: nowrap;
            display: flex;
            align-items: center;
        }

        /* chevron icon */
        & > a > span:last-of-type {
            font-size: rem(16px);
        }
    }

    &__linkedOrderCode:hover {
      text-decoration: underline;
    }

    &__linkedOrderTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);
    }

    &__noDocuments,
    &__noLinkedOrder {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(24px);
        color: var(--color-txtTab);
    }

    &__ctas {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: rem(24px);
        padding-top: rem(31px);
        border-top: 1px solid $color-border-ctas;
        margin-top: rem(32px);
    }

    &__minimize {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-info);
        text-decoration: underline;
    }

    &__closeRequest {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        background-color: var(--color-white);
        padding: rem(18px) rem(30px);
        border: 2px solid var(--color-primary3);
        border-radius: rem(12px);
    }
}