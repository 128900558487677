@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt13-coupon-popup {
    --value-tag-color: #fed404;
    --value-text-color: var(--color-txtButton);
    --value-triangle-color: #cf9403;

    &__content {
        display: flex;
        flex-flow: column;
        border-radius: rem(12px);
        background: var(--color-white);

        @include respond-below(m) {
            background: url('#{$resourcesFolder}/myconad/icons/coupon-bg.svg');
            background-size: rem(345px) auto;
            background-repeat: no-repeat;
        }

    }

    &__usageCount{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: rem(152px);
        left: rem(24px);

        @include respond-below(m) {
            top: rem(91px);
            left: rem(15px);
        }
    }


    &__usageTitleCount{
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
    }

    &__usageTextCount{
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
    }

    &__closeButton{
        position: absolute;
        top: rem(32px);
        right: rem(7px);
        width: rem(40px);
        height: rem(40px);
        background: url('#{$resourcesFolder}/myconad/icons/close-circle-no-bg.svg');
        background-size: rem(24px) rem(24px);
        background-repeat: no-repeat;
        background-color: var(--color-primary3);
        background-position: center;
        border-radius: 9999px;
        cursor: pointer;

        @include respond-above(m) {
            top: rem(80px);
        }
    }

    &__valueTag {
        position: absolute;
        background-color: var(--value-tag-color);
        height: rem(36px);
        top: rem(90px);
        right: rem(0px);
        z-index: 30;
        border-radius: rem(5px) rem(5px) rem(0px) rem(5px);
        display: flex;
        align-items: center;
        padding-right: rem(8px);
        padding-left: rem(8px);
        gap: rem(4px);

        @include respond-above(m) {
            height: rem(46px);
            top: rem(152px);
            right: rem(0px);
            padding-right: rem(16px);
            padding-left: rem(20px);
        }
    }

    &__valueText {
        @extend %robotoFontMedium;
        color: var(--value-text-color);
        font-size: rem(28px);
        line-height: rem(36px);
        padding: rem(0px) rem(0px) rem(0px) rem(8px);
        display: flex;
        align-items: center;

        @include respond-above(m) {
            font-size: rem(32px);
            line-height: rem(40px);
            padding: rem(3px) rem(0px);
        }
    }

    &__punti {
      @extend %dmSansFontMedium;
      font-size: rem(14px);
      line-height: rem(14px);
    }

    &--forLG{
        display: none;
        @include respond-above(m) {
            display: inline;
        }
    }


    &__triangle{
        position: absolute;
        top: rem(46px);
        right: rem(0px);
        width: rem(11px);
        height: rem(15px);
        background-color: var(--value-triangle-color);
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);

        @include respond-below(m) {
            top: rem(36px);
            right: rem(0px);
            width: rem(7px);
            height: rem(9px);
        }
    }

    &__media {
        height: rem(112px);

        @include respond-above(l) {
            width: rem(784px);
        }
    }

    &__info {
        padding: rem(16px) rem(16px) rem(10px) rem(16px);

        @include respond-above(l) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: rem(24px) rem(45px) rem(5px) rem(24px);
        }
    }

    &__used {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-txtAcc);
        background-color: var(--color-secondary1);
        padding: rem(3px) rem(16px) rem(3px) rem(16px);
        border-radius: rem(12px);
    }

    &__validity {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
    }

    &__bodyContainer{
        display: flex;
        flex-direction: column;
        margin-top: rem(46px);
        background-color: var(--color-white);
        border-radius: rem(12px);

        @include respond-above(m) {
            margin-top: rem(14px);
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 0 rem(5px) rem(16px) rem(16px);
        padding-right: rem(16px);
        background-color: var(--color-white);
        border-radius: rem(12px);

        @include respond-above(m) {
            padding: 0 rem(24px) rem(0px) rem(24px);
        }
    }

    &__bodyHead{
        display: flex;
        flex-flow: column;
        gap: rem(8px);
    }

    &__main {
        display: flex;
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row;
            justify-content: space-between;
        }
    }

    --status-color: #f2273b;
    &__status{
        display: flex;;
        height: rem(16px);
        justify-content: space-between;
        align-items: center;

        @include respond-above(m) {
            gap: rem(16px);
            justify-content: flex-start;
        }
    }

    &__statusText {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--status-color);
    }

    &__statusPoint{
        height: rem(6px);
        width: rem(6px);
        border-radius: 50%;
        background-color: var(--status-color);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtButton);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(16px);
        color: var(--color-txtButton);
    }

    &__usageContainer{
        display: flex;
        flex-direction: column;
        gap: rem(16px);
    }

    &__usageUse{
        padding-top: rem(16px);
        border-top: 1px solid;
        border-color: #e7e7e7;
        margin-top: rem(16px);

        @include respond-above(m) {
            border-top: none;
        }
    }

    &__usage{
        display: flex;
        flex-direction: column;
        gap: rem(4px);
    }

    &__usageTitle{
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtButton);
    }

    &__usageText{
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtButton);
    }

    &__barcodeWrapper {
        align-self: center;
        margin-top: rem(66px);

        @include respond-above(m) {
            margin-top: rem(93px);
        }
    }

    &__barcode {
        transition: all 0.3s ease-in-out;
        opacity: 1;
    }

    &__usageConditions {
        display: flex;
        flex-direction: column;
        @extend %dmSansFontRegular;
        color: var(--color-gray);
        margin-top: rem(29px);

        @include respond-above(m) {
            margin-top: rem(32px);
        }
    }

    &__usageConditionsTitle {
        font-size: rem(16px);
        line-height: rem(24px);
        padding-bottom: rem(4.5px);
    }

    &__usageConditionsText {
        font-size: rem(12px);
        line-height: rem(16px);
    }

    &__usageConditionsDiscover{
        @extend %dmSansFontMedium;
        width: fit-content;
        font-size: rem(16px);
        line-height: rem(24px);
        text-decoration: underline;
        margin-top: rem(24px);
        color: var(--color-info);

        @include respond-above(m) {
            margin-top: rem(16px);
        }
    }

    &__mecTitle{
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtButton);
        padding-top: rem(16px);
        border-top: 1px solid;
        border-color: #e7e7e7;
        margin-top: rem(16px);

        @include respond-above(m) {
            border-top: none;
        }
    }

    &__mecText{
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtButton);
        margin-top: rem(16px);

        @include respond-above(m) {
            margin-top: rem(8px);
        }
    }

    &__image {
        max-height: rem(160px);
        margin-top: rem(24px);
        object-fit: cover;
    }

    &__bottomClose{
        @extend %button-primary3;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        min-width: rem(105px);
        margin: rem(24px);
        text-align: center;
        width: fit-content;
        align-self: flex-end;
        pointer-events: all !important;

        @include respond-below(m) {
            margin-top: rem(32px);
            width: -webkit-fill-available;
            margin: rem(24px) rem(8px);
            min-width: unset;
        }
    }
}