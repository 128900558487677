@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw8-insert-card {
    @include wizard(&);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }

        > a {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &__imgCards {
        width: 100%;
        max-width: rem(423px);
        margin: 0 auto;
        margin-top: rem(11px);
    }

    &__accordion {
        position: relative;
        padding: rem(24px);
        border: 1px solid alpha(--color-darkCharcoal, 0.3);
        border-radius: rem(12px);
        margin-top: rem(20px);
    }

    &__accordionBtn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
        transition: transform 0.3s ease-in-out;
        
    }

    &__accordionHeading {
        @extend %buttonReset;
        @extend %font-heading5;
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        position: relative;
        padding-right: rem(24px);

        & > [class^='ec-icon'] {
            font-size: rem(24px);
            line-height: 1em;
            vertical-align: middle;
            margin-right: rem(8px);
            display: none;

            @include respond-above(m) {
                display: inline-block;
            }
        }
    }

    &__accordionContent {
        overflow: hidden;
        transition: all 0.4s ease-in-out;
        max-height: 0;
        visibility: hidden;
    }

    &__accordion--show &__accordionBtn {
        transform: translateY(-50%) rotateX(180deg);
    }

    &__formSection {
        margin-top: rem(24px);
        align-self: stretch;

        &:first-child {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            display: flex;
            justify-content: space-between;
            &--single {
                max-width: 50%;
            }
        }
    }

    &__formItem {
        flex: 1 0 0;
    }

    &__formItem {
        @include respond-above(m) {
            margin-right: rem(8px);
        }
    }

    &__formItem + &__formItem {
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-top: 0;
            margin-left: rem(8px);
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__verify {
        @extend %buttonReset;
        @extend %button-primary3;
        text-align: center;
        margin-top: rem(24px);
        pointer-events: all !important;
    }

    &__separator {
        margin-top: rem(16px);
        border-top: 1px solid
            hsla(var(--color-borderTextfield-h), var(--color-borderTextfield-s), var(--color-borderTextfield-l), 0.1);
    }

    &__continueTop {
        margin-top: rem(31px);
        @include respond-above(m) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__continueBtn {
        @extend %buttonReset;
        @extend %button-primary3;
        text-align: center;
        
        @include respond-below(m) {
            margin-top: rem(24px);
            width: 100%;
        }
    }

    &__continueTitle {
        @extend %font-heading4;
        font-weight: bold;
        @include respond-above(m) {
            max-width: rem(370px);
        }
    }

    &__continueTxt {
        margin-top: rem(30px);
        @extend %txt;
        color: alpha(--color-black, 0.8);
    }

    &__supportLinkText {
        @extend %txt;
        margin-top: rem(16px);
        color: alpha(--color-black, 0.8);
    }
}
