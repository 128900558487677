@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt10-filter {

    &__content {
        position: relative;
        
        @include respond-above(l) {
            width: fit-content;
        }
    }

    /* button */
    &__filter {
        @extend %buttonReset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: rem(56px);
        border: 1px solid var(--color-border);
        border-radius: rem(16px);
        padding: 0 rem(16px) 0 rem(16px);

        @include respond-above(l) {
            width: rem(225px);
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    &__filter:focus-visible {
        @extend %button-focused;
    }

    &__labels {
        display: flex;
        flex-flow: column;
        text-align: left;
    }

    &__filterIcon {
        font-size: rem(24px);
        transition: transform 0.3s ease-in-out;
        
        &--rotate {
            transform: rotateX(180deg);
        }
    }

    &__filterByLabel {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(13px);
        padding-bottom: rem(4px);
    }

    &__filterDropdown {
        max-height: 0;
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border: 1px solid var(--color-border);
        border-radius: rem(16px);
        background-color: var(--color-bg);
        margin-top: rem(8px);
        transition: max-height .4s ease-in-out;
        overflow: hidden;

        &--open {
            max-height: 500px;
            visibility: visible;
            pointer-events: all;
            z-index: setZ(dropdown);
        }
    }

    &__filterList {
        padding: rem(16px) rem(14px) rem(16px) rem(16px);
    }

    &__filterItem {
        display: flex;
        align-items: center;
        padding: rem(8px) 0 rem(8px) 0;

        &:first-child {
            padding-top: unset;
        }
        &:last-child {
            padding-bottom: unset;
        }
    }

    &__filterItemLabel {
        position: relative;
        cursor: pointer;
        padding-left: rem(16px);
    }
    &__filterItemLabel:focus-visible {
        @extend %button-focused;
    }

    &__filterRadio {
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }

    &__filterRadio--default {
        display: none;
    }

    /* custom input checkbox */
    &__filterLabel {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(14px);
        padding-left: rem(12px);

        &::before, &::after {
            content: '';
            position: absolute;
            vertical-align: middle;
            border: 1px solid;
            border-radius: 50%;
        }
        &::before {
            display: inline-block;
            width: rem(20px);
            height: rem(20px);
            top: rem(1px);
            left: 0;
            border-color: alpha(--color-border, 0.4);
        }
        &::after {
            display: none;
            width: rem(12px);
            height: rem(12px);
            top: rem(5px);
            left: rem(4px);
            border-color: var(--color-primary3);
            background-color: var(--color-primary3);
        }
    }
    &__filterRadio:checked + &__filterLabel::after {
        display: block;
    }
    &__filterRadio:checked + &__filterLabel::before {
        border: 1px solid alpha(--color-primary3, 0.8);
    }
}