@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp28-reorder-error {
   @include actionPopup();

   &__oc,
   &__hd {
     display: none;
   }
}