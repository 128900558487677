@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt39-preauthorization-popup {
    overflow: hidden;
    /* 536px used also in the script for setting the height dynamically */
    height: rem(536px);
    max-width: rem(624px);

    &__content {
        position: relative;
        overflow: hidden;
        background-color: var(--color-bg);
        border-radius: rem(12px);
        height: 100%;
    }
    &__content > div:first-of-type {
        position: relative;
        /* considering 64px for the title and 24px for the bottom margin of the popup */
        height: calc(100% - 88px);
        padding-right: rem(64px);
        padding-left: rem(24px);
        margin-bottom: rem(27px);

        @include respond-above(l) {
            margin-bottom: rem(36px);
        }

        @include adjustPerfectScrollbar();
        .ps__rail-y {
            right: 30px;
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-txtAcc);
        padding: rem(24px) rem(64px) rem(8px) rem(24px);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        top: rem(24px);
        right: rem(26px);
        cursor: pointer;
    }
    &__close:focus-visible {
        @extend %button-focused;
    }
}