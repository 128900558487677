@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm14-menu-mobile {
    $root: &;
    @include modal(&, false);

    & &__wrapper {
        margin-top: rem(48px);
        padding-top: 0;
        padding-bottom: rem(16px);
    }

    &__title {
        padding-bottom: rem(24px);
        border-bottom: 1px solid lightness(--color-black, 90%);
    }

    &__userDisplayName {
        @extend %dmSansFontRegular;
        font-size: rem(24px);
        line-height: rem(32px);

        b {
            @extend %dmSansFontBold;
        }
    }

    &__navSectionBtn,
    &__exitLink {
        @extend %buttonReset;
    }

    &__link,
    &__navSectionBtn,
    &__exitLink {
        @extend %dmSansFontMedium;
        display: block;
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        border-bottom: 1px solid lightness(--color-black, 90%);
        padding-left: rem(8px);
        font-size: rem(14px);
        line-height: rem(24px);
        width: 100%;
    }

    &__navSectionBtn {
        display: flex;
        align-items: center;
    }

    &__navSectionBtnText {
        flex: 1 0 auto;
    }

    &__exitLink {
        border-bottom: 0;
    }

    &__navSectionBtnOpen {
        font-size: rem(24px);
        line-height: 1em;
        transition: transform 0.3s ease-out;
    }

    &__navSectionBtnIcon,
    &__linkIcon {
        margin-right: rem(8px);
    }

    &__navSectionContent {
        background: alpha(--color-menuGray, 0.05);
        transition: max-height 0.3s ease-in;
        max-height: 0;
        overflow: hidden;

        @include respond-below(m) {
            margin-left: -1 * rem(16px);
            margin-right: -1 * rem(16px);
            padding-left: rem(16px);
            padding-right: rem(16px);
        }

        #{$root}__link {
            padding-left: rem(32px);
            padding-top: rem(16px);
            padding-bottom: rem(16px);
            line-height: 1em;
            border-bottom: 0;
        }

        #{$root}__link:first-child {
            padding-top: rem(24px);
        }

        #{$root}__link:last-child {
            padding-bottom: rem(24px);
        }
    }

    &__navSection.open {
        #{$root}__navSectionBtn {
            border-bottom: 1px solid transparent;
        }
        #{$root}__navSectionContent {
            max-height: 400px;
        }
        #{$root}__navSectionBtnOpen {
            transform: rotateZ(180deg);
        }
    }

    /* align conad card icon */
    &__linkIcon.ec-icon-conad-card {
        position: relative;
        bottom: -2px;
    }

    &__link.active,
    &__link.active > span:first-of-type {
        color: var(--color-primary3);
        font-weight: bold;
    }

    &__navSectionBtn:focus-visible,
    &__exitLink:focus-visible {
        @extend %button-focused;
    }
}
