@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm15-cc-request-details {
    $color-border-bg: #d9d9d9;
    $color-border-ctas: #a8a8a8;
    $color-info-separator: #e0ded7;

    &__topBar {
        padding: rem(9px) rem(16px) rem(12px);
        border-bottom: 1px solid $color-border-bg;

        & > div:first-child {
            width: rem(118px);
            height: rem(6px);
            background-color: $color-border-bg;
            border-radius: rem(3.5px);
            margin: 0 auto;
        }
    }

    &__close {
        @extend %buttonReset;
        display: block;
        width: rem(24px);
        height: rem(24px);
        font-size: rem(16px);
        font-weight: bold;
        background-color: $color-border-bg;
        border-radius: 50%;
        margin-left: auto;
        margin-top: rem(-3px);

        &::before {
            display: block;
            width: fit-content;
            margin: 0 auto;
        }
    }

    &__wrapper {
        position: relative;
        height: 100vmax;

        @include respond-below(m) {
            background: #ffffff;
        }

        @include adjustPerfectScrollbar();
    }

    &__contentWrapper {
        padding-left: rem(16px);
        padding-right: rem(16px);
        height: 100%;
    }

    &__heading {
        text-align: center;
    }

    &__reqTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(9px);
        margin-bottom: rem(16px);
    }

    &__site {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(9px);

        /* icon */
        & > span:first-child {
            position: relative;
            bottom: -2px;
            margin-right: rem(6px);
        }
    }

    &__created {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);

        /* date */
        & > span:last-child {
            @extend %dmSansFontBold;
        }
    }

    &__status {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(23px);

        /* badge */
        @include ccRequestBadges();
    }

    &__info {
        background-color: var(--color-secondary1);
        padding-top: rem(24px);
        margin-left: rem(-16px);
        margin-right: rem(-16px);
        padding-bottom: rem(33px);
        text-align: center;
    }

    &__infoSection {
        /* titles */
        & > h4 {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-txtAcc);
            margin-bottom: rem(4px);
        }

        & > p {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-txtAcc);
        }

        & > p > a {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__infoSeparator {
        width: rem(55px);
        height: rem(1px);
        background-color: $color-info-separator;
        margin: rem(15px) auto rem(16px);
    }

    &__description {
        margin-top: rem(32px);
    }

    &__descriptionTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        text-align: center;
    }

    &__descriptionText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
        margin-left: rem(-16px);
        margin-right: rem(-16px);
        padding: rem(16px);
        background-color: var(--color-secondary1);
    }

    &__documents {
        margin-top: rem(32px);
        text-align: center;

        & > ul > li {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(24px);
            color: var(--color-txtAcc);
            width: fit-content;
            margin-left: auto;
            margin-right: auto;

            & > a {
                display: flex;
                align-items: center;
            }
            & > a > span {
                text-decoration: underline;
            }

            /* icons */
            & > a > span:first-of-type {
                font-size: rem(16px);
                margin-right: rem(8px);
                text-decoration: none;
            }
            & > a > span:last-of-type {
                font-size: rem(16px);
                margin-left: rem(8px);
                text-decoration: none;
                transform: rotateZ(320deg);
            }
        }
        & > ul > li:not(:first-child) {
            margin-top: rem(12px);
        }
    }

    &__documentsTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);
    }

    &__linkedOrder {
        margin-top: rem(32px);
        text-align: center;

        /* order link */
        & > a {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-txtAcc);
            white-space: nowrap;
            width: fit-content;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
        & > a > span {
            text-decoration: underline;
        }

        /* chevron icon */
        & > a > span:last-of-type {
            font-size: rem(16px);
            text-decoration: none;
        }
    }

    &__linkedOrderTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);
    }

    &__noDocuments,
    &__noLinkedOrder {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(24px);
        color: var(--color-txtTab);
    }

    &__ctas {
        padding-top: rem(23px);
        border-top: 1px solid $color-border-ctas;
        margin-top: rem(40px);
    }

    &__closeRequest {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        background-color: var(--color-white);
        padding: rem(18px) rem(30px);
        border: 2px solid var(--color-primary3);
        border-radius: rem(12px);
        width: 100%;
        text-align: center;

        @include respond-below(m) {
            margin-bottom: rem(170px);
        }
    }
}
