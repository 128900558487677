@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt27-cards {

    &__heading {
        position: relative;
        margin-bottom: 4px;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(m) {
            font-size: rem(16px);
        }
    }

    &__titleDecoration {
        position: absolute;
        top: calc(100% + 8px);
        width: 32px;
        height: 4px;
        background-color: var(--color-primary2);
        border-radius: 4px;

        @include respond-above(m) {
            top: calc(100% + 5px);
        }
    }

    &__carouselContainer {
        overflow: hidden;
        margin-top: rem(25px);
    }

    &__carousel {
        width: 100%;
        padding: rem(10px) 0;
    }
    
    &__track {
        display: flex;
        flex-flow: row wrap;
        width: 100%;

        @include respond-above(m) {
            flex-flow: row nowrap;
        }
    }

    /* cards */
    &__card {
        @include respond-above(m) {
            margin-bottom: rem(20px);
    
            &:not(:first-child) {
                margin-left: rem(16px);
            }
        }
    
        @include respond-below(m) {
            margin-bottom: rem(16px);
            margin-left: 1px;
            margin-right: 1px;
        }
    }
}