@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw17-migration-step1 {
    @include wizard(&);

    &__stepsIcons {
        @include respond-below(l) {
            position: static !important;
            align-self: center;
            margin-bottom: rem(32px);
        }
    }

    &__error {
        margin-bottom: rem(16px);
        overflow: hidden;

        &::before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);
        margin-bottom: rem(56px);

        @include respond-above(l) {
            margin-top: rem(24px);
            margin-bottom: rem(48px);
        }
    }

    &__formSection {
        margin-top: rem(24px);
    }

    &__formItem:not(:first-child) {
        margin-top: rem(24px);
    }

    &__formSection &__formItem:not(:first-child) {
        @include respond-above(l) {
            margin-top: unset;
            margin-left: rem(16px);
        }
    }

    &__error + &__formItem {
        margin-top: 0;
    }

    &__nameSurname {
        display: flex;
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row;
        }
    }

    &__birthdate {
        @include respond-above(l) {
            max-width: calc(50% - 8px);
        }
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        margin-top: rem(56px);

        @include respond-above(l) {
            flex-flow: row-reverse;
            margin-top: rem(40px);
        }
    }

    &__verify {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        text-align: center;
        cursor: pointer;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }

    &__skip {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        display: inline-block;
        margin: rem(24px) 0;
        text-decoration: underline;
        text-align: center;
        cursor: pointer;

        @include respond-above(l) {
            margin: rem(16px) 0;
            margin-right: rem(24px);
        }
    }
}