//general
$text-base-size: 16px;

//breakpoints
$breakpoints: (
    m: 768px,
    l: 1024px,
    xl: 1440px,
);

//container
$container-wmax: 1424px;
$container-wpad: 16px;
$container-wpad-desk: 40px;

//header
$nav-height: 56px;
$nav-height-desk: 88px;

// z-index
$z-layers: (
    bottomless-pit: -9999,
    negative: -1,
    default: 1,
    backToTop: 3999,
    dropdown: 3000,
    overlay: 4000,
    header: 5000,
    modal: 5002,
    popup: 5005,
    top: 6000,
) !default;

$resourcesFolder: '~/src/main/webpack/resources';

$ec-icon-chevron-left: '\e932';