@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mc9-preview-coupon {
    display: flex;
    flex-flow: column;
    padding-bottom: rem(48px);

    @include respond-below(l) {
        margin-top: rem(24px);
    }

    &__closeContainer {
        position: absolute;
        top: rem(8px);
        left: rem(8px);
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        border-radius: rem(8px);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: rem(8px);
        cursor: pointer;
    }

    &__content {
        padding-bottom: rem(41px);

        @include respond-above(l) {
            display: flex;
            flex-flow: row wrap;
            align-items: stretch;
            margin-right: rem(-16px);
        }

        &--hidden {
            padding-bottom: 0;
        }
    }
}
