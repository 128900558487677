@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mt45-refresh-prize-coupon {
    $root: &;

    &__triangle {
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    }

    &__wrapper {
        flex-flow: column;
        border-radius: rem(10px);
        border-width: rem(3px);
        border-color: #CCC7B7;
    }

    &__main {
        flex-flow: column;

        &--travel {
            padding: rem(16px) rem(10px) rem(10px) rem(10px);

            @include respond-above(l) {
                padding: rem(16px) rem(8px) rem(10px) rem(8px);
            }
        }

        &--shopping {
            padding: rem(4px) rem(4px) rem(10px) rem(4px);

            @include respond-above(l) {
                padding: rem(16px) rem(8px) rem(10px) rem(8px);
            }
        }
    }

    &__imageShopping {
        max-width: 75%;
        width: rem(205px);
        height: rem(100px);
        margin-left: auto;
        margin-top: rem(6px);
        margin-bottom: rem(6px);
        margin-right: auto;
        object-fit: contain;
    }

    &__imageTravel {
        width: 100%;
        height: rem(112px);
        border-radius: rem(10px) rem(10px) rem(0px) rem(0px);
        object-fit: cover;
    }

    &[data-selected] {
        #{$root}__wrapper {
            border-color: var(--color-orange);
        }

        // #{$root}__bubbleBorderBottomColor {
        //     border-bottom-color: var(--color-primary3);
        // }

        // #{$root}__bubbleBorderTopColor {
        //     border-top-color: var(--color-primary3);
        // }

        // #{$root}__bubbleBorderWidthAndColorLeft {
        //     border-left-color: var(--color-primary3);
        //     border-width: rem(3px);
        // }
    } 

}
