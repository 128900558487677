@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp26-refresh-thank-you {
    @include actionPopup();

    &__content {
        padding: rem(24px) rem(8px) rem(8px) rem(24px);
    }

    &__title {
        padding: 0;
    }

    &__additionalText {
        &--hidden {
            display: none;
        }
    }

    p {
        @extend %dmSansFontRegular;
        letter-spacing: normal;
        a {
            @extend %dmSansFontRegular;
            cursor: pointer;
            color: var(--color-info);
            font-weight: 500;
            text-decoration: underline;
        }

        .color-orange {
          color: var(--color-orange);
        }
    }

    &__ctas{
        justify-content: space-between;
    }

    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(20px);

        @include respond-above(m) {
            flex-direction: row;
            align-items: flex-start;
            gap: rem(30px);
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: rem(20px);
        font-size: rem(16px);
        line-height: rem(24px);
    }

    &__discoverMore {
        @extend %dmSansFontBold;
    }

    &__privacyText {
        max-width: unset;
        display: flex;
        flex-direction: column;
        gap: rem(10px);
        font-size: rem(14px);
        line-height: rem(20px);

        @include respond-above(m) {
            max-width: rem(380px);
        }
    }

    &__ctaAction {
        height: rem(56px);
    }
}