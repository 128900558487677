@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mc8-cards-icon-text {
    @extend %container;
    margin-top: rem(56px);
    margin-bottom: rem(26px);

    @include respond-above(m) {
        margin-bottom: rem(62px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(36px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(38px);

        @include respond-above(m) {
            margin-bottom: rem(56px);
        }

        &::after {
            display: block;
            content: ' ';
            width: 32px;
            height: 4px;
            border-radius: 4px;
            background-color: var(--color-primary2);
            margin-top: rem(10px);
        }
    }

    &__cards {
        display: flex;
        gap: rem(50px);
        flex-flow: column;

        @include respond-above(m) {
            flex-flow: row;
            gap: rem(20px);
        }
    }

    &__card {
        flex: 1 0 0;
        display: flex;
        gap: rem(16px);
    }

    &__cardIcon {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        font-size: rem(48px);
        color: var(--color-primary3);
        padding: rem(20px);
        border-radius: 50%;
        background-color: alpha(--color-neutral, 10%);
        box-shadow: 0 1px 1px 0 alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }

    &__cardContent {
        flex-grow: 1;
    }

    &__cardTitle {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        padding-bottom: rem(8px);
    }

    &__cardText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(20px);
        color: var(--color-gray);
    }

    &__links {
        display: flex;
        flex-flow: column;
        gap: rem(40px);

        @include respond-above(m) {
            flex-flow: row-reverse;
            gap: rem(24px);
            margin-top: rem(88px);
        }
    }
    &__cards ~ &__links {
        margin-top: rem(73px);

        @include respond-above(m) {
            margin-top: rem(88px);
        }
    }

    &__primaryLink {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-white);
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        padding-top: rem(20px);
        padding-bottom: rem(20px);
        padding-left: rem(32px);
        padding-right: rem(32px);
        text-align: center;
        width: 100%;

        @include respond-above(m) {
            width: fit-content;
        }
    }

    &__secondaryLink {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-darkCharcoal);
        background-color: var(--color-white);
        border-radius: rem(12px);
        border: 2px solid var(--color-primary3);
        padding-top: rem(18px);
        padding-bottom: rem(18px);
        padding-left: rem(32px);
        padding-right: rem(32px);
        text-align: center;
        width: 100%;

        @include respond-above(m) {
            width: fit-content;
        }
    }
}