@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mf17-my-stores {
    $root: &;
    padding-bottom: rem(104px);

    &__titlePrefStore{
        @extend %dmSansFontBold;
        margin-bottom: rem(16px);
        font-size: rem(24px);
        line-height: rem(40px);
    }

    &__headerOtherStores{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid lightness(--color-border, 90%);

        @include respond-below(m) {
            border-style: none;
        }
    }

    &__counter{
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);

    }

    &__titleOtherStores{
        @extend %dmSansFontBold;
        padding-top: rem(24px);
        margin-bottom: rem(16px);
        font-size: rem(20px);
        line-height: rem(40px);

        @include respond-below(m) {
            padding-top: rem(0px);
        }
    }

    &__containerOtherStores{
        display: flex;
        flex-direction: column;
        gap: rem(16px);
        margin-bottom: rem(0px);

        [class~='mt44-store-card']:last-of-type  {
            margin-bottom: rem(24px);

            @include respond-below(m) {
                margin-bottom: rem(31.9px);
            }
        }
    }

    &__subtitleOtherStores{
        @extend %dmSansFontRegular;
        margin-bottom: rem(24px);
        font-size: rem(16px);
        line-height: rem(20px);
        color: var(--color-txtTab);

        &--hide{
            display: none
        }
    }

    &__imageContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__emptyImage{
        width: rem(305px);
        height: rem(196px);
        margin-top: rem(76px);

        @include respond-below(m){
            min-width: rem(239px);
            height: rem(153px);
            margin-top: rem(72px);
        }

        &--hide{
            display: none
        }
    }

    &__addStores{
        @extend %button-primary3;
        font-size: rem(16px);
        line-height: rem(16px);
        text-align: center;

        @include respond-below(m) {
            width: 100%;
        }

        &--hide{
            display: none;
        }

        
    }

    
}