@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf2-messages {
    display: flex;
    flex-flow: column;
    padding-bottom: rem(48px);

    @include respond-below(l) {
        margin-top: rem(31px);
    }

    &__topBar {
        padding-bottom: rem(14px);
        border-bottom: 1px solid alpha(--color-black, 0.1);

        @include respond-above(l) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__countMsg {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        padding-top: rem(14px);

        @include respond-above(l) {
            align-self: flex-end;
        }
    }

    &__content {
        padding-bottom: rem(24px);

        @include respond-above(l) {
            padding-bottom: rem(32px);
        }

        &--hidden {
            display: none;
        }
    }

    &__loadMore {
        @extend %buttonReset;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
        align-self: center;
        margin: rem(20px) 0;

        &--hidden {
            display: none;
        }
    }
    &__loadMore:focus-visible {
        @extend %button-focused;
    }

    &__noMessages {
        padding-top: rem(24px);

        &--hidden {
            display: none;
        }
    }
    &__noMsgTitle {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        padding-bottom: rem(8px);
    }
    &__noMsgText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
    }
}