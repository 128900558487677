@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt44-store-card {
    border-radius: 12px;
    border: solid 1px lightness(--color-border, 90%);
    padding: rem(16px) rem(16px) rem(14px);
    position: relative;

    --display-badge: none;
    --display-modify: none;
    --margin-heading: 0px;
    --padding-address: 32px;

    &--favorite {
        --display-badge: block;
        --display-modify: block;
        --margin-heading: 16px;
        --padding-address: 16px;
    }

    @include respond-below(m) {
        --padding-address: 16px;
    }

    &--fadeIn {
        @extend %fadeIn;
    }

    &--fadeOut {
        @extend %fadeOut;
    }

    &--hide {
        display: none;
    }

    &--favStoreInStores {
        margin-top: 0;
        margin-bottom: rem(24px);

        @include respond-below(m) {
            margin-bottom: rem(8px);
        }
    }

    &__storeHeading {
        display: var(--display-badge);
    }

    &__nameAndActions {
        margin-top: var(--margin-heading);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__actions {
        &--dsk {
            display: block;
        }

        &--mob {
            display: none;
        }

        @include respond-below(m) {
            &--dsk {
                display: none;
            }

            &--mob {
                display: block;
                margin-bottom: rem(16px);
            }
        }
    }

    &__storeFilters {
        width: 100%;
    }

    &__action {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
    }

    &__storeOpening {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__storeBadge {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-primary3);
        background-color: rgb(254, 240, 229);
        padding: rem(5px) rem(16px);
        border-radius: 12px;
    }

    &__modifyStore,
    &__modifyStoreLink {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: rem(24px);

        display: var(--display-modify);
    }
    &__modifyStore:focus-visible,
    &__modifyStoreLink:focus-visible {
        @extend %button-focused;
    }

    &__storeInfoAndLink {
        display: flex;
        flex-flow: column;

        @include respond-above(m) {
            flex-flow: row;
            justify-content: space-between;
        }
    }

    &__storeInfo {
        @include respond-above(m) {
            flex-grow: 1;
            margin-right: rem(31.5px);
        }
    }

    &__storeLink {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        padding: rem(20px) rem(32px);
        border: 2px solid var(--color-primary3);
        border-radius: 12px;
        text-align: center;
        margin-top: rem(31.5px);

        @include respond-above(m) {
            margin-top: unset;
            align-self: flex-end;
            flex-grow: 0;
            margin-left: rem(31.5px);
        }
    }

    &__storeName {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        display: block;
        width: fit-content;
        margin-bottom: rem(8px);
    }

    &__storeAddress,
    &__openingState,
    &__openingHours {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }

    &__storeAddress {
        padding-bottom: var(--padding-address);
    }

    &__storeOpening {
        padding-top: rem(9px);
        border-top: 1px solid lightness(--color-border, 90%);
    }

    &__openingState {
        display: flex;
        align-items: center;
    }

    &__openingStateIcon {
        width: 8px;
        height: 8px;
        border-radius: 12px;
        margin-left: rem(8px);
    }

    &__openingState--open > &__openingStateIcon {
        background-color: var(--color-positiveInfo);
    }
    &__openingState--closed > &__openingStateIcon {
        background-color: var(--color-negativeInfo);
    }
}
