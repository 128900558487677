@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm7-delivery-address {
    @include modal(&, true);

    &__error {
        margin-top: rem(10px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__titleAdd,
    &__titleModify {
        display: none;

        &--show {
            display: inline;
        }
    }

    &__wrapper .ps__rail-y {
        margin-top: rem(16px) !important;
    }

    &__formSection {

        .mt2-addressfield,
        .mt38-address-google,
        .mt8-textfield, 
        .mt16-select,
        .mt18-textarea,
        .mt17-checkbox {
            margin-top: rem(24px);
        }

        .mt19-radio {
            margin-top: rem(24px);
            margin-bottom: rem(8px);
        }
    }
    &__formSection &__scala {
        margin-top: rem(16px);
    }

    &__favoriteCheckbox {
        margin-top: rem(32px) !important;
    }

    /* on mobile, keep one form input per row */
    @include respond-above(l) {
        &__rowNomeCognome,
        &__rowTelefonoPiano {
            display: flex;
            justify-content: space-between;
        }

        &__rowNomeCognome > &__nome {
            flex: 1 0 0;
            margin-right: rem(6px);
        }
        &__rowNomeCognome > &__cognome {
            flex: 1 0 0;
            margin-left: rem(6px);
        }

        &__rowTelefonoPiano > &__telefono {
            flex: 369 0 0;
            margin-right: rem(6px);
        }
        &__rowTelefonoPiano > &__piano {
            flex: 195 0 0;
            margin-left: rem(6px);
        }

        &__rowAscensorePortineria {
            display: flex;
        }
        &__rowAscensorePortineria > &__ascensore {
            padding-right: rem(22.2px);
        }
        &__rowAscensorePortineria > &__portineria {
            padding-left: rem(18.2px);
            border-left: 1px solid alpha(--color-borderTextfield, 0.3);
        }
    }

    &__alert {
        display: flex;
        overflow: hidden;
        visibility: hidden;
        max-height: 0;
        background-color: var(--color-secondary4);
        border-radius: 12px;
        padding-left: rem(16px);
        padding-right: rem(16px);
        transition: all .2s ease-in;
    
        &--show {
            visibility: visible;
            max-height: 100px;
            padding-top: rem(16px);
            padding-bottom: rem(16px);
            margin-top: rem(16px);
        }
    }

    &__alertIcon {
        align-self: center;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        object-fit: cover;
        background-image: url('#{$resourcesFolder}/myconad/icons/alert-yellow.svg');
        margin-right: rem(16px);
    }

    &__alertText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: alpha(--color-txt, 60%);
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        @extend %button-primary3;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        min-width: rem(105px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        text-align: center;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);
        order: 2;

        @include respond-above(m) {
            order: 3;
            align-self: flex-end;
        }

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }
    &__submit:focus-visible {
        @extend %button-focused;
    }

    &__remove {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        align-self: center;
        margin-right: rem(24px);
        text-decoration: underline;
        cursor: pointer;

        &--hidden {
            display: none;
        }
    }
    &__remove:focus-visible {
        @extend %button-focused;
    }
}