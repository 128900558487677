@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt23-datepicker {
    position: relative;
    width: 100%;
    display: block;

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(24px);
        display: block;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        border-radius: rem(16px);
        border: 1px solid alpha(--color-borderTextfield, 0.3);

        /* calendar button always present */
        padding-right: rem(60px);
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
        line-height: rem(39px);
    }

    &__input:focus ~ &__label,
    &__input:not(:placeholder-shown) ~ &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: alpha(--color-txt, 0.8);
        background-color: transparent;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: alpha(--color-txt, 0.8);

        /* calendar button always present */
        margin-right: rem(45px);
    }

    &__showCalendar {
        @extend %buttonReset;
        position: absolute;
        right: rem(3px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
        color: lightness(--color-txt, 30);
        border-radius: 14px;
        width: rem(50px);
        height: rem(50px);
        text-align: center;
    }
    &__showCalendar:focus-visible {
        @extend %button-focused;
    }

    &--errorState &__input {
        border: 1px solid var(--color-negativeInfo);
    }

    &--validState.mt23-datepicker--validEnabled &__input {
        border: 1px solid var(--color-positiveInfo);
    }

    &--warningState &__input {
        border: 1px solid var(--color-warningInfo);
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    /* fix autocomplete hover on webkit browsers */
    input:-webkit-autofill:hover ~ &__label {
        color: alpha(--color-txt, 0.8);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
