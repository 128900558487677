@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ms7-menu {
    $root: &;
    flex-direction: column;
    display: none;
    width: rem(224px);
    margin-right: rem(16px);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &--initialized {
        opacity: 1;
    }

    @include respond-above(l) {
        display: flex;
        flex: 0 0 auto;
        margin-bottom: rem(40px);
    }

    &__navDivider {
        width: rem(68px);
        margin-top: rem(24px);
        color: var(--color-borderDivider);
        border: 1px solid;
    }

    &__link {
        position: relative;
        margin-top: rem(35px);
        padding-left: rem(32px);
        padding-right: rem(16px);
        @extend %font-label2;
        font-weight: 500;
        line-height: rem(18px);
        color: var(--color-txtAcc);
        width: fit-content;

        &--inside {
            margin-top: rem(16px);
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__exitLink {
        @extend %buttonReset;
        position: relative;
        padding-left: rem(32px);
        padding-right: rem(16px);
        margin-top: rem(24px);
        @extend %font-label2;
        font-weight: 500;
        line-height: rem(18px);
        color: var(--color-txtAcc);
        width: fit-content;
        cursor: pointer;
    }
    &__exitLink:focus-visible {
        @extend %button-focused;
    }

    &__navSection {
        margin-top: rem(32px);
    }

    &__navSectionBtn {
        position: relative;
        @extend %buttonReset;
        padding-left: rem(32px);
        padding-right: rem(32px); 
        margin-top: rem(32px);
        @extend %font-label2;
        font-weight: 500;
        line-height: rem(16px);
        display: block;
        width: 100%;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: rem(32px);
        }
    }

    &__navSectionBtn:focus-visible {
        @extend %button-focused;
    }

    &__navSection.open {
        #{$root}__navSectionContent {
            max-height: rem(150px);
            visibility: visible;
        }

        #{$root}__navSectionBtnOpen {
            transform: translateY(-50%) rotateX(180deg);
        }
    }

    &__navSectionBtnOpen {
        position: absolute;
        right: 0;
        font-size: rem(16px);
        line-height: 1em;
        top: 50%;
        transform: translateY(-50%);
        transition: transform 0.3s ease-in-out;
    }

    &__navSectionContent {
        display: flex;
        flex-direction: column;
        margin-left: rem(32px);
        max-height: 0;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;
        visibility: hidden;
    }

    &__navSectionBtnIcon,
    &__linkIcon {
        position: absolute;
        left: 0;
        font-size: rem(16px);
        line-height: 1em;
    }

    /* align conad card icon */
    &__link.active,
    &__link.active > span:first-of-type {
        color: var(--color-primary3);
        font-weight: bold;
    }
}
