@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf15-favorites-flyer {
    display: flex;
    flex-flow: column;

    @include respond-below(l) {
        margin-top: rem(24px);
    }

    --color-heart: var(--color-primary3);

    &__subTitle{
        @extend %dmSansFontRegular;
        font-size: rem(15px);
        line-height: rem(24px);
        margin-bottom: rem(8px);
    }

    &__topBar {
        display: flex;
        padding-bottom: rem(8px);
        border-bottom: 1px solid alpha(--color-black, 0.1);
        margin-bottom: rem(24px);
        gap: rem(16px);
        flex-direction: column;

        @include respond-above(l) {
            display: flex;
            flex-direction: row;
            padding-bottom: rem(24px);
            gap: rem(24px);
            justify-content: space-between;
        }
    }

    &__countContent {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        margin-bottom: rem(16px);
        text-align: right;
    }

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 49%);
        grid-auto-flow: row dense;
        gap: rem(14px) rem(14px);
        margin-bottom: rem(16px);

        @include respond-between(m, xl) {
            grid-template-columns: repeat(3, 32%);
            grid-auto-flow: row dense;
            gap: rem(16px);
        }
        
        @include respond-above(xl) {
            grid-template-columns: repeat(4, 24%);
            gap: rem(16px);
        }
    }

    &__storeContainer{
        width: 100%;
    }

    &__storeIcon{
        width: rem(16px);
        height: rem(16px);
        
    }

    &__store{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: rem(8px);
    }

    &__storeName{
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
    }

    &__storeAddress{
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
    }

    &__storeFilters {

        & > * > button > *{
           flex: 1 1 1;
        }
    }

    &__storeFilters, &__filter{
        width: 100%;
        
        & > div{
            width: 100%;
        }
        
        & > div > button{
            width: 100%;
            display: grid !important;
            grid-template-columns: 90% 10% !important;
        }

        & > div > button > div > span:nth-child(2) {
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
            overflow: hidden;
        }

        & span.ec-icon-chevron-down {
            place-self: center stretch;
            justify-self: end;
        }
    }

    &__filter{
        width: rem(304px);
    }

    &__loadMore {
        @extend %buttonReset;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(124px);
        height: rem(40px);
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        cursor: pointer;
        text-decoration: underline;
        margin-top: rem(8px);
        margin-bottom: rem(64px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(40px);


        @include respond-below(l){
            margin-top: rem(24px);
        }

        &--loader{
            background-image: url('#{$resourcesFolder}/myconad/icons/loader.gif');
            text-indent: -9999px;
        }

        &--hidden {
            display: none;
        }
    }
    &__loadMore:focus-visible {
        @extend %button-focused;
    }

    &__noContent {
        padding-top: rem(24px);

        &--hidden {
            display: none;
        }
    }

    &__noContentTitle {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        padding-bottom: rem(8px);
    }

    &__noContentText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
    }

    /* cards */
    
}

