@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use '../../../../../../style/modules/themes' as *;
@use 'sass:math';

:root,
html {
    @include default-theme;
    @extend %dmSansFontRegular;
    font-size: $text-base-size;
    font-weight: normal;
    font-style: normal;
    letter-spacing: rem(0.2px);
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    background-color: var(--color-bg);
    color: var(--color-txtAcc);
    overflow-y: scroll;

    &.block-scroll {
        /* when modal active */
        touch-action: none;
        -webkit-overflow-scrolling: none;
        overflow-y: hidden;
        /* Other browsers */
        overscroll-behavior: none;
    }
}

.ms1-page {
    &--minSpaced {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        & > main {
            flex: 1 0 auto;
        }
    }

    &--spacedTop {
        padding-top: 40px;
    }
}
