@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw9-privacy {
    @include wizard(&);
    display: flex;
    flex-direction: column;

    &__title {
        align-self: stretch;
    }

    &__subtitle {
        @extend %txt;
        @extend %font-body2;
        color: lightness(--color-black, 60%);
        margin-top: rem(15px);
        padding-bottom: rem(16px);
        align-self: stretch;
    }

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }

        & > a {
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &__box {
        margin-top: rem(24px);
        align-self: stretch;

        &:not(:last-of-type) {
            padding-bottom: rem(24px);
            border-bottom: 1px solid alpha(--color-black, 0.1);
        }
    }

    &__boxMarketingPartner {
        &--hidden {
            display: none;
        }
    }

    &__boxPretitle {
        @extend %font-label3;
        color: var(--color-blackAcc);
        font-weight: bold;
    }

    &__boxTitle {
        @extend %font-label;
        color: var(--color-blackAcc);
        font-weight: bold;
        margin-top: rem(8px);
    }

    &__boxText {
        margin-top: rem(16px);
        @extend %font-body2;
        color: var(--color-blackAcc);
    }

    &__boxNotSelected {
        @extend %font-body3;
        color: var(--color-semantic2);
        margin-top: rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__submit {
        @extend %buttonReset;
        @extend %button-primary3;
        margin-top: rem(56px);
        text-align: center;
        align-self: flex-end;

        &--disabled {
            pointer-events: none;
            color: var(--color-txtButton);
            background-color: var(--color-bgButton);
        }
    }

    &__withFidelityCard {
        display: none;
        align-items: flex-start;
        flex-flow: column;
        margin-top: rem(32px);

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__image {
        width: rem(227px);
        height: rem(136px);
        object-fit: contain;
        margin-bottom: rem(12px);
        object-position: left;

        @include respond-above(m) {
            margin-bottom: unset;
            margin-right: rem(12px);
            object-position: right;
        }
    }

    &__text {
        margin-top: rem(8px);
        @extend %font-heading5;
        @extend %txt;
    }

    &--fidelity &__subtitle,
    &--fidelity &__form {
        display: none;
    }
    &--fidelity &__withFidelityCard {
        display: flex;
    }
}
