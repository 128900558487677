//
//  CONTAINER
//––––––––––––––––––––––––––––––––––––––––––––––––––
@use './variables' as *;
@use './functions' as *;
@use './mixins' as *;

%container {
    width: 100%;
    padding: 0 rem($container-wpad);
    margin: 0 auto;

    @include respond-above(l) {
        padding: 0 rem($container-wpad-desk);
        max-width: calc(#{rem($container-wmax)} + #{rem($container-wpad-desk)} * 2);
    }
}

%leftMarginContainer {
    width: 100%;
    max-width: calc(
        (100% - (#{rem($container-wmax)} + #{rem($container-wpad)} * 2)) / 2 +
            (#{rem($container-wmax)} + #{rem($container-wpad)} * 2)
    );
    padding: 0 0 0 rem($container-wpad);
    margin: 0 0 0 auto;

    @include respond-above(l) {
        max-width: calc(
            (100% - (#{rem($container-wmax)} + #{rem($container-wpad-desk)} * 2)) / 2 +
                (#{rem($container-wmax)} + #{rem($container-wpad-desk)} * 2)
        );
        padding: 0 0 0 rem($container-wpad);
    }
}

//
//  FONTS
//––––––––––––––––––––––––––––––––––––––––––––––––––

//Roboto Slab
%robotoFont {
    font-family: 'Roboto Slab';
}

%robotoFontThin {
    @extend %robotoFont;
    font-weight: 100;
}

%robotoFontLight {
    @extend %robotoFont;
    font-weight: 300;
}

%robotoFontRegular {
    @extend %robotoFont;
    font-weight: 400;
}

%robotoFontMedium {
    @extend %robotoFont;
    font-weight: 500;
}

%robotoFontBold {
    @extend %robotoFont;
    font-weight: 700;
}

%robotoFontBlack {
    @extend %robotoFont;
    font-weight: 900;
}

//DM Sans
%dmSansFont {
    font-family: 'DM Sans';
}

%dmSansFontThin {
    @extend %dmSansFont;
    font-weight: 100;
}

%dmSansFontLight {
    @extend %dmSansFont;
    font-weight: 300; 
}

%dmSansFontRegular {
    @extend %dmSansFont;
    font-weight: 400;
}

%dmSansFontMedium {
    @extend %dmSansFont;
    font-weight: 500;
}

%dmSansFontBold {
    @extend %dmSansFont;
    font-weight: 700;
}

%dmSansFontBlack {
    @extend %dmSansFont;
    font-weight: 900;
}

//
//  FONTS SIZE
//––––––––––––––––––––––––––––––––––––––––––––––––––

%font-body {
    font-size: rem(16px);
    line-height: rem(24px);
}

%font-body2 {
    font-size: rem(14px);
    line-height: 1.43em;
}

%font-body3 {
    font-size: rem(12px);
    line-height: 1.33em;
}

%font-label {
    font-size: rem(16px);
    line-height: 1em;
}

%font-label2 {
    font-size: rem(14px);
    line-height: 1em;
}

%font-label3 {
    font-size: rem(12px);
    line-height: 1em;
}

%font-contacts {
    font-size: rem(11.4px);
    line-height: 1.5em;
}

%font-copyright {
    font-size: rem(10px);
    line-height: 1.42em;
}

%font-heading2 {
    font-size: rem(28px);
    line-height: 1.07em;

    @include respond-above(l) {
        font-size: rem(32px);
        line-height: 1.25;
    }
}

%font-heading3 {
    font-size: rem(20px);
    line-height: 1.2em;

    @include respond-above(m) {
        font-size: rem(24px);
        line-height: 1.33em;
    }
}

%font-heading4 {
    font-size: rem(20px);
    line-height: 1.4em;
}

%font-heading5 {
    font-size: rem(16px);
    line-height: 1.5em;
}

%font-dataLabel1 {
    font-size: rem(17px);
    line-height: 1em;
}

%font-button2 {
    font-size: rem(16px);
    line-height: 1.5em;
}

//
//  TEXT
//––––––––––––––––––––––––––––––––––––––––––––––––––

%txt {
    a {
        color: var(--color-info);
        font-weight: 500;
        text-decoration: underline;
    }
}

//
//  ICON
//––––––––––––––––––––––––––––––––––––––––––––––––––
%ecIcon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'conad-corporate-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//
//  CTA
//––––––––––––––––––––––––––––––––––––––––––––––––––

%buttonReset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    border-radius: 0;
    text-align: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;

    /* Blue outline removal (not very accessible) */
    &:focus {
        outline: none;
    }

    /* Remove excess padding and border in Firefox 4+ */
    &::-moz-focus-inner {
        border: 0;
        padding: 0;
    }

    /* Accessibility */
    &:focus-visible {
        outline: 1px solid var(--color-black);
        outline-offset: 1px;
    }
}

%button-empty {
    background-color: transparent;
    color: var(--color-txtAcc);
    font-size: rem(16px);
    @extend %buttonReset;
    @extend %dmSansFont;
    line-height: rem(20px);
    font-weight: bold;
    border-radius: rem(12px);
    padding: rem(18px) rem(16px);
    transition: all 0.2s;
    border: 1px solid lightness(--color-txt, 40%);

    &.invalid {
        background-color: var(--color-bgButton);
        color: var(--color-txtButton);
        pointer-events: none;
    }
}

%button-primary2 {
    background-color: var(--color-primary2);
    color: var(--color-txtAcc);
    font-size: rem(16px);
    @extend %buttonReset;
    @extend %dmSansFont;
    line-height: rem(20px);
    font-weight: 500;
    border-radius: rem(16px);
    padding: rem(18px) rem(16px);
    transition: all 0.2s;

    &.invalid {
        background-color: var(--color-bgButton);
        color: var(--txtButton);
        pointer-events: none;
    }
}

%button-primary3 {
    background-color: var(--color-primary3);
    color: var(--color-txtDark);
    font-size: rem(16px);
    @extend %buttonReset;
    @extend %dmSansFont;
    line-height: rem(20px);
    font-weight: bold;
    border-radius: rem(16px);
    padding: rem(18px) rem(16px);
    transition: all 0.2s;
    min-width: rem(119px);

    &.invalid {
        background-color: var(--color-bgButton);
        color: var(--color-txtButton);
        pointer-events: none;
    }
}

%button-secondary {
    background-color: transparent;
    color: var(--color-txtAcc);
    font-size: rem(16px);
    @extend %buttonReset;
    @extend %dmSansFont;
    line-height: rem(20px);
    font-weight: bold;
    border-radius: rem(12px);
    padding: rem(18px) rem(16px);
    transition: all 0.2s;
    border: 2px solid var(--color-primary3);
    min-width: rem(119px);
    text-align: center;

    &.invalid {
        background-color: var(--color-bgButton);
        color: var(--color-txtButton);
        pointer-events: none;
    }
}

%button-focused {
    outline: 1px solid var(--color-black);
    outline-offset: 1px;
}

//
//  BAGDE
//––––––––––––––––––––––––––––––––––––––––––––––––––

%badge {
    @extend %font-label3;
    background: alpha(--color-darkCharcoal, 0.05);
    border-radius: rem(12px);
    padding: 0 rem(16px);
    line-height: rem(24px);
    min-width: rem(24px);

    &.warn {

    }
}

// Common animations

%fadeIn {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

%fadeOut {
    -webkit-animation-duration: 0.6s;
    animation-duration: 0.6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOutAndDisappear;
    animation-name: fadeOutAndDisappear;
}
