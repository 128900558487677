@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ms6-preheader {
    width: 100%;
    background-color: var(--color-secondary1);
    height: rem(48px);
    position: relative;

    @include respond-above(l) {
        &__goto,
        &__gotoDropdown {
            display: none;
        }
    }

    &__container {
        @extend %container;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        padding: rem(16px) 0;
        z-index: setZ(header);
    }

    &__back {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        z-index: inherit;
        visibility: hidden;
        pointer-events: none;

        &--show {
            visibility: visible;
            pointer-events: all;
        }

        /* icon */
        & > span:first-of-type {
            position: relative;
            bottom: -2px;
            margin-right: 6px;
            font-size: rem(16px);
            font-weight: bold;
        }
    }

    &__origin {
        text-decoration: underline;
    }

    &__gotos {
        display: none;
        align-items: center;

        @include respond-above(l) {
            display: flex;
        }
    }
    &__gotoLinkDsk {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--colot-txt, 20%);
        display: flex;
        align-items: center;

        &:not(:first-child) {
            padding-left: rem(7.3px);
            border-left: 1px solid lightness(--color-txt, 90%);
            margin-left: rem(8px);
        }

        & > span {
            margin-left: rem(7.1px);
        }
    }

    &__goto {
        @extend %buttonReset;
        width: 16px;
        height: 16px;
        background-image: url('#{$resourcesFolder}/myconad/icons/square-menu.svg');
        margin-right: rem(12px);
    }
    &__goto:focus-visible {
        @extend %button-focused;
    }

    &__gotoDropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--color-secondary1);
        padding: 0 rem(16px);
        pointer-events: none;
        visibility: hidden;
        transition: max-height 0.3s ease-in-out;
        max-height: 0;
        overflow: hidden;
        z-index: setZ(dropdown);

        & > div:first-of-type {
            height: 1px;
            width: 100%;
            background-color: lightness(--color-txt, 90%);
        }
    }
    &__gotoDropdown--open {
        pointer-events: all;
        max-height: rem(121px);
    }

    &__gotoRow {
        display: flex;
        justify-content: space-between;
        z-index: inherit;

        &:nth-child(2) {
            margin-top: rem(24px);
        }
        &:nth-child(3) {
            margin-top: rem(8px);
            padding-bottom: rem(24px);
        }
    }

    &__gotoLink {
        flex: 1 0 0;
        display: flex;
        justify-content: space-between;
        background-color: var(--color-bg);
        padding: rem(8px);
        border-radius: 8px;
        box-shadow: 0 1px 1px 0 alpha(--color-boxShadow1, 0.15), 0 0 1px 0 alpha(--color-boxShadow1, 0.2);
        z-index: inherit;

        &:first-of-type {
            margin-right: rem(8px);
        }
        &:last-of-type {
            margin-left: rem(8px);
        }

        & > div:first-of-type {
            display: flex;
            z-index: inherit;

            & > span {
                @extend %dmSansFontMedium;
                font-size: rem(14px);
                line-height: rem(14px);
                color: var(--color-txtAcc);
                margin-left: rem(8px);
                z-index: inherit;
            }
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
        font-size: 16px;

        &--onlineshopping {
            background-image: url('#{$resourcesFolder}/myconad/icons/cart.svg');
        }
        &--saporie {
            background-image: url('#{$resourcesFolder}/myconad/icons/saporie.svg');
        }
        &--sustainability {
            background-image: url('#{$resourcesFolder}/myconad/icons/sostenibilita.svg');
        }
        &--conad {
            background-image: url('#{$resourcesFolder}/myconad/icons/conad.svg');
        }
        &--goto {
            background-image: url('#{$resourcesFolder}/myconad/icons/arrow-exit.svg');
        }
    }
}
