@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf4-addresses {
    $root: &;
    margin-bottom: rem(73px);

    @include respond-below(l) {
        margin-top: rem(31px);
    }

    &__alert {
        margin-top: rem(-13px);
        margin-bottom: rem(24px);
    }

    &__tabs {
        display: flex;
        justify-content: space-around;

        @include respond-above(l) {
            justify-content: unset;
        }
    }

    &__tabLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtTab);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(24px);
            padding-bottom: rem(8px);
        }
    }

    /* buttons */
    &__deliveryTab, 
    &__billingTab {
        @extend %buttonReset;
        flex: 1 0 0;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-txtTab);
        border-radius: rem(12px);
        padding: rem(16px) rem(12px) rem(15px) rem(12px);

        @include respond-above(l) {
            border: unset;
            border-radius: unset;
            flex: unset;
            padding: unset;
        }
    }
    &__deliveryTab:focus-visible, 
    &__billingTab:focus-visible {
        @extend %button-focused;
    }
    &__activeTab {
        border-color: var(--color-blackAcc);

        #{$root}__tabLabel {
            color: var(--color-blackAcc);
        }

        #{$root}__tabDecoration {
            display: flex;
            width: rem(60px);

            & > div {
                width: rem(32px);
            }
        }
    }
    &__deliveryTab {
        margin-right: rem(3px);

        @include respond-above(l) {
            margin-right: rem(16px);
        }
    }
    &__billingTab {
        margin-left: rem(3px);

        @include respond-above(l) {
            margin-left: rem(16px);
        }
    }

    &__tabDecoration {
        position: absolute;
        bottom: rem(-2.2px);
        width: rem(20px);
        height: rem(2.5px);
        background-color: var(--color-bg);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: none;
            height: rem(4px);
            bottom: rem(-3px);
        }

        & > div {
            width: rem(3px);
            height: inherit;
            background-color: var(--color-primary2);
            border-radius: rem(4px);
        }
    }

    &__deliveryContent, 
    &__billingContent {
        & > div:first-child {
            display: flex;
            flex-flow: column;
            width: 100%;
        }
    }

    /* buttons */
    &__btnAddDeliveryAddress, 
    &__btnAddBillingAddress {
        @extend %buttonReset;
        width: 100%;
        height: rem(99px);
        border: 2px dotted alpha(--color-txt, 0.3);
        border-radius: rem(12px);
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: rem(26px) 0 rem(6px) 0;
        transition: background-color .3s ease-in-out;

        span {
            font-size: rem(16px);
            line-height: rem(16px);
            font-weight: 600;
            margin-left: rem(4px);
        }
    }
    &__btnAddDeliveryAddress:focus-visible, 
    &__btnAddBillingAddress:focus-visible,
    &__btnAddDeliveryAddress:hover, 
    &__btnAddBillingAddress:hover {
        background-color: alpha(--color-bgDark, 0.05);
    }
    &__btnAddDeliveryAddress:focus-visible, 
    &__btnAddBillingAddress:focus-visible {
        @extend %button-focused;
    }
}