@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt16-select {
    position: relative;
    max-width: rem(395px);

    &__button {
        @extend %buttonReset;
        width: 100%;
        height: rem(56px);
        padding: rem(8px) rem(16px) rem(6px) rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        border-radius: rem(16px);
        border: 1px solid alpha(--color-borderTextfield, 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            display: flex;
            flex-flow: column-reverse;
            justify-content: space-between;
            overflow: hidden;
        }

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }
    &__button:focus-visible {
        @extend %button-focused;
    }

    &--errorState &__button {
        border: 1px solid var(--color-negativeInfo);
    }

    &--validState &__button {
        border-color: var(--color-positiveInfo);
    }

    &--warningState &__button {
        border-color: var(--color-warningInfo);
    }

    &__selectLabel {
        @extend %dmSansFontRegular;
        font-weight: 500;
        font-size: rem(13px);
        line-height: rem(13px);
        color: var(--color-txtAcc);
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: var(--color-txtAcc);
        padding-top: rem(8px);
        padding-bottom: rem(2px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--hidden {
            display: none;
        }
    }

    &__label--hidden + &__selectLabel {
        font-size: rem(17px);
        line-height: rem(20px);
    }

    &__icon {
        font-size: rem(24px);
        transition: transform 0.1s ease-in-out;
    }

    &__content {
        position: absolute;
        border-radius: rem(16px);
        box-shadow: 0 8px 16px -4px alpha(--color-boxShadow, 0.15), 0 0 1px 0 alpha(--color-bg, 0.2);
        background-color: var(--color-bgDropdown);
        overflow-y: hidden;
        visibility: hidden;
        max-height: 0;
        pointer-events: none;
        left: 0;
        width: 100%;
        z-index: setZ(dropdown);
        margin-top: rem(5px);
        transition: max-height 0.4s ease-out;

        @include adjustPerfectScrollbar();
        .ps__rail-y {
            margin-top: rem(6px) !important;
            margin-bottom: rem(6px);
        }
    }

    &__item {
        position: relative;
        height: rem(56px);
        @extend %font-dataLabel1;
        color: var(--color-txtDark);
        padding: rem(16px);
        padding-right: rem(56px);
        display: flex;
        align-items: center;
        cursor: pointer;

        &.selected {
            background-color: var(--color-bgDark);
        }
    }
    &__item:focus,
    &__item:hover {
        background-color: alpha(--color-bgDark, 0.6);

        &.selected {
            background-color: var(--color-bgDark);
        }
    }

    &__check {
        position: absolute;
        right: rem(16px);
        display: none;
        font-size: rem(24px);
    }

    &__item.selected &__check {
        display: block;
    }

    &--open &__content {
        max-height: rem(228px);
        pointer-events: all;
        visibility: visible;
    }
    &--open &__button {
        border-color: var(--color-borderTextfield);
    }
    &--open &__icon {
        transform: rotateX(180deg);
    }

    &__input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    &__inputLabel {
        opacity: 0;
        pointer-events: none;
        position: absolute;
    }

    &--errorState &__input {
        border: 1px solid var(--color-negativeInfo);
    }

    &--validState.mt16-select--validEnabled &__input {
        border: 1px solid var(--color-positiveInfo);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
