@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw12-recovered-email {
    @include wizard(&);

    &__txt {
        margin-top: rem(16px);
        @extend %txt;
        @extend %font-heading5;
        color: var(--color-blackAcc);
    }

    &__link {
        @extend %buttonReset;
        @extend %button-primary3;
        margin-top: rem(112px);
        text-align: center;
        align-self: flex-end;
    }
}
