@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp25-refresh-update-profile {
    @include actionPopup();

    max-width: rem(862px);
    @include respond-above(m) {
        width: rem(862px);
    }

    p {
        @extend %dmSansFontMedium;
        letter-spacing: normal;
        a {
            @extend %dmSansFontMedium;
            cursor: pointer;
            color: var(--color-info);
            font-weight: 500;
            text-decoration: underline;
        }
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        font-size: rem(16px);
        top: rem(23px);
        right: rem(23px);
        cursor: pointer;
        color: var(--color-txtAcc);
        font-weight: 600;

        &--hidden {
            display: none;
        }
    }

    &__banner {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: rem(20px);

        @include respond-above(m) {
            flex-direction: row;
            gap: rem(30px);
        }
    }

    &__textContainerBanner {
        width: 100%;

        @include respond-above(m) {
            width: rem(550px);
        }
    }

    &__titleBanner {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
    }

    &__textBanner {
        font-size: rem(14px);
        line-height: rem(20px);
    }

    &__content {
        padding: rem(20px);
        max-height: 97vh;

        @include respond-above(m) {
            padding: rem(40px);
            max-height: 97vh;
        }
    }

    &__form {
        display: flex;
        flex-flow: column;
        margin-top: rem(20px);

        &--hidden {
            display: none;
        }
    }

    &__formSection {
        display: flex;
        flex-flow: column;
        margin-top: rem(24px);
        gap: rem(16px);

        @include respond-above(m) {
            flex-flow: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__formSection:first-child {
        margin-top: 0;
        gap: rem(20px);

        @include respond-above(m) {
            gap: 0;
        }
    }

    &__alertMessageInputs {
        &--hidden {
            display: none;
        }
    }

    &__mailAlertMessage {
        max-width: rem(318px);
        display: flex;
        align-items: center;

        &--hidden {
            display: none;
        }

        span {
            background-color: var(--color-primary2);
            color: white;
            width: rem(23px);
            height: rem(23px);
            border-radius: rem(12px);
            margin-right: rem(10px);
            padding: rem(3px);
        }

        p {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
        }
    }

    &__mailAddress.mt16-select,
    &__phoneNumber {
        width: 100%;

        @include respond-above(m) {
            max-width: rem(383px);
        }
    }

    &__consentsSection {
        flex-direction: column;
        background: #f8f6ef;
        padding: rem(20px) rem(30px);
        border-radius: rem(20px);
    }

    &__box {
        margin-top: rem(16px);
        align-self: stretch;

        @include respond-below(m) {
            &:not(:last-of-type) {
                padding-bottom: rem(24px);
                border-bottom: 1px solid alpha(--color-black, 0.1);
            }
        }
    }
    &__box ~ &__box {
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-top: rem(32px);
        }
    }

    &__boxPretitle {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: lightness(--color-black, 20%);
    }

    &__boxTitle {
        @extend %dmSansFontBold;
        display: none;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(8px);

        @include respond-above(m) {
            font-weight: 500; // medium
            line-height: rem(16px);
        }
    }

    &__boxText {
        font-size: rem(12px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        margin-top: rem(10px);

        p {
            @extend %dmSansFontRegular;
        }
    }

    &__boxNotSelected {
        @extend %font-body3;
        color: var(--color-semantic2);
        margin-top: rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__boxCheckbox {
        display: flex;
        flex-direction: column;
        margin-top: rem(14px);
        width: 100%;
        gap: rem(10px);

        .rt003-checkbox {
            margin-bottom: rem(10px);
        }
    }

    &__privacy.mt17-checkbox--errorState,
    &__regulationCartaInsieme.mt17-checkbox--errorState {
        margin-bottom: rem(12px);
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        margin-top: rem(60px);

        @include respond-above(m) {
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: row;
        }
    }

    &__bottomText {
        @extend %dmSansFontRegular;
        display: flex;
        flex-direction: column;
        gap: rem(15px);
        font-size: rem(14px);
        line-height: rem(20px);

        @include respond-above(m) {
            margin-right: rem(20px);
        }
    }

    &__ctaClose {
        @extend %buttonReset;
        @extend %button-secondary;
        padding: rem(18px) rem(32px);
        min-width: rem(140px);
        text-align: center;
        font-size: rem(16px);
        line-height: rem(16px);

        @include respond-above(m) {
            margin-bottom: unset;
        }
    }

    &__ctaAction {
        @extend %buttonReset;
        @extend %button-primary3;
        min-width: rem(140px);
        text-align: center;
        font-size: rem(16px);
        line-height: rem(16px);
        margin-bottom: 0 !important;

    }
}
