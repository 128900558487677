@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm12-request-card-step2 {
    @include modal(&, true);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__form {
        padding-bottom: rem(50px);
    }

    &__formSection {
        margin-top: rem(24px);

        &:first-child {
            margin-top: rem(16px);
        }
    }

    &__separator {
        margin-top: rem(32px);
        border-top: 1px solid
            hsla(var(--color-borderTextfield-h), var(--color-borderTextfield-s), var(--color-borderTextfield-l), 0.1);
    }

    &__separator + &__formSection {
        margin-top: rem(24px);
    }

    &__presavisione {
        margin-top: rem(24px);
    }

    &__regolamento {
        margin-top: rem(24px);
    }

    &__privacy {
        margin-top: rem(24px);
    }

    &__informativaClienti {
        margin-top: rem(24px);
    }

    &__privacyText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-blackAcc);

        & > a {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__box {
        margin-top: rem(24px);
        align-self: stretch;

        &:not(:last-of-type) {
            padding-bottom: rem(24px);
            border-bottom: 1px solid alpha(--color-black, 0.1);
        }
    }

    &__boxPretitle,
    &__viewedCheckboxesTitle {
        @extend %font-label3;
        color: var(--color-blackAcc);
        font-weight: bold;
    }

    &__boxTitle {
        @extend %font-label;
        color: var(--color-blackAcc);
        font-weight: bold;
        margin-top: rem(8px);
    }

    &__boxText {
        margin-top: rem(16px);
        @extend %font-body2;
        color: var(--color-blackAcc);
    }

    &__boxNotSelected {
        @extend %font-body3;
        color: var(--color-semantic2);
        margin-top: rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__refreshCampaignTexts {
        max-width: rem(416px);
        background: #f8f6ef;
        border-radius: rem(20px);
        padding: rem(16px);
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);

        p > a {
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__bottomDiscoverMore {
        margin-top: rem(10px);
    }

    &__submit {
        @extend %button-primary3;
        text-align: center;
        min-width: rem(138px);
        pointer-events: all;

        &--disabled {
            pointer-events: none;
            color: var(--color-txtButton);
            background-color: var(--color-bgButton);
        }
    }

    &__submit:focus-visible {
        @extend %button-focused;
    }
}
