@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw21-form-gcc-step2 {
    @include wizard(&);

    &__instructions {
        margin-bottom: calc(#{rem(24px)} + #{rem(40px)});
    }

    &__stepsIcons {
        align-self: center;
        position: static !important;
        margin-bottom: rem(32px);

        @include respond-above(l) {
            align-self: flex-start;
            margin-bottom: rem(24px);
        }
    }

    &__error {
        margin-top: rem(16px);
        overflow: hidden;

        &::before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__section {
        margin-top: rem(40px);
    }

    &__sectionTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            line-height: rem(28px);
        }
    }

    &__sectionText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        margin-top: rem(8px);
    }

    &__formSection {
        display: flex;
        flex-flow: column;
        margin-top: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
        }
    }
    &__idType,
    &__educationalQualification,
    &__profession,
    &__maritalStatus,
    &__parents,
    &__others {
        max-width: 100% !important;
    }
    &__idNumber,
    &__releaseInstitution,
    &__profession,
    &__parents,
    &__others {
        margin-top: rem(24px);
    }
    @include respond-above(l) {
        &__idNumber,
        &__releaseInstitution,
        &__profession,
        &__parents,
        &__others {
            margin-top: unset;
        }

        &__idType {
            margin-right: rem(8px);
            flex: 1 0 0;
        }
        &__idNumber {
            margin-left: rem(8px);
            flex: 1 0 0;
        }

        &__releaseDate {
            margin-right: rem(8px);
            flex: 1 0 0;
        }
        &__releaseInstitution {
            margin-left: rem(8px);
            flex: 1 0 0;
        }

        &__educationalQualification {
            margin-right: rem(8px);
            flex: 1 0 0;
        }
        &__profession {
            margin-left: rem(8px);
            flex: 1 0 0;
        }

        &__maritalStatus {
            margin-right: 0;
            flex: 57.5 0 0;
        }
        &__parents {
            margin-left: rem(8px);
            margin-right: rem(8px);
            flex: 57.5 0 0;
        }
        &__others {
            margin-left: rem(8px);
            flex: 37 0 0;
        }
        &__maritalStatus + &__parents,
        &__maritalStatus + &__others {
            margin-left: rem(16px);
        }
    }

    &__box {
        margin-top: rem(24px);
        align-self: stretch;

        &:not(:last-of-type) {
            padding-bottom: rem(24px);
            border-bottom: 1px solid alpha(--color-black, 0.1);
        }
    }

    &__boxPretitle {
        @extend %font-label3;
        color: var(--color-blackAcc);
        font-weight: bold;
    }

    &__boxTitle {
        @extend %font-label;
        color: var(--color-blackAcc);
        font-weight: bold;
        margin-top: rem(8px);
    }

    &__boxText {
        margin-top: rem(16px);
        @extend %font-body2;
        color: var(--color-blackAcc);
    }

    &__boxNotSelected {
        @extend %font-body3;
        color: var(--color-semantic2);
        margin-top: rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        margin-top: rem(40px);

        @include respond-above(l) {
            flex-flow: row-reverse;
        }
    }

    &__verifyIban {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtDark);
        background-color: var(--color-primary3);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        text-align: center;
        pointer-events: all;
        cursor: pointer;
        display: block;
        margin-top: rem(40px);

        @include respond-above(l) {
            width: fit-content;
            margin-top: rem(32px);
        }
    }

    &__sendRequest {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        text-align: center;
        pointer-events: all;
        cursor: pointer;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);

        @include respond-above(l) {
            width: fit-content;
        }

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }
}