@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ms2-header {
    box-shadow: 0 1px 1px 0 alpha(--color-borderSand, 0.15), 0 0 1px 0 alpha(--color-borderSand, 0.2);

    &__container {
        @extend %container;
        display: flex;
        height: $nav-height;
        align-items: center;
        justify-content: space-between;
        position: relative;

        @include respond-above(l) {
            height: $nav-height-desk;
            justify-content: flex-end;
        }
    }

    &__menu {
        display: flex;
        align-items: center;

        @include respond-above(l) {
            display: none;
        }
    }

    &__menuBtn {
        @extend %buttonReset;
        font-size: rem(24px);

        & > span {
            pointer-events: none;
        }
    }

    &__imgs {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;

        @include respond-above(l) {
            left: 0;
            transform: translateX(0);
            padding-left: rem(40px);
        }
    }

    &__imgDsk {
        display: none;
        height: rem(35px);

        @include respond-above(l) {
            display: block;
            height: rem(42px);
        }
    }

    &__imgMob {
        height: rem(35px);

        @include respond-above(l) {
            display: none;
            height: rem(42px);
        }
    }

    &__user {
        margin-left: rem(16px);
        text-align: right;

        @include respond-above(l) {
            flex: 0 0 auto;
            margin-left: 0;
            margin-right: rem(16px);
        }
    }

    &__userDisplayName {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &__userSaldo {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
    }

    &__userSaldoPre {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
    }

    &__cart {
        @extend %buttonReset;
        display: none;
        background-color: var(--color-primary2);
        border-radius: 16px;
        padding: rem(16px);
        cursor: pointer;

        @include respond-above(l) {
            margin-left: rem(24px);

            &--show {
                display: block;
            }
        }
    }

    &__cartDataWrapper {
        display: flex;
        align-items: center;
    }

    &__cart:focus-visible {
        @extend %button-focused;
    }

    &__cartData {
        @extend %robotoFontMedium;
        color: var(--color-txtAcc);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(20px);
            margin-right: rem(16px);
        }
    }

    &__cartIcon {
        font-size: rem(24px);
    }
}
