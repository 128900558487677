@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt3-store {
    display: flex;
    flex-direction: column;
    border-radius: rem(12px);
    padding: rem(16px);
    background-color: var(--color-bg);
    margin-top: rem(16px);
    transition: all 0.3ms ease-in-out;

    &:focus-visible {
        @extend %button-focused;
    }

    &--selected {
        box-shadow: 0 0 10px -2px var(--color-primary3);
    }

    &__wrapperData {
        display: flex;
    }

    &__informativeText {
        @extend %dmSansFontMedium;
        color: var(--color-txtTab);
        display: flex;
        align-items: center;

        @include respond-above(m) {
            margin-left: rem(96px);
        }

        span {
            color: #FFFFFF;
            background: var(--color-info);
            border-radius: 12px;
            margin-right: rem(10px);
        }
    }

    &__img {
        flex: 0 0 auto;
        margin-right: rem(16px);

        &::before {
            display: block;
            
            @include respond-below(l) {
                margin-top: rem(23px);
                margin-bottom: rem(-12px);
            }
        }

        &--store {
            &::before {
                content: url('#{$resourcesFolder}/myconad/icons/store-gray.svg');
            }
        }
    }
    &--selected &__img--store::before {
        content: url('#{$resourcesFolder}/myconad/icons/store-orange.svg');
    }

    &__center {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__right {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include respond-below(l) {
            position: absolute;
        }
    }

    &__title {
        @extend %font-heading5;
        font-weight: bold;
        margin-bottom: rem(4px);
    }

    &__address {
        @extend %font-body2;
        color: alpha(--color-txt, 0.8);
        margin-bottom: rem(9px);
    }

    &__info {
        @extend %font-body3;
        color: lightness(--color-black, 60%);
        font-weight: bold;

        &::before {
            content: '';
            vertical-align: middle;
            display: inline-block;
            height: rem(16px);
            width: rem(16px);
            background-size: rem(16px) rem(16px);
            background-image: url('#{$resourcesFolder}/myconad/icons/info.svg');
            background-repeat: no-repeat;
            margin-right: rem(8px);
        }
    }

    &__badge {
        @extend %badge;
        margin-top: rem(46px);
    }

    &__distance {
        @extend %badge;
        @extend %font-label2;
        font-weight: bold;
    }

    &__infos + &__badge {
        margin-top: rem(16px);
    }
}
