@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mc7-strillo-app {
    background-color: var(--color-primary3);

    &__wrapper {
        @extend %container;
        display: flex;
        flex-flow: column;
        gap: rem(24px);
        padding-top: rem(24px);
        padding-bottom: rem(24px);

        @include respond-above(m) {
            flex-flow: row;
            padding-top: rem(20px);
            padding-bottom: rem(20px);
        }
    }

    /* icon, title and text */
    &__wrapper > div:first-of-type {
        display: flex;
        gap: rem(12px);

        @include respond-above(m) {
            gap: rem(24px);
            flex-grow: 1;
            padding: rem(10px) 0;
        }
    }

    &__icon {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        font-size: rem(42px);
        color: lightness(--color-darkCharcoal, 80%);
        padding: rem(19px);
        background-color: var(--color-white);
        border-radius: 50%;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-white);
        padding-bottom: rem(16px);

        @include respond-above(m) {
            font-size: rem(24px);
            line-height: rem(32px);
            padding-bottom: rem(8px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-white);
        padding-bottom: rem(16px);
        margin-bottom: rem(8px);

        @include respond-above(m) {
            font-size: rem(16px);
            line-height: rem(24px);
            padding-bottom: unset;
            margin-bottom: unset;
        }

        &--dsk {
            @include respond-below(l) {
                display: none;
            }
        }

        &--mob {
            @include respond-above(l) {
                display: none;
            }
        }
    }

    &__ctasMob {
        display: flex;
        flex-wrap: wrap;
        gap: rem(16px);

        @include respond-above(m) {
            display: none;
        }
    }

    &__cta {
    }

    &__cta img {
        max-width: rem(164px);
        max-height: rem(53px);
        object-fit: contain;
    }

    &__ctasDsk {
        display: none;

        @include respond-above(m) {
            display: flex;
            gap: rem(16px);
            align-self: center;
        }
    }

    &__qrCode {
        display: none;

        @include respond-above(m) {
            display: flex;
            gap: rem(16px);
            background-color: var(--color-white);
            margin-right: rem(20px);
            padding-top: rem(8px);
            padding-right: rem(8px);
            padding-bottom: rem(8px);
            padding-left: rem(16px);
        }
    }

    &__qrCodeText {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
        max-width: rem(76px);
        text-align: center;

        /* arrow icon */
        & > span:last-child {
            display: block;
            font-size: rem(16px);
            color: var(--color-darkCharcoal);
        }
    }

    &__qrCodeImage {
        width: rem(84px);
        height: rem(84px);
        object-fit: contain;
        object-position: center;
    }

    &__desktopLink {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-darkCharcoal);
        padding: rem(20px) rem(32px);
        background-color: var(--color-white);
        border-radius: rem(12px);
    }
}
