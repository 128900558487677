@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mf14-travel {
    $root: &;

    @include respond-below(l) {
        margin-top: rem(27px);
    }

    &__intro {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        display: block;
        max-width: 80ch;

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }
    &__orderStatesCta {
        @extend %buttonReset;
        color: var(--color-info);
        text-decoration: underline;
        font-weight: 500;
    }
    &__orderStatesCta:focus-visible {
        @extend %button-focused;
    }

    &__alert {
        margin-bottom: rem(25px);

        @include respond-above(l) {
            margin-top: rem(-29px);
        }
    }

    &__tabs {
        display: flex;
        justify-content: space-around;

        @include respond-above(l) {
            justify-content: unset;
        }
    }

    &__tabLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtTab);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            padding-bottom: rem(8px);
        }
    }

    &__reservationsContent,
    &__favoritesContent {
        margin-bottom: rem(48px);
    }

    /* buttons */
    &__reservationsTab,
    &__favoritesTab {
        @extend %buttonReset;
        flex: 1 0 0;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-txtTab);
        border-radius: rem(12px);
        padding: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            border: unset;
            border-radius: unset;
            flex: unset;
            padding: unset;
        }

        /* mobile/desktop div */
        & > div {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }

        /* icons */
        span {
            font-size: rem(24px);
            margin-bottom: rem(8px);

            @include respond-above(l) {
                margin-bottom: unset;
                margin-right: rem(8px);
                align-self: flex-start;
            }
        }
    }
    &__reservationsTab:focus-visible,
    &__favoritesTab:focus-visible {
        @extend %button-focused;
    }
    &__reservationsTab {
        margin-right: rem(3px);

        @include respond-above(l) {
            margin-right: rem(16px);
        }

        & > span:first-child {
            color: var(--color-txtTab);
        }
    }
    &__favoritesTab {
        margin-left: rem(3px);

        @include respond-above(l) {
            margin-left: rem(16px);
        }

        & > span:first-child {
            color: var(--color-txtTab);
        }
    }
    &__activeTab {
        border-color: var(--color-blackAcc);

        & > span:first-child,
        #{$root}__tabLabel {
            color: var(--color-blackAcc);
        }

        #{$root}__tabDecoration {
            display: flex;
            width: rem(60px);

            & > div {
                width: rem(32px);
            }
        }
    }

    &__tabDecoration {
        position: absolute;
        bottom: rem(-2.2px);
        width: rem(20px);
        height: rem(2.5px);
        background-color: var(--color-bg);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: none;
            height: rem(4px);
            bottom: rem(-3px);
        }

        & > div {
            width: rem(3px);
            height: inherit;
            background-color: var(--color-primary2);
            border-radius: rem(4px);
        }
    }

    &__monthReservations {
        display: flex;
        flex-direction: column;
        gap: rem(16px);
    }

    &__month {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        text-align: left;
        margin-top: rem(24px);
        margin-bottom: rem(16px);
    }

    &__reservations {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(6px);

        &--endLoadMore {
            margin-bottom: rem(16px);
        }
    }

    &__favorites {
        display: flex;
        flex-direction: column;
        gap: rem(16px);
        margin-bottom: rem(16px);

        @include respond-above(m) {
            flex-flow: row wrap;
            align-items: stretch;
        }
    }

    &__modRes {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);
        margin-bottom: rem(8px);

        @include respond-below(l) {
            margin-bottom: rem(0px);
            margin-top: rem(25.5px);
        }

        &--hidden {
            display: none;
        }
    }

    &__loadMore {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        cursor: pointer;
        text-decoration: underline;
        margin-top: rem(16px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(18px);

        &--loader {
            background-image: url('#{$resourcesFolder}/myconad/icons/loader.gif');
            text-indent: -9999px;
        }

        &--hidden {
            display: none;
        }

        @include respond-below(l) {
            margin-bottom: rem(64px);
        }
    }

    &__loadMoreFav {
        margin-top: rem(32px);
    }

    &__goTo {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        cursor: pointer;
        text-decoration: underline;
        margin-top: rem(8px);
        margin-bottom: rem(16px);

        @include respond-below(l) {
            margin-top: rem(40px);
            margin-bottom: rem(40px);
        }

        &--hidden {
            display: none;
        }
    }

    &__empty {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: rem(32px);

        @include respond-below(l) {
            margin-top: rem(41.3px);
        }

        &--hidden {
            display: none;
        }
    }

    &__emptyImage {
        width: rem(158.9px);

        @include respond-below(l) {
            margin-top: rem(41.3px);
        }
    }

    &__emptyTitle {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        text-align: center;
        color: rgb(124, 124, 124);
        margin-top: rem(16px);

        @include respond-below(l) {
            margin-top: rem(15.4px);
        }
    }

    &__emptySubTitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        margin-top: rem(8px);
        text-align: center;
    }

    &__emptyCta {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        display: inline-block;
        width: fit-content;
        text-align: center;
        margin-top: rem(24px);
        color: var(--color-txtDark);
        background-color: var(--color-primary3);

        @include respond-below(l) {
            width: 100%;
        }
    }
}
