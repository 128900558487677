@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mc4-alert-box {
    display: flex;
    padding: rem(16px);
    background-color: var(--color-secondary4);
    border-radius: 12px;

    &--hidden {
        display: none;
    }

    &__icon {
        align-self: center;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        object-fit: cover;
        background-image: url('#{$resourcesFolder}/myconad/icons/alert-yellow.svg');
        margin-right: rem(16px);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: alpha(--color-txt, 60%);
        display: block;
        max-width: 80ch;
    }
}
