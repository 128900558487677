@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw14-confirmed-operation {
    @include wizard(&);
    display: flex;
    flex-direction: column;

    &__container {
        display: none;

        &--show {
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            @include respond-above(l) {
                flex-flow: row;
            }
        }
    }

    &__image {
        width: rem(148px);
        height: auto;
        margin-right: rem(12px);
    }

    &__result {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        max-width: rem(464px);
    }

    &__txt {
        margin-top: rem(8px);
        @extend %font-heading5;
        @extend %txt;
    }

    &__link {
        @extend %buttonReset;
        @extend %button-primary3;
        display: block;
        text-align: center;
        align-self: flex-end;
        margin-top: rem(40px);
        width: 100%;

        @include respond-above(l) {
            margin-top: rem(72px);
            min-width: rem(248px);
            width: unset;
        }
    }
    &[data-name='confirmed-delete-profile'] &__link {
        margin-top: rem(28px);
    }
}
