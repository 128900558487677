@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp11-gcc-thankyou {
    @include actionPopup();

    &__link {
        @extend %buttonReset;
        @extend %button-primary3;
        text-align: center;

        @include respond-above(m) {
            margin-bottom: unset;
        }
    }
}