@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt18-textarea {
    position: relative;
    width: 100%;
    display: block;

    &__wrapper {
        position: relative;
        border-radius: rem(16px);
        overflow: hidden;
    }
    &__headbar {
        content: '';
        position: absolute;
        display: none;
        height: rem(24px);
        top: 1px;
        left: 2px;
        border: hidden;
        border-radius: rem(20px);
        width: calc(100% - 30px);
    }

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        display: block;
        overflow-y: auto;
        width: 100%;
        min-height: rem(56px);
        height: rem(56px);
        outline: none;
        padding: rem(26px) rem(16px) rem(16px) rem(16px);
        background-color: transparent;
        border-radius: rem(16px);
        border: 1px solid alpha(--color-borderTextfield, 0.3);
    }

    /* hide scrollbar for Chrome, Safari and Opera */
    &__input::-webkit-scrollbar {
        display: none;
    }
    /* hide scrollbar for IE, Edge and Firefox */
    &__input {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }
    &__input:focus ~ &__headbar {
        border-top-color: var(--color-primary2);
        border-right-color: var(--color-primary2);
        border-left-color: var(--color-primary2);
        background-color: #fef6cc;
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
    }

    &__input:focus + &__label,
    &__input:not(:placeholder-shown) + &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: alpha(--color-txt, 0.8);
        background-color: transparent;
        z-index: 5002;
    }
    &__input:focus ~ &__headbar,
    &__input:not(:placeholder-shown) ~ &__headbar {
        display: block;
        z-index: 5001;
    }
    &__input:focus ~ &__expander::after,
    &__input:not(:placeholder-shown) ~ &__expander::after {
        border-color: #fef6cc;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: rem(24px);
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: alpha(--color-txt, 0.8);

        &--optional {
            display: none;
        }
    }

    &__expander {
        position: absolute;
        bottom: 2px;
        right: 2px;
        width: rem(24px);
        height: rem(48px);
        background-image: url('#{$resourcesFolder}/myconad/icons/expander.svg');
        pointer-events: none;
    }
    &__expander::after {
        content: ' ';
        pointer-events: none;
        position: absolute;
        bottom: 0.6px;
        right: 0.6px;
        width: 20px;
        height: 20px;
        background: transparent;
        border-right: 3px solid var(--color-white);
        border-radius: 8.5px;
        transform: rotate(45deg);
    }

    &--errorState &__input {
        border: 1px solid var(--color-negativeInfo); //lightness(--color-borderError, 40%);
    }

    &--validState &__input {
        border-color: var(--color-positiveInfo);
    }

    &--warningState &__input {
        border-color: var(--color-warningInfo);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
