@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt9-message-popup {
    max-width: rem(784px);

    &__content {
        position: relative;
        display: flex;
        justify-content: stretch;
        flex-flow: column;
        background-color: var(--color-bg);
        overflow: hidden;
        height: 100vh;
        margin: 0 rem(-5px);

        @include respond-above(l) {
            justify-content: unset;
            flex-flow: row;
            max-height: rem(372px);
            margin: unset;
            border-radius: rem(12px);
        }
    }

    &__media {
        flex: 0 0 auto;

        @include respond-above(l) {
            flex: unset;
        }
    }
    &__image {
        object-fit: cover;
        width: 100%;
        height: rem(304px);
        
        @include respond-above(l) {
            width: rem(233px);
            height: 100%;
        }
    }

    &__message {
        flex: 1 1 auto;
        display: flex;
        flex-flow: column;
        justify-items: stretch;
        overflow: hidden;
        padding: rem(41px) rem(24px) rem(116px) rem(24px);

        @include respond-above(l) {
            flex: unset;
            padding-right: rem(57px);
            padding-left: rem(57px);
        }
    }
    &__media + &__message {
        @include respond-above(l) {
            padding-left: 24px;
        }
    }
    &__date {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-gray);
        flex: 0 0 auto;
    }
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        padding: rem(8px) 0 rem(8px) 0;
        flex: 0 0 auto;

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }
    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        position: relative;
        overflow-y: hidden;
        padding-right: rem(15px);
        flex: 1 1 auto;

        @include adjustPerfectScrollbar();
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        font-size: rem(24px);
        top: rem(23px);
        right: rem(23px);
        cursor: pointer;
        color: var(--color-txtAcc);
    }
    &__close:focus-visible {
        @extend %button-focused;
    }
    &__media ~ &__close {
        @include respond-below(l) {
            color: var(--color-txtDark);
        }
    }

    &__ctas {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: rem(30px);
        right: rem(30px);
    }

    &__ctaCancel {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
    }
    &__ctaCancel:focus-visible {
        @extend %button-focused;
    }

    &__ctaAction {
        height: rem(56px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        margin-left: rem(46px);
        color: var(--color-white);
        cursor: pointer;
    }
}