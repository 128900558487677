@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mf16-customer-care {
    $root: &;
    padding-bottom: rem(80px);
    margin-top: rem(6px);

    @include respond-above(l) {
        margin-top: rem(12px);
    }

    &__intro {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(32px);

        @include respond-above(l) {
            margin-bottom: rem(24px);
        }

        a {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__tabs {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        @include respond-above(l) {
            justify-content: unset;
        }

        @include respond-below(l) {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
        }

        /* hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    /* hide scrollbar for Chrome, Safari and Opera */
    &__tabs::-webkit-scrollbar {
        display: none;
    }

    &__tabLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtTab);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            padding-bottom: rem(8px);
        }
    }

    &__tabBtn {
        @extend %buttonReset;
        flex: 1 0 0;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-txtTab);
        border-radius: rem(12px);
        padding: rem(20px);
        margin-bottom: 3px;
        max-height: 71px;
        min-width: 128px;

        @include respond-above(l) {
            flex-flow: row;
            border: unset;
            border-radius: unset;
            flex: unset;
            padding: unset;
            max-height: unset;
            min-width: unset;
        }

        /* mobile/desktop div */
        & > div {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }

        &:first-child {
            margin-right: rem(3px);

            @include respond-above(l) {
                margin-right: rem(16px);
            }
        }
        &:not(:first-child):not(:last-child) {
            margin-left: rem(3px);
            margin-right: rem(3px);

            @include respond-above(l) {
                margin-left: rem(16px);
                margin-right: rem(16px);
            }
        }
        &:last-child {
            margin-left: rem(3px);

            @include respond-above(l) {
                margin-left: rem(16px);
            }
        }
    }

    &__activeTab {
        border-color: var(--color-blackAcc);

        #{$root}__tabLabel {
            color: var(--color-blackAcc);
        }

        #{$root}__tabDecoration {
            display: flex;
            width: rem(60px);

            & > div {
                width: rem(32px);
            }
        }
    }

    &__tabDecoration {
        position: absolute;
        bottom: rem(-2.2px);
        width: rem(20px);
        height: rem(2.5px);
        background-color: var(--color-bg);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: none;
            height: rem(4px);
            bottom: rem(-3px);
        }

        & > div {
            width: rem(3px);
            height: inherit;
            background-color: var(--color-primary2);
            border-radius: rem(4px);
        }
    }

    &__tabPan:not([hidden]) {
        display: flex;
        flex-flow: column;
        width: 100%;
    }

    &__noRequests {
        margin-top: rem(40px);
    }
    &__noRequestsTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }
    &__noRequestsText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);

        a {
            @extend %dmSansFontMedium;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__sort {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        display: none; /* prehide */
        width: fit-content;
        margin-top: rem(24px);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            margin-top: rem(12px);
            margin-left: auto;
            margin-bottom: rem(10px);
        }

        &--show {
            display: block;
        }
    }
    &__sortBy {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);

        /* prehide button that sorts by 'leastrecent' */
        &[data-sort-by='leastrecent'] {
            display: none;
        }

        /* chevron icons */
        & > span:last-child {
            font-size: rem(16px);
            color: var(--color-info);
            position: relative;
            bottom: -2px;
        }
    }

    &__requests {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
    }
    &__requests:empty {
        display: none;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: rem(16px);
        margin-top: rem(40px);

        @include respond-above(l) {
            margin-top: rem(36px);
        }
    }

    &__page {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);

        &--hidden {
            display: none;
        }

        &--current {
            color: lightness(--color-txtAcc, 60%);
            pointer-events: none;
        }
    }

    &__prev,
    &__next {
        @extend %buttonReset;
        font-size: rem(24px);
    }
}
