@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp18-gcc-check-email {
    @include actionPopup();

    @include respond-above(l) {
        max-width: rem(720px);
        width: rem(720px);
    }
}
