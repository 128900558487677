@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw18-migration-step2 {
    @include wizard(&);

    &__stepsIcons {
        @include respond-below(l) {
            position: static !important;
            align-self: center;
            margin-bottom: rem(32px);
        }
    }

    &__title--back::before {
        @include respond-below(l) {
            position: absolute;
            top: rem(7px);
        }
    }

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &::before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        margin-bottom: rem(40px);
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: rem(24px);
        }
    }
    &__textAnteCPlus {
        margin-top: rem(8px);
    }

    &__formSection {
        margin-top: rem(24px);
    }

    &__formItem:not(:first-child) {
        margin-top: rem(24px);
    }

    &__formSection &__formItem:not(:first-child) {
        @include respond-above(l) {
            margin-top: unset;
            margin-left: rem(16px);
        }
    }

    &__privacyTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__privacyText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-bottom: rem(2px);

        a {
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
            font-weight: 500;
        }
    }

    &__box {
        margin-top: rem(24px);
        align-self: stretch;

        &:not(:last-of-type) {
            padding-bottom: rem(24px);
            border-bottom: 1px solid alpha(--color-black, 0.1);
        }
    }

    &__boxPretitle {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-blackAcc);
    }

    &__boxTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-blackAcc);
        margin-top: rem(8px);

        @include respond-above(l) {
            font-weight: 500; /* medium */
            line-height: rem(16px);
        }
    }

    &__boxText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-blackAcc);
        margin-top: rem(16px);
    }

    &__boxNotSelected {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        margin-top: rem(16px);
        display: none;

        @include respond-above(l) {
            font-size: rem(13px);
        }

        &--show {
            display: block;
        }
    }

    &__emailTitle,
    &__updatePwdTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            margin-bottom: rem(16px);
        }
    }
    &__emailTitle {
        margin-top: rem(32px);

        @include respond-above(l) {
            margin-top: rem(30px);
        }
    }
    &__updatePwdTitle {
        margin-top: rem(32px);
    }

    &__emailText,
    &__updatePwdText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        margin-bottom: rem(24px);

        @include respond-above(l) {
            margin-bottom: rem(28px);
        }
    }

    &__email {
        max-width: 100% !important;

        @include respond-above(l) {
            ul {
                width: calc(#{math.div(406,624)*100} * 1%);
            }
        }
    }

    &__passwordRules {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        overflow: hidden;
        max-height: rem(50px);
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            max-height: 0;
        }
    }

    &__passwordMismatch {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        overflow: hidden;
        visibility: visible;
        pointer-events: all;
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__passwordState {
        flex: 0 0 auto;
        margin-right: rem(16px);
        & > .ok {
            font-size: rem(16px);
            background-color: var(--color-secondary3);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .error {
            font-size: rem(16px);
            background-color: var(--color-primary);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .active {
            display: block;
        }
    }

    &__passwordText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
    }
    &__passwordMismatchText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-primary);
    }

    &__confirmPassword {
        margin-top: rem(16px);
    }
    &__passwordRules--hidden + &__confirmPassword {
        margin-top: 0;
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        margin-top: rem(40px);

        @include respond-above(l) {
            flex-flow: row-reverse;
        }
    }
    &__passwordMismatch--hidden + &__ctas {
        margin-top: 0;
    }

    &__next {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        text-align: center;
        pointer-events: all;
        cursor: pointer;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }

    &__deleteAccount {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        display: inline-block;
        margin: rem(24px) 0;
        text-decoration: underline;
        text-align: center;
        cursor: pointer;

        @include respond-above(l) {
            margin: rem(16px) 0;
            margin-right: rem(24px);
        }
    }
}