@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw1-login {
    @include wizard(&);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        margin-top: rem(8px);
    }
    &__error--hidden + &__form {
        margin-top: rem(26px);

        @include respond-above(m) {
            margin-top: rem(40px);
        }
    }

    &__password {
        margin-top: rem(24px);
    }

    &__formBottom {
        display: flex;
        flex-flow: column;

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__formBottom > div {
        display: flex;
        flex-flow: column;
        order: 2;

        @include respond-above(m) {
            order: 1;
        }
    }

    &__mantain {
        margin-top: rem(24px);

        @include respond-below(m) {
            position: relative;
            top: -75px;
        }

        & > .mt17-checkbox__labelText {
            font-size: rem(12px);
            font-weight: 400;
        }
    }

    &__content {
        order: 1;
    }

    &__submit {
        margin-top: rem(24px);
        @extend %button-primary3;
        text-align: center;
        order: 1;

        @include respond-above(m) {
            margin-top: rem(16px);
            margin-left: rem(16px);
            align-self: flex-end;
            order: 2;
        }

        @include respond-below(m) {
            position: relative;
            top: 43px;
        }
    }

    &__formTxt {
        @extend %font-label3;
        @extend %txt;
        font-weight: 500;
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-top: rem(28px);
        }
    }

    &__recaptchaPolicy {
        @extend %font-label3;
        @extend %txt;
        font-weight: 500;
        margin-top: rem(16px);
    }

    &__alternative {
        display: flex;
        align-items: center;
        margin-top: rem(40px);
        margin-bottom: rem(32px);

        @include respond-above(m) {
            margin-top: rem(48px);
        }

        & > hr {
            display: block;
            flex: 1 1 auto;
            color: lightness(--color-txt, 90%);
            border: 1px solid;
        }
    }

    &__alternativeText {
        margin-left: rem(16px);
        margin-right: rem(16px);
        color: var(--color-gray);
        font-weight: bold;
    }

    &__alert_12_hours_login {
        display:flex;
        align-items: center;
    }

    &__alert_12_hours_icon {
        flex: 0 0 auto;
        -o-object-fit: contain;
         object-fit: contain;
        width: 1rem;
        height: 1.0625rem;
        background: url("#{$resourcesFolder}/myconad/icons/info-gray.svg");
        margin-right: rem(5px);
    }

    &__alert_12_hours_text {
        @extend %font-label3;
        @extend %txt;
        font-weight: 500;
    }

    &__social {
        margin-top: rem(24px);
        
        @include respond-above(m) {
            margin-top: rem(40px);
        }
    }

    &__socialButton {
        @extend %button-empty;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        color: var(--color-txtAcc);

        & > img {
            width: rem(24px);
        }

        & > span {
            flex: 1 1 auto;
        }

        &:not(:first-of-type) {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            padding-right: rem(32px);
            padding-left: rem(32px);
        }
    }

    &__endTxt {
        margin-top: rem(24px);
        @extend %font-heading5;
        @extend %txt;
    }
}
