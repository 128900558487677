@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt42-product-flyer-card {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
    height: rem(513px);

    justify-content: space-between;
    height: rem(296px);
    background-color: var(--color-white);
    border-radius: rem(12px);
    padding: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    --color-lightYellow: #fffbe6;
    --color-redPromotion: #e20714;
    --color-neutral: #f8f6ef;

    &--fadeOut {
        @extend %fadeOut;
    }


    &[data-evidence] {
        background-color: var(--color-lightYellow);
    }

    @include respond-between(m, l) {
        //width: 49%;
    }

    @include respond-above(l) {
        //width: 23.7%;
        height: rem(334px);
        padding: rem(16px);
    }

    @include respond-above(xl) {
        //width: 24.1%;
    }

    &--hidden {
        display: none;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: space-between;

        margin-top: rem(8px);
    }

    &__image {
        width: rem(80px);
        height: rem(80px);
        align-self: center;
        object-fit: contain;

        @include respond-above(l) {
            width: rem(104px);
            height: rem(104px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        height: rem(60px);
        color: var(--color-darkCharcoal);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__heart {
        flex-shrink: 0;
        border-style: none;
        cursor: pointer;

        >span {
            color: var(--color-primary3);
            font-size: rem(16px);
        }

        @include respond-above(l) {

            >span {
                color: var(--color-primary3);
                font-size: rem(24px);
            }
        }
    }


    &__heart:hover>span {
        opacity: 0.6;
    }

    &__heart:focus-visible {
        @extend %button-focused;
    }

    &__evidence {
        background-image: linear-gradient(to bottom right,
                var(--color-primary2),
                var(--color-primary),
                var(--color-redPromotion));
        color: var(--color-neutral);
    }

    &__promotion {
        background-color: var(--color-redPromotion);
        color: var(--color-neutral);
    }

    &__points {
        background-color: var(--color-lightYellow);
        color: var(--color-primary);
    }

    &__evidence,
    &__promotion,
    &__points {
        border-radius: rem(8px);
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        padding: rem(8px);
        width: fit-content;
        height: fit-content;

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(16px);
        }
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        height: rem(52px);
    }

    &__topLeft {
        display: flex;
        flex-direction: column;
        gap: rem(8px);
        width: rem(119px);

        @include respond-above(l) {
            width: rem(157px);
        }
    }

    &__carta {
        display: flex;
        gap: rem(8px);
        flex-wrap: wrap;
    }

    &__cartainsieme,
    &__cartainsiemepiu,
    &__bassiefissi,
    &__carteinsieme {
        width: rem(48px);
        height: rem(32px);
        background-size: rem(32px);
        background-repeat: no-repeat;
    }

    &__cartainsieme {
        background-image: url('#{$resourcesFolder}/myconad/icons/cartainsieme.png');
        ;
    }

    &__cartainsiemepiu {
        background-image: url('#{$resourcesFolder}/myconad/icons/cartainsiemepiu.png');
        ;
    }

    &__carteinsieme {
        background-image: url('#{$resourcesFolder}/myconad/icons/carte-insieme.png');
        ;
        background-size: rem(28px);
    }

    &__bassiefissi {
        background-image: url('#{$resourcesFolder}/myconad/icons/bassiefissi.png');
        ;
        background-size: rem(23px);
    }

    &__validity {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-redPromotion);
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: rem(22px);

        height: rem(24px);

        @include respond-above(l) {
            height: rem(38px);
        }
    }

    &__bottomLeft {
        display: flex;
        flex-direction: column;
    }

    &__confText {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(12px);
    }

    &__priceText {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: alpha(var(--color-black), 0.6);
    }

    &__finalPrice {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
        white-space: nowrap;

        @include respond-above(l) {
            font-size: rem(30px);
            line-height: rem(38px);
        }
    }

    &[data-original-price] &__finalPrice {
        color: var(--color-redPromotion);
    }

    &__originalPrice {
        position: absolute;
        right: 0;
        top: rem(-20px);
        text-decoration: line-through;
        text-decoration-thickness: 0.2%;
        text-decoration-color: alpha(var(--color-black), 0.6);
        align-self: flex-end;
        @extend %robotoFontMedium;
        font-size: rem(12px);
        line-height: rem(20px);

        @include respond-above(l) {
            font-size: rem(16px);
        }
    }
}