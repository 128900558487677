@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ms5-background {
    width: 100%;
    height: 100vh;

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
