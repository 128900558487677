@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm13-privacy-consents {
    @include modal(&, true);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__alert {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 95%);
        border-radius: rem(16px);
        margin-top: rem(16px);
        letter-spacing: normal;

        &::before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        &--hidden {
            display: none;
        }
    }

    &__subtitle {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: lightness(--color-black, 20%);
        margin-top: rem(16px);
        padding-bottom: rem(24px);
        border-bottom: 1px solid lightness(--color-black, 90%);
    }

    &__form {
        padding-bottom: rem(50px);
    }

    &__formSection {
        margin-top: rem(24px);
    }

    &__privacyText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);

        & > a {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__box {
        margin-top: rem(16px);
        align-self: stretch;

        @include respond-below(m) {
            &:not(:last-of-type) {
                padding-bottom: rem(24px);
                border-bottom: 1px solid alpha(--color-black, 0.1);
            }
        }
    }
    &__alert--hidden + &__box,
    &__box ~ &__box {
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-top: rem(32px);
        }
    }

    &__boxPretitle {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: lightness(--color-black, 20%);
    }

    &__boxTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(8px);

        @include respond-above(m) {
            font-weight: 500; // medium
            line-height: rem(16px);
        }
    }

    &__boxText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        margin-top: rem(16px);
    }

    &__boxNotSelected {
        @extend %font-body3;
        color: var(--color-semantic2);
        margin-top: rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        @extend %button-primary3;
        text-align: center;
        pointer-events: all !important;

        @include respond-below(m) {
            flex-grow: 1;
        }
    }
}
