@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt28-icon-cards {
    width: 100%;

    &__heading {
        position: relative;
        margin-bottom: 4px;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(m) {
            font-size: rem(24px);
        }
    }

    &__titleDecoration {
        position: absolute;
        top: calc(100% + 8px);
        width: 32px;
        height: 4px;
        background-color: var(--color-primary2);
        border-radius: 4px;
    }

    &__carouselContainer {
        overflow: hidden;
        margin-top: rem(25px);
        margin-bottom: rem(10px);
    }

    &__carousel {
        width: 100%;
        padding: rem(10px) 0;
    }
    
    &__track {
        width: 100%;
        display: flex;
        box-sizing: content-box;
    }

    &__card {
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 12px;
        box-shadow: 0 4px 8px -2px alpha(--color-boxShadow, 0.15), 0 0 1px 0 alpha(--color-boxShadow, 0.2);
        padding: rem(14px);
    }

    &__cardImage {
        object-fit: contain;
        width: 52px;
        height: 52px;
    }
}