@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp2-psw-recovered {
    @include respond-above(m) {
        width: rem(624px);
    }

    &__content {
        position: relative;
        display: flex;
        //flex-flow: column;
        background-color: var(--color-bg);
        border-radius: rem(12px);
        overflow: hidden;
        margin: 0 rem(5px);

        @include respond-above(l) {
            justify-content: unset;
            margin: unset;
            border-radius: rem(12px);
        }
    }

    &__image {
        width: rem(148px);
        height: auto;
        margin-right: rem(12px);
        align-self: flex-start;
        margin-top: rem(24px);
        margin-left: rem(24px);
        display: none;

        @include respond-above(m) {
            display: block;
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        font-size: rem(16px);
        top: rem(23px);
        right: rem(23px);
        cursor: pointer;
        color: var(--color-txtAcc);
        font-weight: 600;
    }
    &__close:focus-visible {
        @extend %button-focused;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--colot-txt, 20%);
        padding: rem(24px) rem(56px) rem(8px) rem(16px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
            padding: rem(24px) rem(56px) rem(8px) rem(24px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--colot-txt, 20%);
        padding-left: rem(16px);
        padding-right: rem(56px);
        max-height: rem(344px);
        overflow: hidden;

        @include respond-above(l) {
            padding-left: rem(24px);
        }
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        padding: 0 rem(16px) rem(24px);
        margin-top: rem(40px);

        @include respond-above(m) {
            flex-flow: row-reverse;
            padding: 0 rem(24px) rem(24px);
            margin-top: rem(80px);
        }
    }

    &__ctaAction {
        @extend %buttonReset;
        @extend %button-primary3;
        text-align: center;

        @include respond-above(m) {
            margin-bottom: unset;
        }
    }

    &__ctaCancel {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
        margin-top: rem(24px);

        @include respond-above(l) {
            align-self: center;
            margin-right: rem(24px);
            margin-top: unset;
        }
    }
}
