@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt32-date-box {
    width: rem(112px);
    height: rem(85px);
    flex-shrink: 0;
    display: flex;
    flex-flow: column;
    background-color: var(--color-bg);
    border-radius: 12px;
    pointer-events: none;
    opacity: 0.4;
    margin-top: rem(6px);
    cursor: default;
    transition: all .3s ease-in-out;

    @include respond-above(m) {
        height: rem(95px);
        margin-top: rem(10px);
    }

    &:focus-visible {
        @extend %button-focused;
    }

    &--available,
    &--availablePartial {
        opacity: 1;
        pointer-events: all;
        cursor: pointer;
        background: #ffffff;
        border: solid 1px #efefef;
    }

    &--selected {
        border: solid 1px #fee04a;
        background-color: var(--color-bgYellowLight);
        width: rem(136px);
        height: rem(95px);
        margin-top: 0;

        @include respond-above(m) {
            height: rem(115px);
        }
    }

    &--selected.mt32-date-box--available &__availability,
    &--selected.mt32-date-box--availablePartial &__availability {
        background: unset;
    }

    &--selected &__availabilityDate {
        padding: rem(10px) rem(18px);

        @include respond-above(m) {
            padding: rem(18px) rem(22px);
        }
    }

    &__day {
        @extend %dmSansFontRegular;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        pointer-events: none;
    }

    &--selected &__day {
        @extend %dmSansFontRegular;
        font-size: rem(22px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        pointer-events: none;
    }

    &__weekday {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        padding-top: rem(4px);
        pointer-events: none;
    }

    &--selected &__weekday {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(16px);
        color: var(--color-gray);
        padding-top: rem(4px);
        pointer-events: none;

        @include respond-above(m) {
            padding-top: rem(8px);
        }
    }

    &--selected &__availability {
        padding: rem(8px) 0 0 rem(17px);
    }

    &__availability {
        background: #f4f4f4;
        border-radius: 12px 12px 0 0;
        padding: rem(4px) rem(14px) rem(2px);
    }

    &__exhausted,
    &__available,
    &__partiallyAvailable {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        order: 1;
        flex-grow: 1;
        position: relative;
        top: -3px;
    }


    &--available &__exhausted,
    &--availablePartial &__exhausted,
    &--available &__partiallyAvailable,
    &--availablePartial &__available{
        display: none;
    }

    &--available &__availability{
        background: #eff7e9;
    }

    &--availablePartial &__availability{
        background: #f5e6eb;
    }

    &__day,
    &__weekday,
    &__exhausted {
        user-select: none;
    }

    &__availabilityDate {
        padding: rem(8px) rem(15px);

        @include respond-above(m) {
            padding: rem(15px) rem(15px);
        }
    }
}
