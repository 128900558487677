@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt43-reservation-card {

    display: flex;
    align-items: flex-start;
    width: 100%;
    min-height: rem(120px);
    padding: rem(8px);
    align-items: center;
    box-shadow: 0 4px 8px -2px rgba(171, 147, 67, 0.15), 0 0 1px 0 rgba(171, 147, 67, 0.2);

    --text-dark: var(--color-txtAcc);
    --text-grey: #707070;
    --text-orange: var(--color-primary3);
    --text-blue: var(--color-info);
    --image-filter: grayscale(0);
    --details-decoration: underline;

    &--disabled{
        --text-dark: #707070;
        --text-orange: #999999;
        --text-blue: #999999;
        --image-filter: grayscale(1);
        --details-decoration: none;
    }

    @include respond-below(l) {
        min-height: rem(163px);
        padding-bottom: rem(16px);
    }

    &__left {
        flex-shrink: 0;
    }

    &__image{
        height: rem(104px);
        width: rem(126px);
        border-radius: rem(8px);
        object-fit: cover;
        filter: var(--image-filter);

        @include respond-below(l) {
            width: rem(107px);
            height: rem(139px);
        }  
    }

    &__right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: rem(8px) rem(8px)  rem(0px) rem(26px);
        height: 100%;
        justify-content: space-between;
        height: rem(104px);

        @include respond-below(l) {
            height: rem(139px);
        }  
    }

    &__top{
        display: flex;
        flex-direction: column;
    }

    &__up{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__title{
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--text-dark);
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    &__priceTop{
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--text-dark);
        text-align: right;
        display: block;
        white-space: nowrap;

        @include respond-below(l) {
            display: none;
        }
    }

    &__middle{
        display: flex;
        flex-direction: row;
        margin-top: rem(8px)
    }

    &__place{
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--text-grey);
    }

    &__bottom{
        display: flex;

        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__status{
       @extend %dmSansFontMedium;
       font-size: rem(14px);
       line-height: rem(14px);
       text-align: left;
       color: var(--text-orange);
    }

    &__details{
       @extend %dmSansFontMedium;
       font-size: rem(14px);
       line-height: rem(18px);
       text-align: right;
       color: var(--text-blue);
       text-decoration: var(--details-decoration);

       @include respond-below(l) {
            display: none;
        }
    }

    &__priceBottom{
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--text-dark);
        text-align: right;
        display: block;
        white-space: nowrap;

        @include respond-above(l) {
            display: none;
        }
    }
}