@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm10-confirm-login {
    @include modal(&, true);

    &__topBar {
        padding-bottom: rem(32px) !important;
    }

    &__error {
        margin-top: rem(10px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
    }

    &__wrapper .ps__rail-y {
        margin-top: rem(16px) !important;
    }

    &__form {
        flex: 1 0 auto;
    }

    &__emailWrapper {
        display: flex;
        flex-flow: column;
        margin-bottom: rem(32px);

        @include respond-above(l) {
            flex-flow: row;
        }
    }

    &__emailText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            width: calc(#{math.div(400, 592)} * 100%);
        }
    }

    &__email {
        font-weight: bold;
    }

    &__wrongEmail {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;

        @include respond-above(l) {
            width: calc(100% - #{math.div(400, 592)} * 100%);
            align-self: flex-end;
            text-align: right;
        }
    }

    &__formBottom {
        display: flex;
        flex-flow: column;
        margin-top: rem(32px);

        @include respond-above(l) {
            flex-flow: row;
            justify-content: space-between;
        }
    }

    &__formText {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
    }

    &__recoverPwdText {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);

        & > a {
            line-height: rem(16px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__recaptchaPolicy {
        @extend %font-label3;
        @extend %txt;
        display: inline-block;
        margin-top: rem(16px);
    }

    &__submit {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px);
        pointer-events: all;
        color: var(--color-txtDark);
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        text-align: center;
        margin-top: rem(24px);

        @include respond-above(l) {
            margin-top: unset;
            margin-left: rem(24px);
        }

        &--disabled {
            pointer-events: none;
            color: var(--color-txtButton);
            background-color: var(--color-bgButton);
        }

        &.invalid {
            background-color: var(--color-bgButton);
            color: var(--color-txtButton);
            pointer-events: none;
        }
    }
    &__submit:focus-visible {
        @extend %button-focused;
    }

    &__alternative {
        margin-top: rem(40px);

        & > hr {
            display: block;
            flex: 1 1 auto;
            color: lightness(--color-txt, 90%);
            border: 1px solid;
            margin-bottom: rem(24px);
        }
    }

    &__alternativeText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }

    &__social {
        margin-top: rem(24px);
    }

    &__socialButton {
        @extend %button-empty;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        color: var(--color-txtAcc);

        & > img {
            width: rem(24px);
        }

        & > span {
            flex: 1 1 auto;
        }

        &:not(:first-of-type) {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            padding-right: rem(32px);
            padding-left: rem(32px);
        }
    }
    &__socialButton:focus-visible {
        @extend %button-focused;
    }

    &__bottomText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        padding-bottom: rem(56px);

        & > a {
            color: var(--color-info);
            text-decoration: underline;
        }
    }
}
