@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mw16-associate-social {
    @include wizard(&);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__container {
        display: none;

        &--show {
            display: flex;
        }
    }
    &__container--ok {
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row;
            justify-content: flex-start;
        }
    }
    &__container--error {
        align-items: flex-start;
    }

    &__image {
        width: rem(148px);
        height: auto;
        margin-bottom: rem(24px);
        
        @include respond-above(l) {
            height: fit-content;
            margin-bottom: unset;
            margin-right: rem(12px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);
        margin-bottom: rem(76px);

        @include respond-above(l) {
            margin-top: rem(24px);
            margin-bottom: rem(56px);
        }
    }

    &__ctas {
        display: flex;
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row-reverse;
        }
    }

    &__associate {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtDark);
        background-color: var(--color-primary3);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        text-align: center;
        cursor: pointer;
    }

    &__cancel {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        display: inline-block;
        margin: rem(24px) 0;
        text-decoration: underline;
        text-align: center;
        cursor: pointer;

        @include respond-above(l) {
            margin: rem(16px) 0;
            margin-right: rem(24px);
        }
    }
}