@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ms3-footer {
    display: flex;
    flex-direction: column;
    background-color: var(--color-bgFooter);
    padding: rem(24px) 0;
    color: var(--color-txtFooter);

    &__container {
        @extend %container;
        display: flex;
        flex-direction: column;
    }

    &__links {
        display: flex;
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row;
            justify-content: space-between;
        }
    }

    &__link {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtFooter);

        @include respond-below(l) {
            &:not(:first-child) {
                margin-top: rem(24px);
            }
        }

        @include respond-above(l) {
            font-size: rem(14px);
            line-height: rem(16px);
        }
    }

    &__bottom {
        padding-top: rem(16px);
        border-top: 1px solid alpha(--color-borderDark, 0.2);
        margin-top: rem(24px);

        @include respond-above(l) {
            margin-top: rem(16px);
        }
    }

    &__copyright {
        @extend %font-copyright;
        color: var(--color-txtFooter);
    }
}
