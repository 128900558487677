@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt5-footer-links {
    position: relative;

    &__container {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease-out;

        @include respond-above(l) {
            max-height: rem(400px);
        }
    }

    &__title {
        margin-top: rem(4px);
        color: alpha(--color-txtDark, 0.6);
        @extend %font-label;
        margin-bottom: rem(4px);
    }

    &__closer {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        font-size: rem(24px);
        line-height: 1em;
        display: block;
        cursor: pointer;

        @include respond-above(l) {
            display: none;
        }
    }

    &__link {
        display: block;
        margin-top: rem(24px);
        font-weight: 500;
        @extend %font-label2;
    }

    &--open &__container {
        max-height: rem(400px);
    }
}
