@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm2-billing-address {
    @include modal(&, true);

    hr {
        border: 1px solid lightness(--color-txt, 90%);
        background-color: lightness(--color-txt, 90%);
        margin-top: rem(25px);
    }

    &__error {
        margin-top: rem(10px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__titleAdd,
    &__titleModify {
        display: none;

        &--show {
            display: inline;
        }
    }

    &__heading p {
        margin-bottom: rem(16px);
    }

    &__form {
        display: none;
        flex-direction: column;

        &--active {
            display: flex;
        }
    }

    &__formSection {
        margin-top: rem(26px);
        padding-bottom: rem(6px);

        .mt8-textfield,
        .mt16-select {
            margin-top: rem(24px);
        }
    }

    &__formSectionTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
        width: 100%;
    }

    &__addressName {
        margin-top: rem(26px);
    }

    /* on mobile, keep one form input per row */
    @include respond-above(l) {
        &__rowCittaProvincia,
        &__rowIndirizzoCap,
        &__rowCellulareTelefono,
        &__rowNomeCognome {
            display: flex;
            justify-content: space-between;
        }

        &__rowCittaProvincia > &__citta {
            flex: 2 0 0;
            margin-right: rem(6px);
        }
        &__rowCittaProvincia > &__provincia {
            flex: 1 0 0;
            margin-left: rem(6px);
        }
        &__rowIndirizzoCap > &__indirizzo {
            flex: 4 0 0;
            margin-right: rem(6px);
        }
        &__rowIndirizzoCap > &__cap {
            flex: 1 0 0;
            margin-left: rem(6px);
        }

        &__rowCellulareTelefono > &__cellulare {
            flex: 1 0 0;
            margin-right: rem(6px);
        }
        &__rowCellulareTelefono > &__telefono {
            flex: 1 0 0;
            margin-left: rem(6px);
        }

        &__rowNomeCognome > &__nome {
            flex: 1 0 0;
            margin-right: rem(6px);
        }
        &__rowNomeCognome > &__cognome {
            flex: 1 0 0;
            margin-left: rem(6px);
        }
        &__nazione {
            width: rem(193px);
        }
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        @extend %button-primary3;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        min-width: rem(105px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        text-align: center;
        pointer-events: none;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);
        order: 2;

        @include respond-above(m) {
            order: 3;
            align-self: flex-end;
        }

        &--active {
            pointer-events: all;
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }
    &__submit:focus-visible {
        @extend %button-focused;
    }

    &__remove {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        align-self: center;
        margin-right: rem(24px);
        text-decoration: underline;
        cursor: pointer;

        &--hidden {
            display: none;
        }
    }
    &__remove:focus-visible {
        @extend %button-focused;
    }

    &__otherData > &__formSectionTitle + p {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        margin-top: rem(14px);
    }
    &__otherData > &__formSectionTitle + p + &__emailPEC {
        margin-top: rem(13px);
    }
}
