@use 'sass:color';
@use './variables' as *;
@use './functions' as *;

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (min-width: $breakpoint-value) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }

        // If the breakpoint doesn't exist in the map.
    } @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
    // If both the lower and upper breakpoints exist in the map.
    @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
        // Get the lower and upper breakpoints.
        $lower-breakpoint: map-get($breakpoints, $lower);
        $upper-breakpoint: map-get($breakpoints, $upper);

        // Write the media query.
        @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
            @content;
        }

        // If one or both of the breakpoints don't exist.
    } @else {
        // If lower breakpoint is invalid.
        @if (map-has-key($breakpoints, $lower) == false) {
            // Log a warning.
            @warn 'Your lower breakpoint was invalid: #{$lower}.';
        }

        // If upper breakpoint is invalid.
        @if (map-has-key($breakpoints, $upper) == false) {
            // Log a warning.
            @warn 'Your upper breakpoint was invalid: #{$upper}.';
        }
    }
}

//
//  RESPOND PRINT
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-print() {}
@mixin respond-print() {
    // Write the media query.
    @media print {
        @content;
    }
}

//
//  BROWSER SPECIFIC
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Target css for IE10/11/Edge only
// USAGE: @include ie-only(&){}

@mixin ie-only($isEdge: false) {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        @content;
    }

    @if $isEdge == true {
        @supports (-ms-ime-align: auto) {
            @content;
        }
    }
}

// Target css for Firefox only
// USAGE: @include ff-only(&){}

@mixin ff-only() {
    @supports (-moz-appearance: none) {
        @content;
    }
}

//
//  HARDWARE SPECIFIC
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Exclude device with no hover funcionality (mobile devices)
// USAGE: @include mouse-only(){}

@mixin mouse-only($nomobile: true) {
    @if $nomobile == true {
        @media (hover: hover), (-ms-high-contrast: none) {
            @content;
        }
    } @else {
        @media (hover: none) {
            @content;
        }
    }
}

@mixin _raw($string) {
    $string: '*/#{$string}/*';
    /*#{$string}*/
}

//
//  COLORS
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
    #{$color}: unquote('hsl(#{$hue}, #{$saturation}, #{$lightness})');
    #{$color}-h: #{$hue};
    #{$color}-s: #{$saturation};
    #{$color}-l: #{$lightness};
}

@mixin defineColorHex($color, $hex) {
    #{$color}: unquote('hsl(#{strip-unit(color.hue($hex))}, #{color.saturation($hex)}, #{color.lightness($hex)})');
    #{$color}-h: #{strip-unit(color.hue($hex))};
    #{$color}-s: #{color.saturation($hex)};
    #{$color}-l: #{color.lightness($hex)};
}

//
//  PERFECT SCROLLBAR
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin adjustPerfectScrollbar {
    .ps__rail-y {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: rem(12px);
    }
    .ps__rail-x {
        display: none;
    }
    .ps__thumb-y {
        background-color: black;
    }
}

//
// MODALS
//----------------------------------------------------

@mixin modal($componentName, $bars: false) {
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;

    @if $bars {
        #{$componentName}__topBar {
            transition: box-shadow 0.2s ease-in-out;
            padding-top: rem(52px);
            padding-left: rem(16px);
            padding-right: rem(16px);
            padding-bottom: rem(16px);

            @include respond-above(m) {
                padding-top: rem(80px);
                padding-left: rem(72px);
                padding-right: rem(72px);
            }

            &--shadow {
                box-shadow: 0 8px 16px -4px alpha(--color-boxShadow1, 0.15), 0 0 1px 0 alpha(--color-boxShadow1, 0.2);
            }
        }

        #{$componentName}__bottomBar {
            transition: box-shadow 0.3s ease-in-out;
            padding-top: rem(16px);
            padding-bottom: rem(16px);
            padding-left: rem(16px);
            padding-right: rem(16px);

            @include respond-above(m) {
                padding-right: rem(72px);
                padding-left: rem(72px);
            }

            &--shadow {
                box-shadow: 0 -2px 16px alpha(--color-boxShadow1, 0.15);
            }
        }
    }

    #{$componentName}__wrapper {
        position: relative;
        overflow-y: hidden;
        height: 100%;
        padding-left: rem(16px);
        padding-right: rem(16px);

        @if not $bars {
            padding-top: rem(80px);
            padding-bottom: rem(30px);
        }

        @include respond-above(m) {
            padding-left: rem(72px);
            padding-right: rem(72px);
        }

        @include adjustPerfectScrollbar();

        .ps__rail-y {
            margin-top: rem(32px);
        }

        @include respond-below(m) {
            padding-bottom: 250px;
        }
    }

    #{$componentName}__close {
        @extend %buttonReset;
        position: absolute;
        right: rem(16px);
        top: rem(16px);
        font-size: rem(24px);

        @include respond-above(m) {
            top: rem(40px);
            right: rem(72px);
        }
    }
    #{$componentName}__close:focus-visible {
        @extend %button-focused;
    }

    #{$componentName}__title {
        @extend %font-heading3;
        font-weight: bold;
        color: var(--color-txtAcc);

        &--back::before {
            content: $ec-icon-chevron-left;
            @extend %ecIcon;
            cursor: pointer;
            vertical-align: middle;
            margin-right: rem(32px);
        }
    }

    #{$componentName}__stepsIcons {
        display: none;
        position: absolute;
        margin-left: rem(24px);
        margin-top: rem(32px);

        @include respond-above(m) {
            margin-left: rem(72px);
            margin-top: rem(48px);
        }

        &--show {
            display: flex;
        }

        & > .step {
            @extend %buttonReset;
            display: inline-block;
            width: rem(24px);
            height: rem(8px);
            margin-right: rem(8px);
            border-radius: 100px;
            pointer-events: none;
            z-index: 2;

            &.future {
                border: 2px solid var(--color-primary2);
            }

            &.past {
                background-color: alpha(--color-primary2, 0.2);
                pointer-events: all;
            }

            &.active {
                background-color: var(--color-primary2);
            }
        }
    }
}

@mixin wizard($componentName) {
    position: relative;
    display: flex;
    flex-flow: column;
    @extend %container;
    /*min-height: calc(100vh - #{$nav-height});
    @include respond-above(l) {
        min-height: calc(100vh - #{$nav-height-desk});
    }*/

    max-width: rem(704px);
    padding-top: rem(40px);
    margin-top: rem(32px);
    padding-bottom: rem(40px);

    #{$componentName}__title {
        @extend %font-heading2;
        font-weight: bold;
        color: var(--color-txtAcc);

        & > button:first-child {
            @extend %buttonReset;
            display: none;
            visibility: hidden;
            vertical-align: middle;
            margin-right: rem(32px);
        }

        &--back > button:first-child {
            display: inline;
            visibility: visible;
        }
    }

    #{$componentName}__stepsIcons {
        display: none;
        position: absolute;
        margin-top: rem(-24px);

        &--show {
            display: flex;
        }

        & > .step {
            @extend %buttonReset;
            display: inline-block;
            width: rem(24px);
            height: rem(8px);
            margin-right: rem(8px);
            border-radius: 100px;
            pointer-events: none;
            z-index: 2;

            &.future {
                border: 2px solid var(--color-primary2);
            }

            &.past {
                background-color: alpha(--color-primary2, 0.2);
                pointer-events: all;
            }

            &.active {
                background-color: var(--color-primary2);
            }
        }
    }
}

//
//  ACTION POPUPS
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin actionPopup() {
    max-width: rem(656px);

    @include respond-above(l) {
        width: rem(656px);
    }

    &__content {
        position: relative;
        display: flex;
        flex-flow: column;
        background-color: var(--color-bg);
        border-radius: rem(12px);
        overflow: hidden;
        margin: 0 rem(5px);

        @include respond-above(l) {
            justify-content: unset;
            margin: unset;
            border-radius: rem(12px);
        }
    }

    &__close {
        @extend %buttonReset;
        position: absolute;
        font-size: rem(16px);
        top: rem(23px);
        right: rem(23px);
        cursor: pointer;
        color: var(--color-txtAcc);
        font-weight: 600;
    }
    &__close:focus-visible {
        @extend %button-focused;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--colot-txt, 20%);
        padding: rem(24px) rem(56px) rem(8px) rem(16px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
            padding: rem(24px) rem(56px) rem(8px) rem(24px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--colot-txt, 20%);
        padding-left: rem(16px);
        padding-right: rem(56px);
        max-height: rem(344px);
        overflow: hidden;

        @include respond-above(l) {
            padding-left: rem(24px);
        }
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        padding: 0 rem(16px) rem(24px);
        margin-top: rem(40px);

        @include respond-above(l) {
            flex-flow: row-reverse;
            padding: 0 rem(24px) rem(24px);
            margin-top: rem(80px);
        }
    }

    &__ctaAction {
        /*@extend %buttonReset;*/
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        color: var(--color-white);
        text-align: center;
        cursor: pointer;
        display: inline-block;
        margin-bottom: rem(24px);

        @include respond-above(l) {
            margin-bottom: unset;
        }
    }

    &__ctaCancel {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        text-align: center;
        text-decoration: underline;
        cursor: pointer;

        @include respond-above(l) {
            align-self: center;
            margin-right: rem(24px);
        }
    }

    &__ctaAction:focus-visible,
    &__ctaCancel:focus-visible {
        @extend %button-focused;
    }
}

//
//  ORDER STATES
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin orderStateColors() {
    &--CONFIRMED {
        /* blue */
        background-color: var(--color-secondary2);
        color: lightness(--color-info, 35%);
    }
    &--IN_PREPARATION,
    &--READY_FOR_DELIVERY,
    &--READY_FOR_PREPARATION {
        /* grey */
        background-color: alpha(--color-darkCharcoal, 0.05);
        color: var(--color-gray);
    }
    &--OUT_FOR_DELIVERY,
    &--OUT_FOR_LOCKER,
    &--READY_FOR_COLLECTION,
    &--CUSTOMER_DELAYED {
        /* yellow */
        background-color: var(--color-secondary4);
        color: lightness(--color-primary2, 45%);
    }
    &--DELIVERED_PICKED {
        /* green */
        background-color: var(--color-secondary6);
        color: lightness(--color-secondary3, 35%);
    }
    &--PICKER_DELAYED,
    &--DELIVERY_DELAYED,
    &--DELIVERY_FAILED,
    &--NOT_DELIVERED,
    &--CANCELLED_BY_CP,
    &--CANCELLED_BY_OMS,
    &--CANCELLED_BY_USER,
    &--CANCELLED_BY_CUSTOMER_CARE {
        /* red */
        background-color: var(--color-secondary5);
        color: var(--color-primary);
    }
}

//
//  CC REQUEST BADGES
//––––––––––––––––––––––––––––––––––––––––––––––––––
@mixin ccRequestBadges {
    & > span:last-child {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        padding-top: rem(3.2px);
        padding-bottom: rem(3.2px);
        padding-right: rem(16px);
        padding-left: rem(15px);
        border-radius: rem(12px);
        white-space: nowrap;

        /* badge colors */
        &[data-status='NEW'] {
            color: var(--color-info);
            background-color: alpha(--color-info, 0.2);
        }
        &[data-status='IN_GESTIONE'] {
            color: #1b2b4b;
            background-color: #fcf0ba;
        }
        &[data-status='IN_SOSPESO'] {
            color: var(--color-negativeInfo);
            background-color: alpha(--color-negativeInfo, 0.2);
        }
        &[data-status='ON_HOLD'] {
            color: var(--color-negativeInfo);
            background-color: alpha(--color-negativeInfo, 0.2);
        }
        &[data-status='CLOSED'] {
            color: #11a128;
            background-color: alpha(--color-positiveInfo, 0.2);
        }

        &::before {
            content: '●';
            margin-right: rem(6px);
        }
    }
}
