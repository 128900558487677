@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt35-content-card {
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 8px -2px alpha(--color-boxShadow, 0.15), 0 0 1px 0 alpha(--color-boxShadow, 0.2);
    width: 100%;
    position: relative;

    /* margins and padding from parent element */

    @include respond-above(m) {
        flex-shrink: 0;
        width: 224px;
    }

    &__bookmark {
        @extend %buttonReset;
        position: absolute;
        top: rem(16px);
        right: rem(16px);
        width: rem(40px);
        height: rem(40px);
        background-color: var(--color-bg);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        
        > span {
            display: block;
            width: rem(16px);
            height: rem(16px);
            background-image: url('#{$resourcesFolder}/myconad/icons/bookmark-on.svg');
            background-repeat: no-repeat;
            background-size: rem(16px);
            margin: auto;
        }
    }
    &__bookmark:hover > span {
        opacity: 0.6;
    }
    &__bookmark:focus-visible {
        @extend %button-focused;
    }

    &__image {
        object-fit: cover;
        width: 100%;
        height: 184px;

        @include respond-above(m) {
            width: 224px;
            height: 184px;
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        flex-grow: 1;
        padding: rem(16px) rem(16px) 0;
        padding-bottom: rem(28px);
        position: relative;

        @include respond-above(m) {
            padding-bottom: rem(8px);
        }
    }

    &__badge {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-txtAcc);
        padding: rem(5px) rem(16px);
        background-color: var(--color-bg);
        border-radius: 12px;
        position: absolute;
        top: rem(-32px);
        left: rem(16px);
        user-select: none;
    }

    &__link {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        padding: 0 rem(16px) rem(16.3px);

        @include respond-above(m) {
            padding: 0 rem(16px) rem(16px);
        }
    }
}