@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw7-select-registration {
    @include wizard(&);
    display: flex;
    flex-direction: column;

    &__title {
        align-self: stretch;
    }

    &__subtitle {
        @extend %font-body2;
        color: lightness(--color-black, 60%);
        margin-top: rem(15px);
        padding-bottom: rem(16px);
        align-self: stretch;
    }

    &__alternative {
        margin-top: rem(40px);
        display: flex;
        align-items: center;

        & > hr {
            display: block;
            flex: 1 1 auto;
            color: lightness(--color-txt, 90%);
            border: 1px solid;
        }
    }

    &__alternativeText {
        margin-left: rem(16px);
        margin-right: rem(16px);
        color: var(--color-gray);
        font-weight: bold;
    }

    &__social {
        margin-top: rem(40px);
    }

    &__socialButton {
        @extend %button-empty;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: center;
        color: var(--color-txtAcc);

        & > img {
            width: rem(24px);
        }

        & > span {
            flex: 1 1 auto;
        }

        &:not(:first-of-type) {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            padding-right: rem(32px);
            padding-left: rem(32px);
        }
    }

    &__continue {
        @extend %buttonReset;
        @extend %button-primary3;
        margin-top: rem(16px);
        text-align: center;
        align-self: flex-end;
    }
}
