@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf5-orders {
    $root: &;
    margin-bottom: rem(73px);

    @include respond-below(l) {
        margin-top: rem(27px);
    }

    &__intro {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        display: block;
        max-width: 80ch;

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }
    &__orderStatesInfo {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
        margin-bottom: rem(20px);

        @include respond-above(l) {
            margin-top: rem(16px);
            margin-bottom: rem(44px);
        }
    }
    &__orderStatesCta {
        @extend %buttonReset;
        color: var(--color-info);
        text-decoration: underline;
        font-weight: 500;
    }
    &__orderStatesCta:focus-visible {
        @extend %button-focused;
    }

    &__alert {
        margin-bottom: rem(25px);

        @include respond-above(l) {
            margin-top: rem(-29px);
        }
    }

    &__tabs {
        display: flex;
        justify-content: space-around;

        @include respond-above(l) {
            justify-content: unset;
        }
    }

    &__tabLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtTab);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            padding-bottom: rem(8px);
        }
    }

    /* buttons */
    &__homeDeliveryTab,
    &__orderCollectTab {
        @extend %buttonReset;
        flex: 1 0 0;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-txtTab);
        border-radius: rem(12px);
        padding: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            border: unset;
            border-radius: unset;
            flex: unset;
            padding: unset;
        }

        /* mobile/desktop div */
        & > div {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }

        /* icons */
        span {
            font-size: rem(24px);
            margin-bottom: rem(8px);

            @include respond-above(l) {
                margin-bottom: unset;
                margin-right: rem(8px);
                align-self: flex-start;
            }
        }
    }
    &__homeDeliveryTab:focus-visible,
    &__orderCollectTab:focus-visible {
        @extend %button-focused;
    }
    &__homeDeliveryTab {
        margin-right: rem(3px);

        @include respond-above(l) {
            margin-right: rem(16px);
        }

        & > span:first-child {
            color: var(--color-txtTab);
        }
    }
    &__orderCollectTab {
        margin-left: rem(3px);

        @include respond-above(l) {
            margin-left: rem(16px);
        }

        & > span:first-child {
            color: var(--color-txtTab);
        }
    }
    &__activeTab {
        border-color: var(--color-blackAcc);

        & > span:first-child,
        #{$root}__tabLabel {
            color: var(--color-blackAcc);
        }

        #{$root}__tabDecoration {
            display: flex;
            width: rem(60px);

            & > div {
                width: rem(32px);
            }
        }
    }

    &__tabDecoration {
        position: absolute;
        bottom: rem(-2.2px);
        width: rem(20px);
        height: rem(2.5px);
        background-color: var(--color-bg);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: none;
            height: rem(4px);
            bottom: rem(-3px);
        }

        & > div {
            width: rem(3px);
            height: inherit;
            background-color: var(--color-primary2);
            border-radius: rem(4px);
        }
    }

    &__homeDeliveryContent,
    &__orderCollectContent {
        & > div:first-child {
            display: flex;
            flex-flow: column;
            width: 100%;

            @include respond-above(l) {
                & > div:first-of-type {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    /* filters */
    &__filter {
        margin-top: rem(50px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
    }

    &__countOrders {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        align-self: flex-end;
        margin-top: rem(14px);
    }

    hr {
        border: 0.5px solid lightness(--color-txt, 90%);
        background-color: lightness(--color-txt, 90%);
        margin-top: rem(13px);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            margin-top: rem(16px);
        }
    }

    &__loadMore {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(124px);
        height: rem(40px);
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        cursor: pointer;
        text-decoration: underline;
        margin-top: rem(50px);

        &--hidden {
            display: none;
        }
    }

    &__noOrders {
        display: none;
        padding-top: rem(24px);

        &--show {
            display: block;
        }
    }

    &__noOrdersTitle {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        padding-bottom: rem(8px);
    }

    &__noOrdersText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-gray);
    }
}
