@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ml2-navlayout {
    @extend %container;
    flex-grow: 1;

    @include respond-above(l) {
        display: flex;
    }

    flex-direction: row;
    padding-top: 2.5rem;

    &--main {
        flex: 1 0 auto;
    }

    &__main {
        flex: 1 1 auto;
        min-height: rem(500px);

        @include respond-above(l) {
            max-width: calc(100% - 15rem);
        }
    }

    &__selected {
        @extend %font-heading2;
        font-weight: bold;
        margin-bottom: 2px;
        flex: 1 0 auto;

        &--hidden {
            display: none !important;
        }

        margin-right: rem(60px);
    }

    &__selected + &__item {
        margin-top: rem(32px);
    }

    &__breadcrumb + &__item {
        margin-top: rem(32px);
    }
}
