@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt7-message {
    padding-top: rem(24px);
    padding-bottom: rem(16px);
    border-bottom: 1px solid alpha(--color-black, 0.1);

    &__unread {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(69px);
        height: rem(22px);
        background-color: var(--color-warning);
        border-radius: rem(16px);
        color: var(--color-warningText);
        margin-bottom: rem(19px);
        margin-top: rem(-9px);

        @include respond-above(l) {
            margin-bottom: rem(8px);
            margin-top: unset;
        }
    }

    &__senderHeading {
        @include respond-above(l) {
            display: flex;
        }
    }
    &__sender {
        display: flex;
        align-items: center;

        @include respond-above(l) {
            flex: 0 0 auto;

            &::after {
                content: '-';
                padding-left: 5px;
                padding-right: 5px;
                font-weight: bold;
            }
        }
    }
    &__senderIcon {
        padding-right: rem(7.7px);
    }
    &__senderName {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }
    &__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: rem(4px);

        @include respond-above(l) {
            flex: 1 1 auto;
            margin-top: unset;
        }
    }
    &__title {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        flex-basis: 0;
        flex-grow: 4;
        max-width: rem(259px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include respond-above(l) {
            max-width: unset;
        }
    }
    &__date {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-gray);
        flex-basis: 0;
        flex-grow: 1;
        text-align: end;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        padding-top: rem(8px);
        padding-bottom: rem(16px);
    }

    &__readMore {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
    }
    &__readMore:focus-visible {
        @extend %button-focused;
    }
}