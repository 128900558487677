@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt37-widget {
    
    border-radius: rem(12px);
    border: solid 1px lightness(--color-border, 90%);
    background-color: var(--color-white);
    box-shadow: 0 4px 8px -2px alpha(--color-border, 0.15), 0 0 1px 0 alpha(--color-border, 0.2);
    min-height: rem(401px);
    @include respond-above(m) {
        min-height: rem(126px);
    }

    &__notValid {
        display: none;
    }

    &__container {
        margin-left: rem(24px);
        margin-top: rem(11px);
        margin-bottom: rem(14.4px);
        margin-right: rem(16px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include respond-below(m) {
            flex-direction: column;
            justify-content: center;
            margin: rem(26px) rem(32px);
            align-items: center;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        width: rem(105px);
        height: rem(100.6px);
        margin-right: rem(29px);
    }

    &__texts {
        margin-top: rem(3px);
        min-width: rem(250px);

        @include respond-below(m) {
            margin-top: rem(19.2px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: rem(38px);
        }

        @include respond-above(m) {
            margin-right: rem(21px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        margin-bottom: rem(6px);
        display: flex;
        flex-direction: row;
        margin-top: rem(3px);

        @include respond-below(m) {
            font-size: rem(16px);
            margin-bottom: rem(8px);
            display: flex;
            justify-content: center;
        }
    }

    &__text, &__rightText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        text-align: center;
    }

    &__text {
        @include respond-above(m) {
            text-align: left;
        }
    }

    &__rightText {
        margin-top: rem(9px);
        max-width: rem(173px);
    }

    &__icon {
        width: rem(22px);
        height: rem(21px);
        margin-left: rem(4.5px);
    }

    &__rightCta {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: lightness(--color-txt, 20%);
        border: 2px solid var(--color-primary3);
        border-radius: 12px;
        text-align: center;
        width: 100%;
        min-height: rem(40px);
        padding: rem(13px) rem(24px);
        min-width: rem(220px);

        @include respond-below(m) {
            margin-top: rem(14px);
            padding: rem(13px) rem(59px);
        }
    }

    &__sep {
        margin-top: rem(9px);
        margin-bottom: rem(12px);
        width: rem(165px);
        height: rem(1px);
        border-radius: rem(1px);
        background-color: var(--color-borderDivider);
        border: none;
        align-self: center;

        @include respond-below(m) {
            display: none;
        }
    }

    &__credits {
        @extend %robotoFontMedium;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-primary3);
        margin-right: rem(8px);
    }
}
