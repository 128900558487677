@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes exitLeft {
    from {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateX(rem(-40px));
    }
}

@keyframes enterRight {
    from {
        opacity: 0;
        transform: translateX(rem(40px));
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeLeft {
    from {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(rem(-40px));
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0%);
    }
}

@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}

@keyframes fadeOutAndDisappear {
    0% {opacity: 1;}
    100% {opacity: 0;}
} 

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1}
} 

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
