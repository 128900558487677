@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt14-coupon-instructions {
    @include respond-above(l) {
        margin-top: rem(30px);
    }

    &__bar {
        margin: 10px 0;

        @include respond-above(l) {
            display: none;
        }
    }

    &--fade {
        /* inherited opacity: 0.5; */
        filter: grayscale(1);

        .mt14-coupon-instructions__onlineShoppingCode {
            pointer-events: none;
        }
    }

    hr {
        margin: 10px 0;
    }

    &__title {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        padding-bottom: rem(5px);

        @include respond-above(l) {
            padding-bottom: rem(22px);
        }
    }

    &__types {
        @include respond-above(l) {
            display: flex;
        }
    }

    &__inStore,
    &__onlineShopping,
    &__travelCoupon {
        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-above(l) {
            flex-direction: row;
            align-items: flex-start;
        }
    }
    &__inStore + &__onlineShopping + &__travelCoupon {
        padding-top: rem(16px);

        @include respond-above(l) {
            padding-top: unset;
            margin-left: rem(28px);
        }
    }

    &__inStoreContent,
    &__onlineShoppingContent,
    &__travelCouponContent {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        align-items: center;
        text-align: center;

        @include respond-above(l) {
            text-align: left;
            align-items: flex-start;
            padding-left: rem(6px);
        }
    }

    &__inStoreIcon,
    &__onlineShoppingIcon,
    &__travelCouponIcon {
        width: rem(80px);
        height: rem(80px);
    }
    &__inStoreIcon {
        background-image: url('#{$resourcesFolder}/myconad/icons/store.svg');
    }
    &__onlineShoppingIcon {
        background-image: url('#{$resourcesFolder}/myconad/icons/cart-80.svg');
    }
    &__travelCouponIcon {
        background-image: url('#{$resourcesFolder}/myconad/icons/plane.svg');
    }

    &__inStoreTitle,
    &__onlineShoppingTitle,
    &__travelCouponTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        padding-bottom: rem(1px);
    }

    &__inStoreText,
    &__onlineShoppingText,
    &__travelCouponText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: rgba(0, 0, 0, 0.4);
    }

    &__instructionsOnlineShoppingWrapper,
    &__instructionsTravelWrapper {
        position: relative;
    }

    &__instructionsOnlineShoppingCode,
    &__instructionsTravelCode {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        width: fit-content;
        border-radius: rem(12px);
        background-color: var(--color-secondary2);
        color: var(--color-info);
        padding: rem(5px) rem(16px) rem(5px) rem(16px);
        cursor: pointer;
        margin-top: rem(14px);

        /* icon */
        & > span:last-of-type {
            color: var(--color-info);
        }
    }

    &__instructionsTooltip {
        opacity: 0;
        position: absolute;
        bottom: calc(100% - 7px);
        right: -25%;
        padding: 1px 8px 3px;
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0 0 10px -2px alpha(--color-black, 0.3);
        pointer-events: none;
        transition: opacity 0.7s ease-in-out;

        @include respond-above(l) {
            left: 0;
            right: unset;
        }
    }

    &__instructionsTooltipText {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
        color: var(--color-primary3);
        white-space: nowrap;
    }

    &--showTooltip &__instructionsTooltip {
        opacity: 1;
    }
}
