@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt15-address {
    border-top: 1px solid alpha(--color-txt, 0.1);
    padding-top: rem(32px);
    padding-bottom: rem(32px);

    &:first-child {
        border-top: unset;
        padding-top: unset;
        margin-top: rem(32px);
    }
    &:last-child {
        padding-bottom: unset;
    }
    @include respond-above(l) {
        padding-bottom: rem(23.2px);
    }

    &__favorite {
        display: flex;
        flex-flow: row-reverse;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    &__favoriteIcon {
        @extend %buttonReset;
    }
    &__favoriteIcon:focus-visible {
        @extend %button-focused;
    }
    &__favoriteLabel {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-primary3);
        background-color: alpha(--color-primary3, 0.1);
        border-radius: 12px;
        padding: rem(5px) rem(16px);
        display: none;
    }
    /* show favorite label */
    &__favorite > .ec-icon-like-on + &__favoriteLabel {
        display: block;
    }
    /* adjust badge margin bottom on favorites */
    &__favorite > .ec-icon-like-on,
    &__favorite > .ec-icon-like-on + &__favoriteLabel {
        margin-bottom: rem(16.3px);
        @include respond-above(l) {
            margin-bottom: rem(17.8px);
        }
    }
    /* adjust icon position on not favorites */
    &__favorite > .ec-icon-like-off {
        position: absolute;
        top: 100%;
        right: 0px;
    }

    &__favoriteIcon {
        font-size: rem(24px);
        color: var(--color-primary3);
        cursor: pointer;
    }
    &__favoriteIcon:hover {
        color: alpha(--color-primary3, 0.8);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        padding-top: rem(3px);
        padding-bottom: rem(16px);

        @include respond-above(l) {
            padding-bottom: rem(5px);
        }
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        padding-top: rem(4px);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &__ctas {
        display: flex;
        padding-top: rem(24px);
    }

    &__ctaModify {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
    }
    &__ctaModify:focus-visible {
        @extend %button-focused;
    }
}