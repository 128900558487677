@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt36-instructions {

    &__heading {
        background-color: alpha(--color-secondary1, 0.4);
        padding: rem(24px) rem(16px);
        border-radius: 16px;

        @include respond-above(l) {
            padding: rem(16px);
        }

        &--open {
            border-radius: 16px 16px 0 0;
        }
    }

    &__title {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;

        @include respond-above(l) {
            line-height: rem(28px);
        }

        > span:first-of-type {
            font-size: rem(24px);
            height: fit-content;
            transition: transform .4s ease-in-out;
        }
    }
    &__heading--open > &__title > span:first-of-type {
        transform: rotateX(180deg);
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        margin-top: rem(8px);
    }

    &__content {
        display: flex;
        flex-flow: column;
        background-color: var(--color-secondary1);
        padding-top: 0;
        padding-bottom: 0;
        padding-left: rem(16px);
        padding-right: rem(16px);
        border-radius: 0 0 16px 16px;
        overflow: hidden;
        visibility: hidden;
        pointer-events: none;
        max-height: 0;
        transition: max-height .4s ease-in-out, 
                    visibility .4s ease-in-out,
                    padding-top .4s ease-in-out,
                    padding-bottom .4s ease-in-out;

        @include respond-above(l) {
            flex-flow: row wrap;
            padding-left: rem(64px);
            padding-right: rem(64px);
        }
    }
    &__heading--open + &__content {
        visibility: visible;
        pointer-events: all;
        padding-top: rem(32px);
        padding-bottom: rem(32px);
    }

    &__step {
        display: flex;
        flex-flow: row;
        transition: all .4s ease-in-out;

        @include respond-above(l) {
            flex-flow: column;
            max-width: calc(#{math.div(100,3)}*1% - #{math.div(128,3)}*1px);
        }
    }
    &__step:not(:first-child) {
        margin-top: rem(32px);

        @include respond-above(l) {
            margin-top: unset;
            margin-left: rem(64px);
        }
    }
    &__step:nth-child(3n + 1) {
        @include respond-above(l) {
            margin-left: 0;
        }
    }

    &__step > div:first-of-type {
        margin-left: rem(17.5px);

        @include respond-above(l) {
            margin-left: unset;
        }
    }

    &__stepImage {
        width: 80px;
        height: 92.5px;
        object-fit: contain;

        @include respond-above(l) {
            align-self: center;
        }
    }

    &__stepTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            margin-top: rem(8px);
            text-align: center;
        }
    }

    &__stepText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        margin-top: rem(8px);

        @include respond-above(l) {
            text-align: center;
        }
    }
}