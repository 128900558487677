@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt41-favorite-card {
    display: flex;
    flex-flow: column;
    justify-content: stretch;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 4px 8px -2px alpha(--color-boxShadow, 0.15), 0 0 1px 0 alpha(--color-boxShadow, 0.2);
    min-width: rem(221px);
    width: 100%;
    cursor: pointer;
    flex: 1 1 auto;

    --text-dark: var(--color-txtAcc);
    --text-grey: #707070;
    --image-filter: grayscale(0);
    --image-opacity: 1;

    &--disabled{
        --text-dark: var(--text-grey);
        --image-filter: grayscale(1);
        --image-opacity: 0.5;
    }

    --color-heart: #df5d1b;

    &--fadeOut{
        -webkit-animation-duration: 0.6s;animation-duration: 0.6s;
        -webkit-animation-fill-mode: both;animation-fill-mode: both;
        -webkit-animation-name: fadeOutAndDisappear;
        animation-name: fadeOutAndDisappear;
    }

    @include respond-above(m) {
        max-width: 224px;
    }

    &__heart {
        @extend %buttonReset;
        position: absolute;
        top: rem(16px);
        right: rem(17px);
        width: rem(32px);
        height: rem(32px);
        background-color: var(--color-bg);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        
        > span {
            display: block;
            width: rem(16px);
            height: rem(16px);
            color: var(--color-heart);
            margin: auto;
        }
    }

    &__heart:hover > span {
        opacity: 0.6;
    }
    &__heart:focus-visible {
        @extend %button-focused;
    }

    &__destination{
        position: absolute;
        top: rem(14px);
        left: rem(13px);
        width: rem(130px);
        display: flex;
        color: var(--color-white);

        > span {
            display: block;
            color: var(--color-white);
        }

        > h3 {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            text-align: left;
            margin-left: rem(2.7px);
        }

    }

    &__top{
        position: relative;
        height: rem(142px);
    }

    &__backdrop {
        position: absolute;
        height: rem(142px);
        width: 100%;
        background-color: alpha(--color-black, 0.3);
    }

    &__image {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: rem(142px);
        background-color: alpha(--color-bgDark, 0.4);
        z-index: setZ(negative);

        filter: var(--image-filter);
        opacity: var(--image-opacity);

        @include respond-above(m) {
            height: rem(142px);
        }
    }

    &__insieme{
        width: rem(73.6px);
        height: rem(47.1px);
        bottom: rem(-3.5px);
        left: rem(44.4px);
        position: absolute;
        border-radius: rem(2px);
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme.png');
        background-repeat: no-repeat;
        background-size: contain;

        @include respond-above(m) {
            width: rem(46.4px);
            height: rem(29.7px);
            bottom: rem(-2.6px);
            left: rem(31.6px);
        }

        &--bigger {
            width: rem(100px);
            left: rem(30px);
            bottom: rem(-3px);
        }
    }

    &__insiemePiu{
        width: rem(73.6px);
        height: rem(47.1px);
        bottom: rem(22.5px);
        left: rem(18px);
        position: absolute;
        border-radius: rem(2px);
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-piu.jpg');
        background-repeat: no-repeat;
        background-size: contain;
        
        @include respond-above(m) {
            width: rem(46.4px);
            height: rem(29.7px);
            bottom: rem(13.6px);
            left: rem(15px);
        }

        &--bigger {
            width: rem(100px);
            left: rem(30px);
            bottom: rem(-3px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--text-dark);
        flex-grow: 1;
        padding: rem(16px) rem(16px) 0;
        margin-bottom: rem(17px);

        @include respond-above(m) {
            margin-bottom: rem(18px);
        }
    }

    &__cards{

        &--hidden{
            display: none
        }
    }

    &__badge {
        @extend %dmSansFontBold;
        font-size: rem(10px);
        line-height: rem(12px);
        color: var(--color-txtAcc);
        padding: rem(5px) rem(16px);
        padding-left: rem(45px);
        background-color: var(--color-bg);
        border-radius: 12px;
        position: absolute;
        bottom: rem(3.4px);
        left: rem(45px);
        user-select: none;

        &--vantaggi{
          background-color: var(--color-primary2);
          font-size: rem(12px);
          line-height: rem(12px); 
          padding: rem(5px) rem(16px);
          padding-left: rem(45px);
          border-radius: 12px;
          position: absolute;
          bottom: rem(3.4px);
          left: rem(45px); 

          @include respond-below(m) {
            padding-left: rem(90px);
          }
        }
    }

    &__duration {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
        padding: 0 rem(16px) rem(16.3px);

        > span {
            display: block;
            color: var(--text-dark);
        }

        > h3 {
            @extend %dmSansFontRegular;
            margin-left: rem(5.8px);
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--text-dark);
        }
    }
}