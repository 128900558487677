@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm16-donate-bollini {
    @include modal(&, true);

    &__topBar {
        padding-bottom: rem(32px) !important;
    }

    &__error {
        margin-top: rem(10px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-bottom: 0 !important;
    }

    &__wrapper .ps__rail-y {
        margin-top: rem(16px) !important;
    }

    &__form {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            width: calc(#{math.div(400, 592)} * 100%);
        }
    }

    &__formBottom {
        margin-top: auto;
        margin-bottom: rem(16px);
    }
}
