@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt21-order {
    $root: &;
    margin-top: rem(16px);

    &__wrapper {
        border-radius: 12px;
        box-shadow: 0 4px 8px -2px alpha(--color-boxShadow1, 0.15), 0 0 1px 0 alpha(--color-boxShadow1, 0.2);
    }

    /* top */

    &__top {
        position: relative;
        padding: rem(24px) rem(16.2px) rem(10.4px) rem(15.8px);
        background-color: var(--colog-bg);

        @include respond-above(m) {
            padding: rem(24px) rem(24px) rem(16px) rem(24px);
        }
    }

    &__action {
        position: absolute;
        top: rem(24px);
        right: rem(16.2px);
        display: flex;
        flex-flow: column;

        @include respond-above(m) {
            right: rem(24px);
            display: block;
        }
    }
    /* action icons */
    &__action > button > span {
        position: relative;
        bottom: -1px;
        font-size: rem(16px);
        margin-left: 4px;
    }

    &__orderAgain,
    &__manageOrder {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        cursor: pointer;
    }
    &__orderAgain:focus-visible,
    &__manageOrder:focus-visible {
        @extend %button-focused;
    }

    &__orderAgain ~ &__manageOrder {
        margin-top: rem(8px);
        margin-left: unset;

        @include respond-above(m) {
            margin-top: unset;
            margin-left: rem(16px);
        }
    }
    
    &__manageOrder > span {
        display: inline-block;
        transition: transform .3s ease-in-out;
    }
    &__manageOrder--open > span {
        transform: rotateX(180deg);
    }
    &__manageOrderDropdown {
        visibility: hidden;
        pointer-events: none;
        max-height: 0;
        transition: all .6s ease-in-out;
        position: absolute;
        top: rem(50px);
        right: rem(15.8px);
        max-width: rem(343px);
        width: calc(100% - 31.8px);
        background-color: var(--color-bgDropdown);
        border: 1px solid lightness(--color-borderTextfield, 80%);
        box-shadow: 0 8px 16px -4px alpha(--color-boxShadow, 0.15), 0 0 1px 0 alpha(--color-boxShadow, 0.2);
        border-radius: 16px;
        overflow: hidden;
        z-index: setZ(dropdown);

        @include respond-above(m) {
            right: rem(24px);
        }

        &--show {
            visibility: visible;
            pointer-events: all;
            max-height: 500px;
        }
    }
    &__manageOrderOption {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: var(--color-txtDark);
        padding: rem(19.5px) rem(16px);
        display: block;
        user-select: none;
        width: 100%;
    }
    &__manageOrderOption:focus,
    &__manageOrderOption:hover {
        background-color: alpha(--color-bgDark, 0.6) !important;
    }
    &__manageOrderOption:focus-visible {
        @extend %button-focused;
    }

    &__orderDetail {
        cursor: pointer;
    }
    &--migrated &__orderDetail {
        cursor: default;
        pointer-events: none;
    }

    &__stateOrder {
        @include respond-above(m) {
            display: flex;
        }
    }

    &__state {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        width: fit-content;
        border-radius: rem(12px);
        padding: rem(3px) rem(16px) rem(2px) rem(16px);
        user-select: none;

        @include respond-above(m) {
            font-size: rem(14px);
            line-height: rem(14px);
            order: 1;
            margin-left: rem(8px);
            padding: rem(4px) rem(16px) rem(2px) rem(16px);
        }

        /* state colors */
        @include orderStateColors();
    }
    &__order {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(18px);

        @include respond-above(m) {
            order: 0;
            margin-top: unset;
        }
    }

    &__dateTotal {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);

        & > p {

            &:not(:first-child) {
                margin-top: rem(5.6px);

                @include respond-above(m) {
                    margin-top: unset;
                }
            }

            @include respond-above(m) {
                display: inline-block;

                &:not(:first-child)::before {
                    content: '-';
                    color: lightness(--color-txt, 90%);
                    margin-right: 6px;
                    margin-left: 3px;
                }
            }
        }
    }
    &__datePlaced, &__total {
        @extend %dmSansFontBold;
    }

    /* bottom */

    &__bottom {
        padding: rem(21.5px) rem(16.2px) rem(21.5px) rem(15.8px);
        background-color: var(--color-secondary1);

        @include respond-above(m) {
            padding: rem(24px);
            display: flex;
            justify-content: space-between;
        }
    }

    &__label {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }
    &__divider {
        background-color: lightness(--color-txt, 90%);
        width: auto;
        height: 1px;
        margin-top: 15px;
        margin-bottom: 16px;
        margin-left: unset;
        margin-right: unset;

        @include respond-above(m) {
            width: 1px;
            height: auto;
            margin-top: unset;
            margin-bottom: unset;
            margin-left: 18.3px;
            margin-right: 17.3px;
        }
    }
    button#{$root}__labelText {
        @extend %buttonReset;
        display: block;
    }
    button#{$root}__labelText:focus-visible {
        @extend %button-focused;
    }
    &__labelText {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);

        @include respond-above(m) {
            margin-top: rem(4px);
        }
    }
    &__icon, 
    &__addressDetailIcon {
        font-size: rem(14px);
        position: relative;
        bottom: -1px;
    }
    &__icon {
        margin-right: 8px;
    }
    &__addressDetailIcon {
        display: inline-block;
        font-weight: 600;
        margin-left: 5px;
        transition: transform .2s ease-in-out;
    }
    &--migrated &__addressDetailIcon {
        display: none;
    }

    &__address {
        position: relative;
    }
    &__address > &__labelText {
        cursor: pointer;
        user-select: none;
    }
    &--migrated &__address > &__labelText {
        cursor: default;
    }
    &__addressDetailTooltip {
        display: none;
        position: absolute;
        left: 0;
        max-width: rem(323px);
        width: 100%;
        padding: rem(16px);
        background-color: var(--color-bg);
        border: 1px solid lightness(--color-borderTextfield, 80%); 
        border-radius: 16px;
        z-index: map-get($z-layers, popup);

        & > h4 {
            @extend %dmSansFontMedium;
            font-size: rem(16px);
            line-height: rem(16px);
            color: var(--color-txtAcc);
        }
        & > p {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-gray);
            margin-top: rem(8px);
        }
    }

    &__address--showDetail &__addressDetailIcon {
        transform: rotateX(180deg);
    }
    &__address--showDetail &__addressDetailTooltip {
        display: block;
    }
}