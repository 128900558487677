@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mw19-migration-thank-you {
    @include wizard(&);
    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        align-items: flex-start;
        flex-flow: column;

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__image {
        width: rem(148px);
        height: auto;
        margin-right: rem(12px);
    }

    &__result {
        flex: 1 1 auto;
        max-width: rem(464px);
        display: flex;
        flex-flow: column;
    }

    &__text {
        @extend %font-heading5;
        @extend %txt;
        margin-top: rem(12px);

        @include respond-above(m) {
            margin-top: rem(8px);
        }
    }

    &__link {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtDark);
        display: inline-block;
        padding: rem(20px) rem(32px);
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        text-align: center;
        width: 100%;
        margin-top: rem(28px);

        @include respond-above(m) {
            width: fit-content;
            margin-top: rem(112px);
            align-self: flex-end;
        }
    }
}
