@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';


.mf7-profile {
    $root: &;
    padding-bottom: rem(80px);

    @include respond-below(l) {
        margin-top: rem(33px);
    }

    &__tabs {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        @include respond-above(l) {
            justify-content: unset;
        }

        @include respond-below(l) {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;

            /* margin for scrollbar mobile */
            & > button {
                margin-bottom: rem(10px);
            }
        }

        /* hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

    }
    /* hide scrollbar for Chrome, Safari and Opera */
    &__tabs::-webkit-scrollbar {
        display: none;
    }

    &__tabLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtTab);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            padding-bottom: rem(8px);
        }
    }

    /* buttons */
    &__accessSecurityTab, 
    &__personalDataTab,
    &__consentsTab {
        @extend %buttonReset;
        flex: 1 0 0;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-txtTab);
        border-radius: rem(12px);
        padding: rem(20px);
        margin-bottom: 3px;
        max-height: 71px;
        min-width: 128px;

        @include respond-above(l) {
            flex-flow: row;
            border: unset;
            border-radius: unset;
            flex: unset;
            padding: unset;
            max-height: unset;
            min-width: unset;
        }

        /* mobile/desktop div */
        & > div {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
    }
    &__accessSecurityTab:focus-visible, 
    &__personalDataTab:focus-visible,
    &__consentsTab:focus-visible {
        @extend %button-focused;
    }
    &__activeTab {
        border-color: var(--color-blackAcc);

        #{$root}__tabLabel {
            color: var(--color-blackAcc);
        }

        #{$root}__tabDecoration {
            display: flex;
            width: rem(60px);

            & > div {
                width: rem(32px);
            }
        }
    }
    &__accessSecurityTab {
        margin-right: rem(3px);

        @include respond-above(l) {
            margin-right: rem(16px);
        }
    }
    &__personalDataTab {
        margin-left: rem(3px);
        margin-right: rem(3px);

        @include respond-above(l) {
            margin-left: rem(16px);
            margin-right: rem(16px);
        }
    }
    &__consentsTab {
        margin-left: rem(3px);

        @include respond-above(l) {
            margin-left: rem(16px);
        }
    }

    &__tabDecoration {
        position: absolute;
        bottom: rem(-2.2px);
        width: rem(20px);
        height: rem(2.5px);
        background-color: var(--color-bg);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: none;
            height: rem(4px);
            bottom: rem(-3px);
        }

        & > div {
            width: rem(3px);
            height: inherit;
            background-color: var(--color-primary2);
            border-radius: rem(4px);
        }
    }

    &__accessSecurityContent, 
    &__personalDataContent,
    &__consentsContent {
        & > div:first-child {
            display: flex;
            flex-flow: column;
            width: 100%;

            @include respond-above(l) {
                & > div:first-of-type {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }

    &__accessData,
    &__personalData {
        display: flex;
        flex-flow: column;
        margin-top: rem(30px); /* -10px from margin-bottom on tabs > button */
        margin-bottom: rem(32px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
    }

    &__accessDataTitle,
    &__marketingEmailSectionTitle,
    &__personalTitle,
    &__enrichmentTitle,
    &__consentsHeadingTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__accessEmail,
    &__accessPassword {
        margin-top: rem(27px);
        padding-bottom: rem(32px);
        border-bottom: 1px solid lightness(--color-border, 90%);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
    }

    &__accessEmailHeading,
    &__accessPasswordHeading,
    &__personalHeading,
    &__enrichmentHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__accessEmailHeading,
    &__accessPasswordHeading {
        margin-bottom: rem(8px);
    }

    &__accessEmailTitle,
    &__marketingEmailTitle,
    &__accessPasswordTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }
    &__marketingEmailTitle {
        margin-top: rem(32px);
        margin-bottom: rem(8px);
    }

    &__marketingEmail {
        margin-bottom: rem(16px);
    }

    &__accessEmailModify,
    &__accessEmailCancel,
    &__accessPasswordModify,
    &__accessPasswordCancel,
    &__personalModify,
    &__personalCancel,
    &__enrichmentModify,
    &__enrichmentCancel {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
    }
    &__accessEmailModify:focus-visible,
    &__accessEmailCancel:focus-visible,
    &__accessPasswordModify:focus-visible,
    &__accessPasswordCancel:focus-visible,
    &__personalModify:focus-visible,
    &__personalCancel:focus-visible,
    &__enrichmentModify:focus-visible,
    &__enrichmentCancel:focus-visible {
        @extend %button-focused;
    }
    &__personalModify,
    &__personalCancel,
    &__enrichmentModify,
    &__enrichmentCancel {
        align-self: flex-end;
    }
    &__accessEmailModify,
    &__accessPasswordModify {
        display: block;
        margin-top: rem(16px);
    }
    &__accessEmailCancel,
    &__accessPasswordCancel {
        height: fit-content;
        align-self: center;
    }
    &__accessEmailCancel,
    &__accessPasswordCancel,
    &__personalCancel,
    &__enrichmentCancel {
        display: none;
    }    
    /* show cancel label on edit mode */
    &__accessEmail--edit &__accessEmailCancel,
    &__accessPassword--edit &__accessPasswordCancel,
    &__personalData--edit &__personalCancel,
    &__enrichmentData--edit &__enrichmentCancel {
        display: inline;
    }
    /* hide modify label on edit mode */
    &__accessEmail--edit &__accessEmailModify,
    &__accessPassword--edit &__accessPasswordModify,
    &__personalData--edit &__personalModify,
    &__enrichmentData--edit &__enrichmentModify {
        display: none;
    }

    &__accessEmailContentEdit,
    &__accessPasswordContentEdit,
    &__personalContentEdit,
    &__enrichmentContentEdit {
        display: none;
    }
    /* hide content view on edit mode */
    &__accessEmail--edit &__accessEmailContentView,
    &__accessPassword--edit &__accessPasswordContentView,
    &__personalData--edit &__personalContentView,
    &__enrichmentData--edit &__enrichmentContentView {
        display: none;
    }
    /* show content edit on edit mode */
    &__accessEmail--edit &__accessEmailContentEdit,
    &__accessPassword--edit &__accessPasswordContentEdit,
    &__personalData--edit &__personalContentEdit,
    &__enrichmentData--edit &__enrichmentContentEdit {
        display: flex;
    }
    &__accessEmailContentEdit,
    &__accessPasswordContentEdit,
    &__personalContentEdit,
    &__enrichmentContentEdit {
        flex-flow: column;
    }

    &__accessEmailField,
    &__accessEmailFieldVerify,
    &__accessPasswordField {
        margin-top: rem(24px);
    }
    &__accessPasswordFieldRepeat {
        margin-top: rem(16px);
    }

    &__accessEmailSave,
    &__accessPasswordSave,
    &__personalSave,
    &__enrichmentSave,
    &__consentsSave {
        @extend %button-primary3;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        min-width: rem(105px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        text-align: center;
        width: fit-content;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);
        align-self: flex-end;
        pointer-events: all !important;

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }
    &__accessEmailSave:focus-visible,
    &__accessPasswordSave:focus-visible,
    &__personalSave:focus-visible,
    &__enrichmentSave:focus-visible,
    &__consentsSave:focus-visible {
        @extend %button-focused;
    }
    &__enrichmentSave,
    &__personalSave,
    &__consentsSave {
        margin-top: rem(24px);
    }

    &__personalSave,
    &__enrichmentSave,
    &__consentsSave {
        @include respond-below(l) {
            width: 100%;
        }
    }

    &__consentsSave {
        @include respond-above(l) {

            margin-right: 7.63%;
        }
    }

    &__passwordRules {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        overflow: hidden;
        max-height: rem(50px);
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            max-height: 0;
        }
    }

    &__passwordMismatch {
        margin-top: rem(24px);
        display: flex;
        align-items: center;
        overflow: hidden;
        visibility: visible;
        pointer-events: all;
        transition: max-height 0.3s ease-in-out;

        &--hidden {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__passwordState {
        flex: 0 0 auto;
        margin-right: rem(16px);
        & > .ok {
            font-size: rem(16px);
            background-color: var(--color-secondary3);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .error {
            font-size: rem(16px);
            background-color: var(--color-primary);
            border-radius: 50%;
            color: var(--color-txtDark);
            display: none;
        }
        & > .active {
            display: block;
        }
    }

    &__passwordText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
    }
    &__passwordMismatchText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-primary);
    }

    /* cta wrappers */
    &__accessEmailEditForm + div,
    &__accessPasswordEditForm + div {
        display: flex;
        justify-content: flex-end;
        gap: rem(24px);
        margin-top: rem(24px);
    }

    &__accessEmailEditText,
    &__consentsHeadingText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtButton);
    }
    &__consentsHeadingText {
        margin-top: rem(11px);
        max-width: 80ch;

        @include respond-above(l) {
            margin-top: rem(16px);
        }

        & > a {
            color: var(--color-info);
            font-weight: 500;
            text-decoration: underline;
        }
    }

    &__marketingEmailSection {
        margin-top: rem(-16px);
        padding-bottom: rem(32px);
        border-bottom: 1px solid lightness(--color-border, 90%);
        margin-bottom: rem(32px);
    }
    &__marketingEmailSectionTitle,
    &__marketingEmailTitle,
    &__marketingEmail {
        display: none;
    }
    &__marketingEmailSection--changeable {
        margin-top: 0;
        
        #{$root}__marketingEmailSectionTitle,
        #{$root}__marketingEmailTitle,
        #{$root}__marketingEmail {
            display: block;
        }
        #{$root}__marketingEmailAlert {
            display: flex;
        }
        #{$root}__marketingEmailInfo {
            display: none;
        }
    }
    &__marketingEmailSection--hidden {
        display: none;
    }

    &__marketingEmailAlert,
    &__marketingEmailInfo {
        padding: rem(16px);
        background-color: var(--color-secondary4);
        border-radius: 12px;
    }
    &__marketingEmailAlert {
        display: none;
        flex-wrap: wrap;
        background-color: var(--color-secondary4);
    }
    &__marketingEmailSection[data-cooperative="CIA"] &__marketingEmailAlert {
        flex-wrap: nowrap;
    }
    &__marketingEmailInfo {
        display: flex;
        background-color: var(--color-secondary2);
    }

    &__marketingEmailAlertIcon,
    &__marketingEmailInfoIcon {
        align-self: center;
        flex-shrink: 0;
        width: 16px;
        height: 16px;
        object-fit: cover;
        margin-right: rem(16px);
    }
    &__marketingEmailAlertIcon {
        background-image: url('#{$resourcesFolder}/myconad/icons/alert-yellow.svg');
    }
    &__marketingEmailInfoIcon {
        @extend %buttonReset;
        background-image: url('#{$resourcesFolder}/myconad/icons/info16.svg');
    }

    &__marketingEmailAlertText,
    &__marketingEmailInfoText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
    }
    &__marketingEmailAlertText {
        color: alpha(--color-txt, 60%);
    }
    &__marketingEmailSection &__marketingEmailAlertText {
        width: calc(100% - 32px);
    }
    &__marketingEmailSection[data-cooperative="CIA"] &__marketingEmailAlertText {
        flex-grow: 1;
        width: unset;
    }
    &__marketingEmailInfoText {
        color: var(--color-info);
    }

    &__marketingEmailAlertCta {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: lightness(--color-warningInfo, 20%);
        padding: rem(11.4px) rem(23px);
        border-radius: rem(12px);
        border: 2px solid var(--color-warningInfo);
        white-space: nowrap;
        margin-left: auto;
        margin-top: rem(16px);
    }

    &__marketingEmailAlertClose {
        @extend %buttonReset;
        font-size: rem(16px);
        font-weight: bold;
        margin-left: rem(16px);
    }

    &__deleteProfile {
        @extend %buttonReset;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        cursor: pointer;
        padding: rem(20px) rem(32px);
        border: 2px solid var(--color-primary3);
        border-radius: 12px;

        @include respond-above(m) {
            width: fit-content;
        }
    }
    &__deleteProfile:focus-visible {
        @extend %button-focused;
    }

    &__personalContent,
    &__enrichmentContentEdit {
        margin-top: rem(27px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
    }
    &__enrichmentContentView {
        margin-top: rem(27px);

        @include respond-above(l) {
            margin-top: rem(40px);
        }
    }
    &__personalContentView,
    &__enrichmentContentView {
        & > div {
            padding-bottom: rem(13px);
            border-bottom: 1px solid alpha(--color-border, 0.05);
        }
        & > div:not(:first-of-type) {
            margin-top: rem(13px);
        }
    }
    &__personalViewLabel,
    &__enrichmentViewLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }
    &__personalViewValue,
    &__enrichmentViewValue {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
    }

    &__personalEditForm,
    &__enrichmentEditForm {
        & > div:not(:first-of-type) {
            margin-top: rem(24px);
        }
    }

    &__personalNameSurnameEdit,
    &__personalChildBirthDateEdit {
        & > div:not(:first-of-type) {
            margin-top: rem(24px);
        }
    }

    &__personalStreetEdit,
    &__personalAddressEdit {
        margin-top: rem(16px);

        @include respond-above(l) {
            display: flex;
            justify-content: space-between;
        }

        @include respond-below(l) {
            & > div:not(:first-child) {
                margin-top: rem(24px);
            }
        }
    }
    @include respond-above(l) {
        &__personalAddressProvinceEdit {
            flex: 9 0 0;
            margin-right: rem(8px);

            &:last-child {
                margin-right: unset;
            }
        }
        &__personalAddressCityEdit {
            max-width: 100% !important;
            flex: 19 0 0;
            margin-left: rem(8px);
            margin-right: rem(8px);

            &:last-child {
                margin-left: unset;
                margin-right: unset;
            }
        }
        &__personalAddressCapEdit {
            flex: 14 0 0;
            margin-left: rem(8px);
        }

        &__personalStreetNameEdit {
            flex: 29 0 0;
            margin-right: rem(8px);

            &:last-child {
                margin-right: unset;
            }
        }
        &__personalStreetNumberEdit {
            flex: 14 0 0;
            margin-left: rem(8px);
        }

        &__personalGenderOptionEdit {
            max-width: 100% !important;

            ul {
                max-width: rem(395px);
                left: unset;
                right: 0;
            }
        }
    }

    &__enrichmentBanner {
        display: flex;
        flex-flow: column-reverse;
        justify-content: space-between;
        min-height: rem(198px);
        background-color: var(--color-secondary6);
        padding: rem(24px) rem(24px);
        border-radius: 12px;

        @include respond-above(l) {
            flex-flow: row;
            padding: rem(24px) rem(40px);
        }
    }
    &__enrichmentBannerLeft {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }
    &__enrichmentBannerTitle {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: unset;
        }
    }
    &__enrichmentBannerDescription {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-gray);
        margin-top: rem(8px);
    }
    &__enrichmentBannerCta {
        @extend %button-primary3;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        min-width: rem(105px);
        padding: rem(12px) rem(24px);
        text-align: center;
        color: var(--color-txtDark);
        background-color: var(--color-primary3);
        width: fit-content;
        cursor: pointer;
        margin-top: rem(32px);
    }
    &__enrichmentBannerCta:focus-visible {
        @extend %button-focused;
    }
    &__enrichmentBannerImage {
        object-fit: contain;
        width: 170px;
        height: 106px;
        object-position: left;

        @include respond-above(l) {
            width: 244px;
            height: 152px;
            object-position: right;
        }
    }

    &__enrichmentHeading {
        margin-top: rem(8px);
    }

    /* extra class for selects of the enrichment data form */
    &__enrichmentSelect {
        max-width: unset !important;
        
        & ul.mt16-select__content {
            max-width: 250px;
            left: unset;
            right: 0;
        }
    }

    /* hide/show banner or enrichment data */
    &__enrichmentHeading,
    &__enrichmentContent {
        display: none;
    }
    &__enrichmentData--active &__enrichmentBanner {
        display: none;
    }
    &__enrichmentData--active &__enrichmentHeading {
        display: flex;
    }
    &__enrichmentData--active &__enrichmentContent {
        display: unset;
    }

    &__enrichmentFormEdit > div:not(:first-of-type) {
        margin-top: rem(16px);
    }

    &__consentsData {
        flex-flow: column;
        margin-top: rem(30px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
    }

    &__consentsDataContent {
        display: flex;
        flex-flow: column;
    }

    &__consentsSupport {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-txtAcc);
    }

    &__consentsTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__consentsText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        max-width: 80ch;
    }

    &__consentsNegativeText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-negativeInfo);
        margin-top: rem(16px);
        display: none;
    }

    &__consentsForm {
        margin-bottom: rem(56px);

        @include respond-above(l) {
            margin-bottom: rem(30px);
            width: 92.37%;
        }
    }
    &__consentsMarketing {
        padding-top: rem(40px);
        padding-bottom: rem(74px);

        @include respond-above(l) {
            padding-bottom: rem(24px);
        }
    }
    &__consentsProfiling {
        padding-bottom: rem(24px);
        border-bottom: 1px solid lightness(--color-border, 90%);
    }
    &__consentsCooperative {
        padding-top: rem(24px);
        padding-bottom: rem(24px);
        border-bottom: 1px solid lightness(--color-border, 90%);
    }
    &__consentsMarketingCommunicationPartner {
        padding-top: rem(24px);
        padding-bottom: rem(24px);
        border-bottom: 1px solid lightness(--color-border, 90%);
    }

    /* CONTACT PREFERENCES */
    &__cp-form {
        margin-top: rem(32px);
    }

    &__cp-introTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-txt, 20%);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__cp-intro {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-txt, 20%);
        margin-top: rem(4px);
    }

    &__cp-section {
        display: flex;
        flex-flow: column;
        margin-top: rem(20px);
        box-shadow: 0 0 8px 0 rgba(142, 142, 142, 0.25);
        padding: rem(16px) rem(16px);
        border-radius: rem(12px);

        @include respond-above(m) {
            padding: rem(23px) rem(16px);
        }
    }

    &__cp-mainTitle,
    &__cp-preferenceTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-txt, 20%);
    }

    &__cp-mainText,
    &__cp-preferenceText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-txt, 20%);
        margin-top: rem(8px);
    }

    &__cp-contactPreferences {
        display: flex;
        flex-flow: column;
        margin-top: rem(16px);
    }

    &__cp-preference {
        padding-top: rem(16px);
        padding-bottom: rem(15px);
        border-bottom: 1px solid alpha(--color-border, 0.1);
    }
    &__cp-section &__cp-contactPreferences &__cp-preference:last-child {
        padding-bottom: 0;
        border-bottom: none;
    }

    &__cp-preferenceHeading {
        display: flex;
        justify-content: space-between;
    }

    &__bottomRefreshTexts {
        display: flex;
        flex-direction: column;
        margin-top: rem(50px);

        @include respond-above(l) {
            flex-direction: row;
            justify-content: space-between;
        }

        p > a {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__bottomRefreshTextsContainer {
        max-width: unset;

        @include respond-above(l) {
            max-width: rem(390px);
        }
    }

    &__bottomDiscoverMore {
        margin-top: rem(10px);
    }
}