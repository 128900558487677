@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mf10-insieme-plus-card-expenses {
    margin-bottom: rem(80px);

    @include respond-below(l) {
        margin-top: rem(37px);
    }

    @include respond-above(m) {
        margin-bottom: rem(88px);
    }

    &__payments {
        margin-top: rem(46px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__paymentsHeading {
        display: flex;
        flex-flow: column;
        border-bottom: 1px solid lightness(--color-border, 90%);
        padding-bottom: rem(32px);
        margin-bottom: rem(23px);

        @include respond-above(m) {
            padding-bottom: rem(20px);
            margin-bottom: rem(24px);
        }
    }

    &__paymentsTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(m) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__paymentsFrom {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(5px);

        @include respond-above(m) {
            align-self: flex-end;
        }
    }

    &__paymentsTable {
        width: 100%;
        margin-bottom: rem(40px);

        @include respond-above(m) {
            margin-bottom: rem(32px);
        }

        /* heading */
        & > thead > tr:first-child {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(14px);
            color: var(--color-gray);
            text-align: left;

            & > th {
                padding-bottom: rem(7px);

                @include respond-above(m) {
                    padding-bottom: rem(16px);
                }
            }
        }

        /* rows */
        & > tbody > tr {
            height: rem(64px);

            @include respond-above(m) {
                height: rem(60px);
            }
        }

        /* even/odd rows */
        & > tbody > tr:nth-child(2n) {
            background-color: lightness(--color-txt, 0.8);
        }
        & > tbody > tr:nth-child(2n + 1) {
            background-color: var(--color-secondary1);
        }

        /* data entries */
        & > tbody > tr > td {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: var(--color-gray);

            @include respond-above(m) {
                font-size: rem(14px);
                line-height: rem(20px);
            }
        }

        /* first column */
        & > thead > tr > th:first-child,
        & tbody > tr > td:first-child {
            padding-left: rem(9px);

            @include respond-above(m) {
                padding-left: rem(8px);
            }
        }

        &--hidden {
            display: none;
        }
    }

    &__paymentsLoadMore {
        @extend %buttonReset;
        display: flex;
        align-items: center;
        justify-content: center;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        cursor: pointer;
        padding: rem(20px) rem(32px);
        border: 2px solid var(--color-primary3);
        border-radius: 12px;

        @include respond-above(m) {
            width: fit-content;
        }

        &--hidden {
            display: none;
        }
    }
    &__paymentsLoadMore:focus-visible {
        @extend %button-focused;
    }

    &__paymentsInfo {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        margin-top: rem(24px);
    }

    &__paymentsMonthlyPdf {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        margin-top: rem(16px);

        &--borderBottom {
            padding-bottom: rem(31px);
            border-bottom: 1px solid alpha(--color-border, 0.1);
        }
    }

    &__downloadPaymentsSelectedMonthPdf,
    &__downloadPaymentsCurrentMonthPdf,
    &__disableServices {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        color: var(--color-info);
        text-decoration: underline;
    }
    &__downloadPaymentsSelectedMonthPdf:focus-visible,
    &__downloadPaymentsCurrentMonthPdf:focus-visible,
    &__disableServices:focus-visible {
        @extend %button-focused;
    }

    &__services {
        /*display: none;*/
        margin-top: rem(32px);

        @include respond-above(m) {
            margin-top: rem(40px);
        }

        &--show {
            display: block;
        }
    }

    &__servicesTitle,
    &__serviceTitle {
        @extend %dmSansFontBold;
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }
    &__servicesTitle {
        font-size: rem(20px);
    }
    &__serviceTitle {
        font-size: rem(16px);
    }

    &__servicesText {
        @extend %dmSansFontRegular;
        color: var(--color-txtAcc);
        margin-top: rem(8px);
    }
    &__servicesText {
        font-size: rem(16px);
        line-height: rem(24px);
    }
    &__serviceText {
        font-size: rem(14px);
        line-height: rem(20px);
    }

    &__servicesForm {
        margin-top: rem(32px);
        padding-bottom: rem(16px);
        border-bottom: 1px solid alpha(--color-border, 0.1);

        & > div:not(:first-child) {
            margin-top: rem(32px);
        }
    }

    &__servicesDisable {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-gray);
        margin-top: rem(16px);

        & > span {
            @extend %dmSansFontMedium;
            color: var(--color-info);
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__servicesSubmit {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(20px) rem(32px);
        border-radius: 12px;
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: rem(40px);
        cursor: pointer;
        color: var(--color-txtButton);
        background-color: var(--color-bgButton);

        @include respond-above(m) {
            width: fit-content;
        }

        &--active {
            color: var(--color-txtDark);
            background-color: var(--color-primary3);
        }
    }
    &__servicesSubmit:focus-visible {
        @extend %button-focused;
    }

    &__noPayments {
        display: none;
        margin-top: rem(32px);

        &--show {
            display: block;
        }
    }

    &__noPaymentsTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);

        @include respond-above(m) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__noPaymentsText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
        margin-bottom: rem(24px);

        @include respond-above(m) {
            font-size: rem(16px);
            line-height: rem(24px);
            margin-bottom: rem(32px);
        }
    }

    &__noPaymentsImage {
        background-image: url('#{$resourcesFolder}/myconad/icons/lens.png');
        background-size: 96px;
        width: 96px;
        height: 96px;
        object-fit: cover;
    }
}
