@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm8-delete-address {
    @include modal(&, true);

    &__wrapper {
        display: flex;
        margin-top: rem(175px);

        & > div:first-of-type {
            display: flex;
            flex-flow: column;
        }
    }

    &__image {
        margin-right: rem(16px);
        object-fit: cover;
        width: 121px;
        height: 104px;

        @include respond-above(l) {
            width: 148px;
            height: 128px;
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-txtAcc);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(8px);
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__delete {
        @extend %button-primary3;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        min-width: rem(105px);
        padding: rem(20px) rem(32px) rem(20px) rem(32px);
        text-align: center;
        color: var(--color-txtDark);
        background-color: var(--color-primary3);
        cursor: pointer;
        border-radius: 12px;
        order: 3;
        align-self: flex-end;
    }
    &__delete:focus-visible {
        @extend %button-focused;
    }

    &__cancel {
        @extend %buttonReset;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-info);
        align-self: center;
        margin-right: rem(24px);
        text-decoration: underline;
        cursor: pointer;
    }
    &__cancel:focus-visible {
        @extend %button-focused;
    }
}