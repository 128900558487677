@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp16-choose-cart {
    @include actionPopup();

    &__content {
        padding: 1.5rem;
        justify-content: center;
        display: flex;
        align-items: center;
    }

    &__wrapper {
        visibility: hidden;
    }

    &__title {
        @extend %font-heading3;
        margin-bottom: rem(8px);
        padding: 0;
    }

    &__subtitle {
        @extend %font-heading4;
        @extend %dmSansFontRegular;
        line-height: 1.2rem;
        color: var(--color-gray);
        margin-bottom: rem(33px);
    }

    &__container {
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-above(m) {
            flex-direction: row;
        }
    }

    &__single-cart {
        width: 100%;
        border-radius: rem(12px);
        border: solid rem(1px) #c1c1c1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-bottom: rem(16px);
        padding: rem(16px);
        height: rem(127px);


        @include respond-above(m) {
            min-width: rem(294px);
            margin-bottom: 0;
        }

        &__createdLabel {
            @extend %font-label2;
            color: var(--color-blackAcc);
            margin-bottom: rem(8px);
        }

        h2 {
            @extend %font-heading2;
            color: var(--color-blackAcc);
            font-weight: bold;
            margin-bottom: rem(8px);
        }

        &-product-listing img {
            width: rem(35px);
            height: rem(35px);
            background-color: white;
            margin-left: rem(8px);
            box-shadow: 0 0 rem(8px) 0 rgba(171, 147, 67, 0.2);
            border-radius: rem(12px);
        }

        &-product-listing img:first-of-type {
            margin-left: 0;
        }

        &-product-listing img:last-of-type {
                    margin-right: rem(8px);
                }

        &-product-listing {
            display: flex;
        }

        &-product-listing-more-product-number {
            @extend %dmSansFontBold;
            font-weight: bold;
            font-size: rem(20px);
            font-stretch: normal;
            font-style: normal;
            line-height: 1.2;
            letter-spacing: normal;
            color: var(--color-blackAcc);
        }

    }

    &__single-cart:first-of-type {
        @include respond-above(m) {
            margin-right: rem(24px);
        }
    }

    &__cart-selected {
        -webkit-box-shadow: 0 0 rem(10px) rem(-2px) #f38933;
        box-shadow: 0 0 rem(10px) rem(-2px) #f38933;
        border: solid rem(4px) #f3893317;
    }

    .product-empty-sample {
        display: none;
    }

    &__container-row {
        display: flex;
        align-items: center;
    }

    &__finalRow {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: rem(16px);

        @include respond-above(m) {
           margin-top: rem(24px);
        }
    }

    &__button {
        @extend %button-primary3;
        margin: 0;
        text-align: center;
        color: var(--color-white);
        border-radius: rem(12px);
        width: 100%;

        @include respond-above(m) {
            width: rem(141px);
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__loader {
        display: block;
        position: relative;
        width: rem(80px);
        height: rem(80px);
        position: fixed;
        background-image: url('#{$resourcesFolder}/myconad/icons/loader.gif');
        background-repeat: no-repeat;
        background-size: rem(80px);
    }

    &__error {
        @extend %font-heading4;
        @extend %dmSansFontRegular;
        line-height: 1.2rem;
        color: var(--color-primary);
        display: none;
        margin-bottom: rem(16px);

        @include respond-above(m) {
           margin-bottom: rem(24px);
        }
    }
}
