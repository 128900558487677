@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt34-map-pin {
    $root: &;
    $pin-width: rem(34px);
    $pin-height: rem(40px);
    $pin-content-size: rem(24px);

    width: calc($pin-width + 1px);
    height: calc($pin-height + 1px);

    position: relative;
    z-index: 10;

    &__pin {
        width: $pin-width;
        height: $pin-width;
        border-radius: 50% 50% 1px 50%;
        background-color: var(--color-primary3);
        transform: rotate(45deg);
        cursor: pointer;
        display: flex;
        box-shadow: 0 0 10px -2px alpha(--color-black, 0.4);

        &:hover {
            background-color: lightness(--color-primary3, 70%);
        }
    }

    &--specialized {
        z-index: 5;
    }

    &--selected {
        z-index: 20;
    }

    &--selected &__pin {
        background-color: var(--color-white);
        box-shadow: -2px -1px 10px 0px alpha(--color-primary3, 0.15), 0px 0px 5px 4px alpha(--color-primary3, 0.2);
    }

    &--aggregator &__pin {
        background-color: var(--color-primary2);
    }
    &--aggregator &__pinContent {
        background: none;
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        text-align: center;
    }

    &__pinContent {
        width: $pin-content-size;
        height: $pin-content-size;
        background-size: $pin-content-size;
        background-repeat: no-repeat;
        transform: rotate(315deg);
        margin: auto;
    }

    /* TYPES */
    &--margherita &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-margherita.svg');
    }
    &--margherita#{$root}--selected &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-margherita-selected.svg');
    }

    &--fuel &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-fuel.svg');
    }
    &--fuel#{$root}--selected &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-fuel-selected.svg');
    }

    &--eye &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-eye.svg');
    }
    &--eye#{$root}--selected &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-eye-selected.svg');
    }

    &--pharmacy &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-pharmacy.svg');
    }
    &--pharmacy#{$root}--selected &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-pharmacy-selected.svg');
    }

    &--pet &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-pet.svg');
    }
    &--pet#{$root}--selected &__pinContent {
        background-image: url('#{$resourcesFolder}/myconad/icons/pin-pet-selected.svg');
    }
}
