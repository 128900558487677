@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt24-product-accordion-card {
    //border: 0.5px solid alpha(--color-border, 0.05);
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 alpha(--color-boxShadow, 0.15), 0 -0.2px 1px 0 alpha(--color-boxShadow, 0.2);
    margin-left: 1px;
    margin-right: 1px;

    &:last-child {
        margin-bottom: 1px;
    }

    &__mobile {
        display: flex;

        @include respond-above(m) {
            display: none;
        }
    }
    &__desktop {
        display: none;

        @include respond-above(m) {
            display: flex;
        }
    }
    &__mobile,
    &__desktop {
        flex-flow: column;
    }

    /* m = mobile version, d = desktop version */

    &__mPromotionBadges,
    &__dPromotionBadges {
        display: flex;
        align-items: center;

        @include respond-above(m) {
            column-gap: 8px;
            row-gap: 8px;
            flex-wrap: wrap;
        }

        @include respond-above(xl) {
            column-gap: unset;
            row-gap: unset;
        }

        & > div:not(:first-child) {
            margin-left: rem(8px);

            @include respond-above(m) {
                margin-left: 0;
            }

            @include respond-above(xl) {
                margin-left: rem(8px);
            }
        }
    }
    &__mPromotionBadges {
        padding-top: rem(8px);
        padding-left: rem(8px);
        padding-right: rem(8px);
    }

    &__mPromotion,
    &__dPromotion {
        /* icon with img */
        &--icon > img {
            height: 24px;
            width: 24px;
            object-fit: cover;
        }

        /* badge */
        &--badge {
            @extend %dmSansFontBold;
            font-size: rem(12px);
            line-height: rem(12px);
            color: var(--color-txtAcc);
            width: fit-content;
            border-radius: rem(8px);
            padding: rem(6px) rem(8px);
            white-space: nowrap;
        }
        &--badge-red {
            color: var(--color-txtDark);
            background-color: var(--color-primary);
        }
        &--badge-orange {
            color: var(--color-primary3);
            background-color: lightness(--color-primary2, 80%);
        }
        &--badge-orange-delivery {
            color: var(--color-primary3);
            background-color: lightness(--color-primary2, 80%);

            /* promo delivery */
            > span:first-child {
                margin-right: 4px;
            }
        }
        /* add other badge-colors below */

        &--bassiFissi,
        &--insieme,
        &--insiemePiu,
        &--bothCards {
            background-repeat: no-repeat;
            background-size: 24px;
            height: 24px;
            width: 24px;
        }
        /* bassi e fissi */
        &--bassiFissi {
            background-image: url('#{$resourcesFolder}/myconad/icons/bassiefissi.png');
        }
        /* fidelity card only (insieme) */
        &--insieme {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-orange.svg');
        }
        /* conad card only (insieme plus) */
        &--insiemePiu {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-gray.svg');
        }
        /* both cards */
        &--bothCards {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-double.svg');
        }
    }

    &__mMain {
        display: flex;
        margin-bottom: rem(12px);
        padding: rem(8px);
        position: relative;
        flex-direction: row;
    }

    &__mPreview > img:first-child,
    &__dPreview > img:first-child {
        width: 56px;
        height: 56px;
        object-fit: contain;
    }
    &__dPreview > img:first-child {
        margin-top: 5px;
    }

    &__mNamePricesQuantity {
        display: flex;
        flex-flow: column;
        margin-left: rem(8px);
        width: 100%;
    }

    &__mName,
    &__dName {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
    }

    &__mPrices {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: rem(8px) 0;
    }

    &__mUnitPrice {
        display: flex;
        align-items: center;

        & > span:not(:first-child) {
            margin-left: rem(8px);
        }
    }
    &__mUpDiscounted,
    &__mUpOriginal,
    &__dUpDiscounted,
    &__dUpOriginal,
    &__mTotalPrice {
        @extend %robotoFontMedium;
        line-height: rem(20px);
    }
    &__dTotalPrice {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
    }
    &__mUpDiscounted,
    &__mUpOriginal {
        font-size: rem(14px);
    }
    &__mTotalPrice {
        font-size: rem(16px);
    }
    &__mUpDiscounted,
    &__dUpDiscounted {
        color: var(--color-primary);
    }
    &__mUpOriginal,
    &__mTotalPrice,
    &__dTotalPrice {
        color: var(--color-txtAcc);
    }
    &__mUpDiscounted + &__mUpOriginal,
    &__dUpDiscounted + &__dUpOriginal {
        font-size: rem(10px);
        line-height: rem(16px);
        text-decoration: line-through;
    }

    &__mQuantity {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-txtAcc);
        align-self: flex-end;
    }

    &__mBottom,
    &__dBottom {
        display: flex;
        justify-content: space-between;
        border-radius: 0 0 12px 12px;
        background-image: linear-gradient(to top, var(--color-secondary7), var(--color-info));
    }
    &__mBottom {
        padding: rem(8px);
    }
    &__dBottom {
        padding: rem(13px) rem(16px);
    }

    &__mSubstituteIfMissing {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-txtDark);

        & > p:not(:first-of-type) {
            line-height: rem(16px);
        }
        & a {
            text-decoration: underline;
        }
    }
    &__dSubstituteIfMissing {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-txtDark);

        & a {
            line-height: rem(16px);
            text-decoration: underline;
        }
    }

    &__mSubIcon {
        align-self: flex-end;
        width: 16px;
        height: 16px;
        object-fit: contain;
        background-image: url('#{$resourcesFolder}/myconad/icons/edit.svg');
    }

    &__dMain {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(8px) rem(23px) rem(8px) rem(23px);
        position: relative;
        height: rem(145px);
    }

    &__dPreviewNameSize {
        display: flex;
        width: 200px;
    }

    &__dNamePrep,
    &__dBadgesPrices,
    &__dQuantityFormat {
        display: flex;
        flex-flow: column;
    }

    &__dNamePrep,
    &__dQuantityFormat {
        justify-content: space-between;
    }

    &__dNamePrep {
        margin-left: rem(8px);
    }

    &__dBadgesPrices {
        min-width: rem(180px);
        padding-left: rem(8px);
        border-left: 1px solid lightness(--color-border, 90%);
        height: fit-content;
        min-height: rem(64px);
        position: relative;

        @include respond-above(m) {
            max-width: rem(180px);
        }

        @include respond-above(xl) {
            min-width: rem(320px);
            max-width: unset;
        }
    }

    &__dUnitPrice {
        margin: rem(4px) 0;
    }

    &__dUnitPricePerQuantity {
        @extend %dmSansFontMedium;
        font-size: rem(10px);
        line-height: rem(12px);
        color: var(--color-gray);
    }

    &__dUnitPricePerQuantity--promo {
        color: var(--color-primary);
    }

    &__dQuantity {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: var(--color-gray);
        width: rem(102px);
        padding: 18.7px rem(16px);
        border-radius: 16px;
        border: solid 1px alpha(--color-borderTextfield, 0.3);
    }

    &__dPrep,
    &__dFormat {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        margin-top: rem(19px);
    }

    &__conad60Image {
        width: rem(31px);
        height: rem(33px);
        object-fit: contain;
        object-position: top center;
        margin-top: rem(5px);
        margin-left: auto;
        margin-right: auto;

        @include respond-above(m) {
            width: rem(36px);
            height: rem(43px);
            margin-bottom: rem(-13px);
        }
    }
}
