@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mf8-dashboard {
    @include respond-below(l) {
        margin-top: rem(34px);
    }

    &__avatarAndInsiemeCard {
        display: flex;
        flex-flow: column;
        position: relative;

        @include respond-above(m) {
            flex-flow: row;
            justify-content: space-between;
        }
    }

    &__avatar {
        display: flex;
        align-items: center;
    }

    &__avatarImage {
        & > img {
            object-fit: cover;
            border-radius: 50%;
            border: 8px solid var(--color-secondary1);
            /* border is internal: add it to dimensions */
            width: calc(58.2px + 16px);
            height: calc(58.2px + 16px);

            @include respond-above(m) {
                width: calc(80px + 16px);
                height: calc(80px + 16px);
            }
        }
    }

    &__avatarUser {
        display: flex;
        flex-flow: column;

        @include respond-above(m) {
            margin-right: rem(100px);
        }
    }
    &__avatarImage + &__avatarUser {
        margin-left: rem(16px);
    }

    &__userGreeting {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }

    &__userName {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
        margin-top: rem(4px);
        margin-bottom: rem(8px);
    }

    &__userModify {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(16px);
        color: var(--color-info);
        text-decoration: underline;
        cursor: pointer;
    }

    &__insiemeCard {
        display: flex;
        justify-content: stretch;
        margin-top: rem(27px);

        @include respond-above(m) {
            margin-top: unset;
        }
    }

    &__insiemeCardInfo {
        align-self: flex-end;
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme.png');
        background-size: 66px;
        margin-bottom: 10px;
        width: 66.5px;
        height: 42.6px;

        @include respond-above(m) {
            background-size: 82.9px;
            width: 83px;
            height: 53.2px;
        }

        /* insieme plus card */
        &--plus {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-piu.jpg');
        }
    }

    &__insiemeCardInfoTooltip {
        position: relative;
        top: -13%;
        right: -87%;

        & > button {
            vertical-align: top;
        }
    }

    &__insiemeCardPointsCouponsNotifs {
        align-self: flex-end;
        display: flex;
        justify-content: stretch;
    }

    &__insiemeCardPoints,
    &__insiemeCardCoupons {
        display: flex;
        flex-flow: column;
        max-width: 79px;
        margin-left: rem(30px);
    }

    &__insiemeCardPointsLabel,
    &__insiemeCardCouponsLabel {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        flex-grow: 1;
    }

    &__insiemeCardPointsYear {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        flex-grow: 1;
    }

    &__insiemeCardPointsValue,
    &__insiemeCardCouponsValue {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-primary3);

        @include respond-above(m) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__insiemeCardNotifs {
        align-self: flex-end;
        position: absolute;
        top: rem(5px);
        right: rem(0px);
        background-color: var(--color-primary3);
        border-radius: 12px;
        padding: rem(12px) rem(12px) rem(9px);
        margin-left: rem(50px);
        margin-bottom: rem(21px);
        cursor: pointer;

        @include respond-above(m) {
            position: relative;
        }
    }

    &__insiemeCardNotifsIcon {
        color: var(--color-txtDark);
    }

    &__insiemeCardNotifsValue {
        position: absolute;
        right: -20%;
        top: 60%;
        display: flex;
        justify-content: center;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: var(--color-secondary5);

        & > span {
            @extend %robotoFontMedium;
            font-size: rem(12px);
            line-height: rem(12px);
            color: var(--color-primary);
            position: relative;
            top: 25%;
        }
    }

    &__noInsiemeCard,
    &__noInsiemePlusCard {
        display: flex;
        flex-flow: column;
        width: 100%;
        border-radius: 12px;
        background-color: var(--color-secondary2);
        padding: rem(16px);
        margin-top: rem(24px);
    }
    &__noInsiemeCard {
        max-width: rem(343px);

        @include respond-above(m) {
            margin-top: unset;
        }
    }
    &__noInsiemePlusCard {
        @include respond-above(m) {
            flex-flow: row;
            justify-content: space-between;
            padding: rem(8px) 0;
            padding-left: rem(16px);
            padding-right: rem(8px);
        }
    }

    &__noInsiemeCardText,
    &__noInsiemePlusCardText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-info);
        display: flex;
        align-items: center;
        margin-bottom: rem(16px);
    }
    &__noInsiemePlusCardText {
        @include respond-above(m) {
            margin-bottom: unset;
        }
    }

    &__noInsiemeCardIcon,
    &__noInsiemePlusCardIcon {
        object-fit: cover;
        width: 16px;
        height: 17px;
        margin-right: rem(16px);
    }
    &__noInsiemeCardIcon {
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-blue.svg');
    }
    &__noInsiemePlusCardIcon {
        background-image: url('#{$resourcesFolder}/myconad/icons/payment-card-blue.svg');
    }

    &__noInsiemeCardCta,
    &__noInsiemePlusCardCta {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-info);
        align-self: flex-end;
        background-color: var(--color-secondary2);
        border-radius: 12px;
        border: solid 2px var(--color-info);
        padding: rem(12px) rem(23px);
        cursor: pointer;
    }
    &__noInsiemeCardCta:focus-visible,
    &__noInsiemePlusCardCta:focus-visible {
        @extend %button-focused;
    }

    &__intro {
        margin-top: rem(32px);
        margin-bottom: rem(40px);

        @include respond-above(m) {
            margin-bottom: rem(42px);
        }
    }

    &__introHeading {
        position: relative;
        margin-bottom: 4px;
    }

    &__introTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }

    &__introTitleDecoration {
        position: absolute;
        top: calc(100% + 5px);
        width: 32px;
        height: 4px;
        background-color: var(--color-primary2);
        border-radius: 4px;
    }

    &__store {
        margin-top: rem(28px);
        margin-bottom: rem(48px);

        @include respond-above(m) {
            position: static;
            flex: 96 0 0;
            margin-top: rem(31px);
            margin-bottom: rem(41px);
        }
    }

    &__introText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(20px);
        color: var(--color-gray);
        margin-top: rem(26px);
        max-width: 80ch;
    }

    &__favorites {
        margin-bottom: rem(54px);
    }

    &__60-widget {
        margin-top: rem(25px);
    }

    &__hidden {
        visibility: hidden;
    }

    &__refreshCampaignWrapper {
        position: relative;
    }

    &__refreshCampaignBanner {
        position: relative;
        display: flex;
        padding: rem(10px) rem(10px) rem(20px);
        margin: rem(20px) 0;
        border-radius: rem(12px);
        flex-direction: column;
        z-index: 2;

        @include respond-above(m) {
            flex-direction: row;
            align-items: stretch;
            padding: rem(16px) rem(16px) rem(16px) rem(13px);
        }
    }

    &__refreshCampaignPic {
        width: 100%;
        height: rem(115px);
        flex-shrink: 0;
        flex-grow: 0;

        @include respond-above(m) {
            width: rem(167px);
            height: rem(188px);
        }
    }

    &__refreshCampaignImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: rem(12px);
    }

    &__refreshCampaignContent {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include respond-above(m) {
            margin-left: rem(22px);
            justify-content: space-between;
        }
    }

    &__refreshCampaignDiscoverMore {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(18px);

        a {
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__refreshCampaignTextContent {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        letter-spacing: normal;

        margin-top: rem(10px);
        text-align: center;
        @include respond-above(m) {
            text-align: left;
        }

        p > a {
            @extend %dmSansFontMedium;
            color: var(--color-info);
            text-decoration: underline;
        }
    }

    &__refreshCampaignTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        margin-bottom: rem(8px);
        margin-top: rem(15px);
        text-align: center;

        @include respond-above(m) {
            text-align: left;
        }
    }

    &__refreshCampaignBottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: rem(16px);
        gap: rem(16px);

        @include respond-above(m) {
            margin-top: 0;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__refreshCampaignCta {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        cursor: pointer;

        font-size: rem(14px);
        line-height: rem(14px);
        color: white;
        background-color: var(--color-primary3);
        border-radius: rem(12px);
        padding: rem(12px) rem(23px);
        text-align: center;
        width: calc(100% - rem(20px));

        @include respond-above(m) {
            width: auto;
        }
    }

    &__refreshCampaignPopUp {
        @extend %dmSansFontBold;
        opacity: 1;
        z-index: 2;
        padding: rem(18px) rem(25px);
        border-radius: rem(20px);
        position: absolute;
        top: rem(-80px);
        left: calc(50% - 4rem);
        background: white;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-primary3);
        min-width: rem(129px);
        text-align: center;
        transition: opacity 0.4s;

        @include respond-above(m) {
            left: rem(16px);
        }
    }

    &__refreshCampaignPopUpBackdrop {
        opacity: 1;
        z-index: 1;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: alpha(--color-bgLoader, 0.6);
        transition: opacity 0.4s;
    }

    &__refreshCampaignPopUpClose {
        @extend %buttonReset;
        position: absolute;
        font-size: rem(18px);
        top: rem(6px);
        right: rem(8px);
        cursor: pointer;
        color: var(--color-txtButton);
        font-weight: 400;
    }

    &__refreshCampaignPopUpHidden {
        opacity: 0;
    }
}
