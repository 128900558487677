@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mp22-coupon-popup {
    width: rem(784px);
    position: relative;
    height: 100vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-top: rem(128px);
    padding-right: rem(11px);
    padding-bottom: rem(219px);

    @include respond-below(m) {
        padding-top: rem(80px);
        padding-bottom: rem(94px);
        padding-right: rem(7px);
        max-width: rem(352px);
    }

    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* make scrollbar transparent */
    }

    &__wrapperPop {
        @include respond-below(m) {
            align-items: start !important;
        }
    }

    &__barcode {
        opacity: 0;
    }
}