@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw13-recover-psw {
    @include wizard(&);

    &__error {
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__historyBack {
        @extend %buttonReset;
        font-size: rem(24px);
        line-height: rem(20px);
        margin-right: rem(12px);
        cursor: pointer;
    }

    &__txt {
        margin-top: rem(16px);
        color: lightness(--color-black, 60%);
    }

    &__email {
        margin-top: rem(16px);
    }

    &__form {
        margin-top: rem(40px);
        display: flex;
        flex-direction: column;
    }

    &__submit {
        @extend %buttonReset;
        @extend %button-primary3;
        margin-top: rem(80px);
        text-align: center;
        pointer-events: all !important;
        align-self: stretch;

        @include respond-above(m) {
            align-self: flex-end;
        }
    }
}
