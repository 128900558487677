@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.ml3-wizardlayout {
    &__item {
        display: none;

        &--show {
            display: block;
        }
    }
}
