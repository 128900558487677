@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt39-product-gift-accordion-card {
    //border: 0.5px solid alpha(--color-border, 0.05);
    border-radius: 12px;
    box-shadow: 0 1px 3px 0 alpha(--color-boxShadow, 0.15), 0 -0.2px 1px 0 alpha(--color-boxShadow, 0.2);
    margin-left: 1px;
    margin-right: 1px;

    &:last-child {
        margin-bottom: 1px;
    }
    
    &__mobile {
        display: flex;

        @include respond-above(m) {
            display: none;
        }
    }
    &__desktop {
        display: none;

        @include respond-above(m) {
            display: flex;
        }
    }
    &__mobile,
    &__desktop {
        flex-flow: column;
    }

    /* m = mobile version, d = desktop version */

    &__mPromotionBadges,
    &__dPromotionBadges {
        display: flex;
        align-items: center;

        & > div:not(:first-child) {
            margin-left: rem(8px);
        }
    }
    &__dPromotionBadges {
        position: absolute;
        top: 0;
    }
    &__mPromotionBadges {
        padding-top: rem(8px);
        padding-left: rem(8px);
        padding-right: rem(8px);
    }

    &__dPromotionBadges + &__dPrices {
        margin-top: rem(26px);
    }

    &__mPromotion,
    &__dPromotion {

        /* icon with img */
        &--icon > img {
            height: 24px;
            width: 24px;
            object-fit: cover;
        }

        /* badge */
        &--badge {
            @extend %dmSansFontBold;
            font-size: rem(12px);
            line-height: rem(12px);
            color: var(--color-txtAcc);
            width: fit-content;
            border-radius: rem(8px);
            padding: rem(6px) rem(8px);
            white-space: nowrap;
        }
        &--badge-red {
            color: var(--color-txtDark);
            background-color: var(--color-primary);
        }
        &--badge-orange {
            color: var(--color-primary3);
            background-color: lightness(--color-primary2, 80%);
        }
        &--badge-orange-delivery {
            color: var(--color-primary3);
            background-color: lightness(--color-primary2, 80%);

            /* promo delivery */
            > span:first-child {
                margin-right: 4px;
            }
        }
        /* add other badge-colors below */

        &--bassiFissi,
        &--insieme,
        &--insiemePiu,
        &--bothCards {
            background-repeat: no-repeat;
            background-size: 24px;
            height: 24px;
            width: 24px;
        }
        /* bassi e fissi */
        &--bassiFissi {
            background-image: url('#{$resourcesFolder}/myconad/icons/bassiefissi.png');
        }
        /* fidelity card only (insieme) */
        &--insieme {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-orange.svg');
        }
        /* conad card only (insieme plus) */
        &--insiemePiu {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-gray.svg');
        }
        /* both cards */
        &--bothCards {
            background-image: url('#{$resourcesFolder}/myconad/icons/carta-insieme-double.svg');
        }
    }

    &__mMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: rem(12px);
        padding: rem(8px);
        position: relative;
    }

    &__giftBackgroundImage {
        position: absolute;
        z-index: -1;
        top:0;
        left:0;
        max-height: rem(86px);
        max-width: rem(111px);

        @include respond-above(m) {
            max-height: rem(110px);
            max-width: rem(200px);
        }
    }

    &__giftProductText {
        width: 100%;
        padding-top: 22px;

        @include respond-above(m) {
            width: auto;
        }
    }

    &__giftProductText > h3 {
        @extend %robotoFontMedium;
        font-size: rem(16px);
        line-height: rem(20px);
        width: 100%;

        @include respond-above(m) {
            width: auto;
        }
    }

    &__giftProductText > p {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
        margin-top: rem(4px);
    }

    &__mGiftProductData {
        display: flex;
        margin-top: rem(10px);
    }

    &__mPreview > img:first-child,
    &__dPreview > img:first-child {
        width: 56px;
        height: 56px;
        object-fit: contain;
    }

    &__mNamePricesQuantity {
        display: flex;
        margin-left: rem(8px);
        width: 100%;
    }

    &__mName,
    &__dName {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-txtAcc);
    }

    &__mPrices {
        position: absolute;
        bottom: 8px;
        right: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 1;
    }

    &__mTotalPrice {
        @extend %robotoFontMedium;
        line-height: rem(20px);
    }

    &__dTotalPrice {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-txtAcc);
    }

    &__mTotalPrice {
        font-size: rem(16px);
        @extend %robotoFontMedium;
        line-height: rem(20px);
        color: var(--color-txtAcc);
    }

    &__dMain {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(8px) rem(23px) rem(8px) rem(23px);
        position: relative;
        height: rem(110px);
    }

    &__dPreviewNameSize {
        display: flex;

        @include respond-above(xl) {
            padding-right: rem(130px);
        }
    }

    &__dNamePrep {
        display: flex;
        align-items: center;
        width: rem(160px);
        display: flex;
        margin-left: rem(8px);

        @include respond-above(m) {
            width: rem(190px);
        }

        @include respond-above(xl) {
            width: rem(300px);
        }
    }
}