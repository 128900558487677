@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm6-select-pdv {
    @include modal(&, true);

    &__addressfield {
        margin-top: rem(16px);
        margin-bottom: rem(8px);
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__close {
        &--onlyMobile {
            @include respond-above(l) {
                display: none;
            }
        }
    }

    &__wrapper {
        background-color: var(--color-secondary1);
    }

    &__selectContent {
        padding-bottom: rem(16px);
    }

    &__selectTop {
        padding-top: rem(40px);
        justify-content: space-between;
        display: none;

        &--show {
            display: flex;
        }
    }

    &__store {
        cursor: pointer;
    }

    &__selectLabel {
        font-weight: bold;
        color: var(--color-gray);
    }

    &__selectNumberLabel {
        color: var(--color-primary3);
    }

    &__select {
        @extend %button-primary3;
        text-align: center;
        min-width: rem(138px);
        border-radius: 12px;

        @include respond-below(l) {
            font-size: rem(14px);
            line-height: rem(14px);
            padding: 13px 24px;
            min-width: unset;
        }
    }
    &__select:focus-visible {
        @extend %button-focused;
    }

    &__showMap {
        display: block;
    }
    &__showList {
        display: none;
    }

    &__showMap,
    &__showList {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-txtAcc);
        border: 2px solid var(--color-primary3);
        border-radius: 12px;
        max-height: rem(40px);
        padding-left: rem(24px);
        padding-right: rem(24px);
        margin-right: rem(16px);

        @include respond-above(l) {
            display: none;
        }
    }
    &__showMap:focus-visible,
    &__showList:focus-visible {
        @extend %button-focused;
    }

    &__showMapIcon,
    &__showListIcon {
        margin-right: rem(4px);
        position: relative;
        bottom: -1px;
    }

    /* show/hide mobile map and select content */
    &__mapMob {
        height: 100%;
        display: none;
    }
    @include respond-below(l) {
        /* hide select list wrapper */
        &--mapMob &__wrapper {
            display: none;
        }
        /* show map */
        &--mapMob &__mapMob {
            display: block;
        }
        /* show button list */
        &--mapMob &__showList {
            display: block;
        }
        /* hide button map */
        &--mapMob &__showMap {
            display: none;
        }
    }
}

.mm6-select-pdv-map {
    display: none;
    width: 100%;
    height: 100%;

    &--preShow {
        display: block;
        opacity: 0;
        pointer-events: none;
    }

    &--show {
        opacity: 1;
        pointer-events: all;
        transition: opacity 0.5s ease-in-out;
    }

    &__map {
        width: 100%;
        height: 100%;
    }

    &__closer {
        @extend %buttonReset;
        position: absolute;
        right: rem(32px);
        top: rem(32px);
        background-color: var(--color-white);
        height: rem(48px);
        width: rem(153px);
        padding: rem(12px) 0;
        border-radius: rem(12px);
        color: var(--color-black);
        display: none;
        border: solid 1px #999;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        text-align: center;
        box-shadow: 2px 5px 8px 0 alpha(--color-black, 0.36);

        @include respond-above(l) {
            display: block;
        }

        & > .ec-icon-close {
            font-size: 15px;
            vertical-align: sub;
            font-weight: bold;
        }

        &:hover {
            background-color: var(--color-bgButton);
        }
    }
    &__closer:focus-visible {
        @extend %button-focused;
    }

    &__zoomControl {
        position: absolute;
        right: rem(32px);
        bottom: rem(96px);
        color: var(--color-black);

        border-radius: rem(12px);
        box-shadow: 2px 5px 10px 0 alpha(--color-black, 0.4);
        background-color: var(--color-white);
        text-align: center;
        width: rem(48px);
        height: rem(97px);

        display: flex;
        flex-direction: column;
        justify-content: stretch;

        @include respond-above(l) {
            bottom: rem(32px);
        }
    }

    &__zoomIn,
    &__zoomOut {
        position: relative;
        display: block;
        @extend %buttonReset;
        height: 50%;
        font-size: rem(20px);

        &:hover {
            background-color: var(--color-bgButton);
        }
    }

    &__tooltip {
        pointer-events: none;
        position: absolute;
        right: calc(100% + 10px);
        top: 50%;
        transform: translateY(-50%);
        background-color: var(--color-darkCharcoal);
        color: var(--color-white);
        font-size: rem(13px);
        line-height: rem(17px);
        @extend %dmSansFontMedium;
        width: rem(134px);

        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        text-align: center;
        padding: rem(8px) 0;
        border-radius: rem(8px);
    }

    &__zoomIn:hover > &__tooltip,
    &__zoomOut:hover > &__tooltip {
        opacity: 1;
    }

    &__zoomIn:hover {
        border-top-left-radius: rem(12px);
        border-top-right-radius: rem(12px);
    }

    &__zoomOut:hover {
        border-bottom-left-radius: rem(12px);
        border-bottom-right-radius: rem(12px);
    }
}
