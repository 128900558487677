@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt39-info-window {
    display: block;
    width: 100%;
    background-color: var(--color-bg);
    border-radius: 12px;
    padding: rem(8px);
    position: relative;

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__img {
        flex: 0 0 auto;
        margin-right: rem(16px);

        &::after {
            content: url('#{$resourcesFolder}/myconad/icons/store-orange.svg');
        }
    }

    &__wrapperData {
        display: flex;
        align-items: center;
    }

    &__informativeText {
        @extend %dmSansFontMedium;
        color: var(--color-txtTab);
        display: flex;
        align-items: center;
        padding-top: 15px;
        border-top: 1px solid alpha(--color-borderTextfield, 0.1);

        span {
            color: #FFFFFF;
            background: var(--color-info);
            border-radius: 12px;
            margin-right: rem(10px);
        }
    }

    &__center {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
    }

    &__address {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
    }

    &__distanceMob,
    &__distanceDsk {
        display: inline-block;
        @extend %dmSansFontBold;
        @extend %badge;
        @extend %font-label2;
    }

    &__distanceDsk {
        position: absolute;
        right: 0;
        @include respond-below(l) {
            display: none;
        }
    }

    @include respond-above(l) {
        &__distanceMob {
            display: none;
        }
    }
}

/* hide default infoWindow elements */
div.gm-style-iw-t {
    &::after {
        visibility: hidden;
        pointer-events: none;
    }

    & button[type='button'] {
        visibility: hidden;
        pointer-events: none;
    }
}

/* adjust padding */
div.gm-style-iw-d {
    padding-right: 12px;
    padding-bottom: 12px;
    overflow: auto !important; /* needed for firefox */
}
