@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm4-thank-you {
    @include modal(&, true);

    &__wrapper {
        display: flex;
        align-items: flex-start;
        padding-top: rem(62px);
        flex-flow: column;

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__image {
        width: rem(204px);
        height: rem(122px);
        object-fit: contain;
        margin-bottom: rem(12px);
        object-position: left;

        @include respond-above(m) {
            margin-bottom: unset;
            margin-right: rem(12px);
            object-position: right;
        }

    }

    &__result {
        flex: 1 1 auto;
        max-width: rem(464px);
    }

    &__thankTitle {
        @extend %font-heading2;
        font-weight: bold;
    }

    &__thankTxt {
        @extend %txt;
        margin-top: rem(6px);
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        @extend %button-primary3;
        text-align: center;
        min-width: rem(138px);
    }
    &__submit:focus-visible {
        @extend %button-focused;
    }
}
