@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt8-textfield {
    position: relative;
    width: 100%;
    display: block;

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(24px);
        display: block;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        border-radius: rem(16px);
        border: 1px solid alpha(--color-borderTextfield, 0.3);
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
        line-height: rem(39px);
    }

    &__input:focus ~ &__label,
    &__input:not(:placeholder-shown) + &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: alpha(--color-txt, 0.8);
        background-color: transparent;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__permanentTextWrapper {
        font-size: rem(17px);
        line-height: rem(40px);
        position: absolute;
        top: 0;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        padding-bottom: 0;
        color: alpha(--color-txt, 0.2);
        pointer-events: none;
        display: flex;
    }

    &__permanentTextHiddenValue {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(24px);
        opacity: 0;
        display: flex;
    }

    &__permanentText {
        @extend %dmSansFontMedium;
        opacity: 0;
        transition: 0.3s ease-in left;
        display: flex;
    }

    &__input:focus ~ &__permanentTextWrapper > &__permanentText,
    &__input:not(:placeholder-shown) + &__permanentTextWrapper > &__permanentText {
        opacity: 1;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: alpha(--color-txt, 0.8);
    }

    &__tooltip {
        position: absolute;
        right: rem(24px);
        top: 50%;
        transform: translateY(-50%);
    }

    &__showPassword {
        @extend %buttonReset;
        position: absolute;
        right: rem(3px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
        color: lightness(--color-txt, 30);
        border-radius: 14px;
        width: rem(50px);
        height: rem(50px);
        text-align: center;
        z-index: setZ(default);

        & > .on {
            display: block;
        }
        & > .off {
            display: none;
        }
    }
    &__showPassword:focus-visible {
        @extend %button-focused;
    }

    &__showPassword--visible {
        & > .off {
            display: block;
        }
        & > .on {
            display: none;
        }
    }

    &--withPassword ~ &__input,
    &--withTooltip ~ &__input {
        padding-right: rem(60px);
    }
    &--withPassword ~ &__label,
    &--withTooltip ~ &__input {
        margin-right: rem(45px);
    }

    &--errorState &__input {
        border: 1px solid var(--color-negativeInfo); //lightness(--color-borderError, 40%);
    }

    &--validState.mt8-textfield--validEnabled &__input {
        border: 1px solid var(--color-positiveInfo); //lightness(--color-secondary2, 40%);
    }

    /* hide default password eye on Edge/Chrome */
    input[type='password']::-ms-reveal,
    input[type='password']::-ms-clear {
        display: none;
    }
    /* fix autocomplete hover on webkit browsers */
    input:-webkit-autofill:hover ~ &__label {
        color: alpha(--color-txt, 0.8);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-primary);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
        letter-spacing: normal;
    }

    &__validated {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-positiveInfo);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
        letter-spacing: normal;
    }

    &--validState &__validated {
        display: inline;
    }

    &--errorState &__error {
        display: inline;
    }
}
