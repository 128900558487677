@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mm11-request-card-step1 {
    @include modal(&, true);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;
        display: flex;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/myconad/icons/badges-alert.svg');
            vertical-align: middle;
            margin-right: rem(16px);
            align-self: center;
        }

        @extend %font-body2;
        color: var(--color-primary);
        padding: rem(17px) rem(16px);
        background: lightness(--color-primary, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__formSection {
        margin-top: rem(24px);

        &:first-child {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            display: flex;
            justify-content: space-between;
            &--single {
                max-width: 50%;
            }
        }
    }

    &__cfSection {
        position: relative;
        display: none;

        &--show {
            display: block;
        }
    }

    &__cfCalc {
        position: absolute;
        @extend %button-primary3;
        text-align: center;
        min-width: rem(99px);
        top: rem(3px);
        right: rem(3px);
        max-height: rem(50px);
        padding-top: rem(15px);
        padding-bottom: rem(15px);
    }
    &__cfCalc:focus-visible {
        @extend %button-focused;
    }

    &__cf > input {
        padding-right: rem(120px);
    }

    &__formItem {
        flex: 1 0 0;
    }

    &__formItem {
        @include respond-above(m) {
            margin-right: rem(8px);
        }

        &--fullWidth {
            @include respond-above(m) {
                margin-right: none;
            }
        }
    }

    &__formItem + &__formItem {
        margin-top: rem(24px);

        @include respond-above(m) {
            margin-top: 0;
            margin-left: rem(8px);
        }
    }

    &__formItem:last-child {
        margin-right: 0;
    }
    
    &__formItem:first-child {
        @include respond-above(m) {
            margin-right: rem(8px);
        }
    }

    &__formItem-fullWidth:first-child {
        @include respond-above(m) {
            margin-right: 0;
        }
    }

    &__indirizzoResidenza {
        flex: 3 0 0;
    }

    &__messageBar {
        border-radius: 12px;
        background-color: var(--color-info);
        margin-top: rem(24px);
        position: relative;

        &-title {
            @extend %dmSansFontBold;
            font-size: rem(16px);
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5rem;
            letter-spacing: normal;
            color: var(--color-white);
        }

        &-description {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43rem;
            letter-spacing: normal;
            color: var(--color-white);
        }

        &-leftBar {
            margin-right: rem(10px);
        }

        &-content {
            display: flex;
            padding: rem(15px) rem(20px);
            padding-right: rem(64px);
        }
    }

    &__backgroundMask {
        position: absolute;
        top: 0;
        right: 0;

        &-desktop {
            display: none;
            @include respond-above(m) {
                display: block;
            }
        }

        &-mobile {
            display: block;
            @include respond-above(m) {
                display: none;
            }
        }
    }



    &__iconPhone {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url('#{$resourcesFolder}/myconad/icons/phone.svg');
        position: relative;
        bottom: -2px;
        margin-left: 5px;
    }

    &__separator {
        margin-top: rem(32px);
        border-top: 1px solid
            hsla(var(--color-borderTextfield-h), var(--color-borderTextfield-s), var(--color-borderTextfield-l), 0.1);
    }

    &__separator + &__formSection {
        margin-top: rem(24px);
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
    }

    &__submit {
        @extend %button-primary3;
        text-align: center;
        min-width: rem(138px);
        pointer-events: all !important;
    }
    &__submit:focus-visible {
        @extend %button-focused;
    }
}
