@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mt12-coupon {
    $root: &;
    position: relative;
    margin-top: rem(24px);
    margin-right: rem(16px);

    @include respond-above(l) {
        display: flex;
        flex-flow: column;
        width: rem(224px);
        margin: rem(16px) rem(0px) rem(0px) rem(16px);
    }

    // Status variables
    --status-display-wrapper: flex;
    --status-display: none;
    --status-color: #f2273b;
    --value-tag-color: #fed404;
    --value-text-color: var(--color-txtButton);
    --value-triangle-color: #cf9403;
    --filter-image: none;
    --title-color: var(--color-txtButton);
    --text-color: lightness(--color-txt, 0.2);
    --display-holes: block;
    --consumed-top: 93px;
    --consumed-left: 8px;
    --display-consumed: none;

    &--CL {
        --display-holes: none;
        --consumed-top: 89px;

        @include respond-below(l) {
            --consumed-top: 88px;
            --consumed-left: 10px;
        }
    }

    &--CO {
        @include respond-below(l) {
            --consumed-top: 90px;
            --consumed-left: 10px;
        }
    }

    @include respond-below(l) {
        --status-display: none;
        --status-display-wrapper: none;
    }

    &--COMING_SOON {
        --status-display: block;
        --status-display-wrapper: flex;
        --status-color: #5c9700;
    }

    &--ACTIVE {
    }

    &--DUE_TO_EXPIRE {
        --status-display: block;
        --status-display-wrapper: flex;
    }

    &--CONSUMED {
        --status-display-wrapper: flex;
        --display-consumed: block;
        --status-visibility: hidden;
        --value-tag-color: var(--color-txtButton);
        --value-text-color: var(--color-white);
        --value-triangle-color: var(--color-txtButton);
        --filter-image: grayscale(80%);
        --title-color: #707070;
        --text-color: #999999;
    }

    &--EXPIRED {
        --status-visibility: hidden;
        --value-tag-color: var(--color-txtButton);
        --value-text-color: var(--color-white);
        --value-triangle-color: var(--color-txtButton);
        --filter-image: grayscale(80%);
        --title-color: #707070;
        --text-color: #999999;
    }

    &__valueTag {
        position: absolute;
        background-color: var(--value-tag-color);
        height: rem(36px);
        top: rem(10px);
        right: rem(-7px);
        z-index: 30;
        display: flex;
        align-items: center;
        border-radius: rem(5px) rem(5px) rem(0px) rem(5px);
        padding-right: rem(8px);
        gap: rem(4px);
    }

    &__valueText {
        @extend %robotoFontMedium;
        color: var(--value-text-color);
        font-size: rem(28px);
        line-height: rem(36px);
        padding: rem(0px) rem(0px) rem(0px) rem(8px);
    }

    &__punti {
        @extend %dmSansFontMedium;
        font-size: 14px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
    }

    &__triangle {
        position: absolute;
        top: rem(36px);
        right: rem(0px);
        width: rem(7px);
        height: rem(9.6px);
        background-color: var(--value-triangle-color);
        clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
    }

    &__holeSX {
        display: var(--display-holes);
        position: absolute;
        top: 2.4rem;
        left: -5px;
        z-index: 30;
        clip-path: inset(3rem 3px round 0rem 3rem 3rem 0rem);
        width: 25px;
        height: 140px;
        background: radial-gradient(
            circle at left,
            rgb(255, 255, 255),
            rgb(255, 255, 255) 27%,
            var(--color-boxShadow1) 90%
        );
    }

    &__holeDX {
        display: var(--display-holes);
        position: absolute;
        top: 2.4rem;
        right: -5px;
        z-index: 30;
        clip-path: inset(3rem 3px round 3rem 0rem 0rem 3rem);
        width: 25px;
        height: 140px;
        // rgb(255,255,255)
        background: radial-gradient(
            circle at right,
            rgb(255, 255, 255),
            rgb(255, 255, 255) 27%,
            var(--color-boxShadow1) 90%
        );
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
        border-radius: rem(12px);
        box-shadow: 0 4px 8px -2px alpha(--color-boxShadow1, 0.15), 0 0 1px 0 alpha(--color-boxShadow1, 0.2);
        overflow: hidden;

        &--fade {
            opacity: 0.5;
            /* disable pointer on the whole coupon */
            pointer-events: none;

            #{$root}__image,
            #{$root}__bottom {
                filter: grayscale(1);
            }
            #{$root}__onlineShopping {
                pointer-events: none;
            }
        }
    }

    &__main {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;
        margin-top: rem(122px);
        gap: rem(8px);
        padding: rem(16px) rem(8px) rem(16px) rem(8px);

        @include respond-below(l) {
            padding: rem(16px) rem(10px) rem(14px) rem(10px);
        }
    }

    &__typeIcon {
        position: absolute;
        height: rem(20px);
        width: rem(20px);
        color: var(--color-white);
        left: rem(20px);
        top: rem(88px);

        @include respond-below(l) {
            left: rem(30px);
        }
    }

    &__heading {
        &--noClick {
            cursor: auto;
        }
    }

    &__barCode {
        background-image: url('#{$resourcesFolder}/myconad/icons/bar-code-outline.svg');
        background-repeat: no-repeat;
        background-size: rem(19.9px) rem(20px);
    }

    &__card {
        background-image: url('#{$resourcesFolder}/myconad/icons/carta-outline.svg');
        background-repeat: no-repeat;
        background-size: rem(18px) rem(12px);
        background-position: center;

        @include respond-below(l) {
            left: rem(21px);
        }
    }

    &__typeText {
        position: absolute;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-white);
        top: rem(91px);
        left: rem(44px);

        @include respond-below(l) {
            left: rem(53.9px);
        }
    }

    &--online &__typeText {
        @include respond-below(l) {
            left: rem(44px);
        }
    }

    &__backdrop {
        position: absolute;
        height: rem(122px);
        border-radius: rem(12px) rem(12px) rem(0px) rem(0px);
        width: 100%;
        background-image: linear-gradient(
            to top,
            rgba(0, 0, 0, 0.8),
            rgba(249, 249, 249, 0.01),
            rgba(255, 255, 255, 0)
        );
    }

    &__image {
        position: absolute;
        width: 100%;
        height: rem(122px);
        border-radius: rem(12px) rem(12px) rem(0px) rem(0px);
        object-fit: cover;
        z-index: setZ(negative);
        filter: var(--filter-image);

        @include respond-above(l) {
            width: rem(223px);
        }
    }

    &__status {
        display: var(--status-display-wrapper);
        height: rem(16px);
        justify-content: space-between;
        align-items: center;
    }

    &__statusText {
        display: var(--status-display);
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--status-color);
    }

    &__statusPoint {
        display: none;
        height: rem(6px);
        width: rem(6px);
        border-radius: 50%;
        background-color: var(--status-color);

        @include respond-above(l) {
            display: var(--status-display);
        }
    }

    &__consumedIcon {
        display: var(--display-consumed);
        position: absolute;
        width: rem(112.9px);
        height: rem(82.6px);
        top: var(--consumed-top);
        left: var(--consumed-left);
        background-image: url('#{$resourcesFolder}/myconad/icons/coupon-used.svg');
        background-repeat: no-repeat;
        background-size: rem(113px) rem(83px);
        z-index: 31;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--title-color);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(16px);
        color: var(--text-color);
    }

    &__onlineBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(8px) rem(16px);
        flex-wrap: wrap;
        cursor: pointer;
    }

    &__onlineBottomLongCode &__onlineShopping {
        flex-direction: column;
    }

    &__onlineShoppingLabel {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
        display: flex;
        margin: rem(5px) 0;
        color: rgba(0, 0, 0, 0.4);
    }

    &__onlineShoppingCodeWrapper {
        position: relative;
        display: flex;
        margin: rem(5px) 0;
    }

    &__onlineShopping {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        width: fit-content;
        border-radius: rem(12px);
        background-color: var(--color-secondary2);
        color: var(--color-info);
        padding: rem(5px) rem(16px);

        /* icon */
        & > span:last-of-type {
            color: var(--color-info);
        }
    }

    &__tooltip {
        opacity: 0;
        position: absolute;
        bottom: calc(100% + 5px);
        right: 0;
        padding: 1px 8px 3px;
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0 0 10px -2px alpha(--color-black, 0.3);
        pointer-events: none;
        width: auto;
        transition: opacity 0.7s ease-in-out;

        @include respond-above(l) {
            left: 0;
            right: unset;
        }
    }


    &--showTooltip &__tooltip {
        opacity: 1;
    }

    &__tooltipText {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
        color: var(--color-primary3);
        white-space: nowrap;
    }
}
