@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use 'sass:math';

.mw6-completedata {
    @include wizard(&);

    &__subtitle {
        @extend %font-heading4;
        font-weight: bold;
        margin-top: rem(40px);

        & > span {
            font-size: rem(24px);
            line-height: rem(24px);
            margin-right: rem(8px);
        }
    }

    &__txt {
        margin-top: rem(12px);
        color: var(--color-gray);
    }

    &__pdvSelect {
        position: relative;
        margin-top: rem(32px);
    }

    /* buttons */
    &__referencePdvButton {
        @extend %buttonReset;
        width: 100%;
        height: rem(99px);
        border: 2px dotted alpha(--color-txt, 0.3);
        border-radius: rem(12px);
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.3s ease-in-out;

        span {
            font-size: rem(16px);
            line-height: rem(16px);
            font-weight: 600;
            margin-left: rem(4px);
        }

        &--hide {
            display: none;
        }

        &:hover {
            background-color: alpha(--color-bgDark, 0.05);
        }
    }

    &__changePdvButton {
        @extend %buttonReset;
        display: none;
        text-align: right;
        margin-left: auto;
        margin-right: 0;
        color: var(--color-info);
        text-decoration: underline;
        font-weight: 500;

        &--show {
            display: block;
        }
    }

    &__continueButton {
        margin-top: rem(56px);
        align-self: flex-end;
        text-align: center;
        display: inline-block;
        @extend %button-primary3;
    }

    &__policy {
        margin-top: rem(56px);
        @extend %txt;

        display: none;
        &--show {
            display: block;
        }
    }
}
