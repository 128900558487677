@import 'tailwindcss/base';

@layer base {
    /* BASIC COLORS EXTENSIONS */
    :root {
        --color-darkCharcoal-tlw: 51 51 51;
        --color-primary3-tlw: 235 83 5;
        --color-info-tlw: 0 114 202;
    }
}

[x-cloak] {
    display: none !important;
}

@import 'tailwindcss/components';

@layer components {
    /* BUTTONS */
    .btn-full-primary {
        @apply bg-primary3 border-primary3 border-2 text-white font-bold text-sm lg:text-base px-3 lg:px-8 py-[11px] lg:py-[18px] leading-none lg:leading-none rounded-xl inline-flex text-center disabled:border-[#3333330c] disabled:bg-[#3333330c] gap-1 justify-center;
    }
    .btn-empty-primary {
        @apply bg-white border-primary3 border-2 text-dark-charcoal text-sm lg:text-base font-bold leading-none lg:leading-none px-3 lg:px-8 py-[11px] lg:py-[18px] rounded-xl inline-flex text-center disabled:border-[--color-bgButton] disabled:text-[#999999] gap-1 justify-center;
    }
    .btn-empty-info {
        @apply bg-white text-info border-info border-2 text-sm lg:text-base font-bold leading-none lg:leading-none  px-3 lg:px-8 py-[11px] lg:py-[18px] rounded-xl inline-flex text-center disabled:border-[--color-bgButton] disabled:text-[#999999] gap-1 justify-center;
    }
    [class^='btn-'].mblarge {
        @apply px-8 py-[19px];
    }

    /* SPINNER */
    .spinner {
        @apply inline-block w-4 h-4 bg-contain bg-no-repeat;
        background-image: url('../resources/myconad/icons/loader.gif');
    }

    .richtext a {
        @apply text-info underline;
    }
}

@import 'tailwindcss/utilities';
